import { useState, useEffect } from "react";

class Queue {
  items: any[] = [];
  geSize = () => {
    return this.items.length;
  };
  enqueue = (item: any) => {
    this.items.push(item);
  };

  dequeue = () => {
    return this.items.shift();
  };
}

const useQueue = ({ arn }: any) => {
  const queue = new Queue();
  const [processing, setProcessing] = useState<number>(0);
  useEffect(() => {}, [queue.items, processing]);

  const addToQueue = (item: any) => {
    queue.enqueue(item);
    setTimeout(async () => {
      if (queue.geSize() === 1) {
        processQueue();
      }
    }, 100);
  };

  const processQueue = async () => {
    const itemFn = queue.dequeue();
    if (itemFn) {
      setProcessing(processing + 1);
      setTimeout(async () => {
        const result = await itemFn();
        processQueue();
      }, 3000);
    }
  };

  return { addToQueue };
};

export { useQueue };
