import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC } from "react";

interface HighRiskNumbersProps {
  highrisknumber: number;
  loading: boolean;
}

const HighRiskNumbers: FC<HighRiskNumbersProps> = ({
  highrisknumber,
  loading,
}: HighRiskNumbersProps) => {
  return loading ? (
    <CircularProgress size={15} />
  ) : (
    <Typography
      component={"span"}
      variant="body1"
      color="text.secondary"
      paragraph
    >
      {highrisknumber}
    </Typography>
  );
};

export default HighRiskNumbers;
