import React, { useState, FC, useEffect } from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Typography,
  Grid,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Row } from "@tanstack/react-table";
import Control from "../../models/Control";

import RemediateDialog from "./RemediateDialog";
import CustomRemediationDialog from "./CustomRemediationDIalog";
import ThankYouDialog from "./CustomRemediationThankYouDialog";
import ScheduleAutoRemediation from "../remediation-approval/ScheduleRemediation";

import DoneIcon from "@mui/icons-material/Done";
import useAccount from "../../hooks/useAccount";

interface ICustomRemediation {
  handleConfirm: (status: boolean) => void;
  row: Row<Control>;
  finding: any;
}

const CustomRemedation: FC<any> = ({
  handleConfirm,
  row,
  finding,
}: ICustomRemediation) => {
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  const [openThanks, setOpenThanks] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const { arn, member_accounts, member_regions, accounttype } = useAccount();
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCustom = () => {
    setOpenCustom(false);
  };

  const handleCloseThanks = () => {
    setOpenThanks(false);
  };

  const handleCloseSchedule = () => {
    setOpenSchedule(false);
  };

  const handleSubmitCustom = () => {
    setOpenCustom(false);
    setOpenThanks(true);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAction(event.target.value as string);
  };

  const isRemediationAllowed = () => {
    let remediate_condition_one = false;
    const region = finding?.Resources
      ? finding?.Resources[0]?.Id.split(":")[3]
      : "";
    if (arn.includes(finding.AwsAccountId)) {
      remediate_condition_one = true;
    } else {
      if (
        member_accounts.includes(finding.AwsAccountId) &&
        member_regions.includes(region)
      ) {
        remediate_condition_one = true;
      } else {
        remediate_condition_one = false;
      }
    }

    let remediate_condition_two = true;
    if (
      row.original.remediatestatus === "N/A" ||
      accounttype === "READ_ONLY" ||
      finding.Compliance.Status === "PASSED" ||
      row.original.intrusive === "INTRUSIVE"
    ) {
      remediate_condition_two = false;
    }

    return remediate_condition_one && remediate_condition_two;
  };

  useEffect(() => {
    if (action === "remediate") {
      setOpen(true);
    } else if (action === "customRemediation") {
      setOpenCustom(true);
    }
  }, [action]);

  return (
    <>
      <RemediateDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        row={row}
        finding={finding}
      />
      <CustomRemediationDialog
        open={openCustom}
        handleClose={handleCloseCustom}
        handleSubmit={handleSubmitCustom}
        row={row}
        finding={finding}
      />
      <ThankYouDialog open={openThanks} onClose={handleCloseThanks} />
      <ScheduleAutoRemediation
        open={openSchedule}
        handleClose={handleCloseSchedule}
        row={row}
        finding={finding}
      />
      <Box sx={{ minWidth: 130 }}>
        <FormControl fullWidth>
          <InputLabel size="small" id="action-select-label">
            <Typography color="primary">Action</Typography>
          </InputLabel>
          <Select
            labelId="action-select-label"
            id="action-simple-select"
            value={action}
            label="Action"
            size="small"
            onChange={handleChange}
          >
            <MenuItem disabled={true} value={"alertOnly"}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography color="primary">Alert Only</Typography>
                </Grid>
                <Grid item>
                  <DoneIcon />
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem disabled={true} value={"createTask"}>
              <Typography color="primary">Create Task</Typography>
            </MenuItem>
            <MenuItem
              disabled={!isRemediationAllowed()}
              value={"remediate"}
              onClick={() => setOpen(true)}
            >
              {finding.Workflow?.Status === "Notified" ? (
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography fontWeight="bold" color="primary">
                      Remediate
                    </Typography>
                  </Grid>
                  <Grid item>
                    <DoneIcon />
                  </Grid>
                </Grid>
              ) : (
                <Typography fontWeight="bold" color="primary">
                  Remediate
                </Typography>
              )}
            </MenuItem>
            <MenuItem disabled={true} value={"rollback"}>
              <Typography color="primary">Rollback</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => setOpenCustom(true)}
              value={"customRemediation"}
            >
              <Typography fontWeight="bold" color="primary">
                Custom Remediation
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => setOpenSchedule(true)}
              disabled={!isRemediationAllowed()}
              value={"scheduleRemediation"}
            >
              <Typography color="primary" fontWeight="bold">
                Schedule Remediation
              </Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default CustomRemedation;
