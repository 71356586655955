import { useState } from "react";
import { Button, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ExportButton from "./ExportButton";

const ExportSelection = ({
  filter,
  table,
}: {
  filter: any,
  table: any;
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
            m: 0,
            ml: 1,
            mb: 1,
            width: "100%",
						p: "5px",
            fontWeight: 600,
        }}
      >
        Detailed Controls
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Generate Detailed Controls Report</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description" sx={{pb:2}}>
						Choose your preferred report format.
          </DialogContentText>
					<Grid item md={12} display={"flex"} direction={"row"}>
					<ExportButton
						type={"CSV"}
						table={table}
						filter={filter}
						sx={{
								m: 0,
								ml: 1,
								mb: 1,
								width: "50%",
								fontWeight: 600,
						}}
					></ExportButton>
					<ExportButton
						type={"PDF"}
						table={table}
						filter={filter}
						sx={{
								m: 0,
								ml: 1,
								mb: 1,
								width: "50%",
								flexGrow: 0,
								fontWeight: 600,
						}}
					></ExportButton>
					</Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportSelection;
