import React, { FC, useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  Typography,
  LinearProgress,
  Alert,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Control from "../../models/Control";
import { Row } from "@tanstack/react-table";
import { useInitiateRemediationMutation } from "../../generated";
import useAccount from "./../../hooks/useAccount";
import SixPillarsRoleButton from "./SixPillarsRoleButton";

const RemediateDialog: FC<any> = ({
  open,
  handleClose,
  handleConfirm,
  row,
  finding,
}: {
  open: boolean;
  handleClose: any;
  handleConfirm: (status: boolean) => void;
  row: Row<Control>;
  finding: any;
}) => {
  const { arn, accounttype, defaultregion, externalId } = useAccount();
  const [initiateRemediation, { loading }] = useInitiateRemediationMutation();
  const [error, setError] = useState({ status: false, msg: "" });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remediate</DialogTitle>
        <DialogContent>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={3}>
              <Typography fontSize={"14px"}>Control ID :</Typography>
            </Grid>
            <Grid item md={9}>
              <Typography fontSize={"14px"}>
                {row.original.controlname}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Typography fontSize={"14px"}>
              You are about to remediate the following issue:
            </Typography>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={3}>
              <Typography fontSize={"14px"}>Control:</Typography>
            </Grid>
            <Grid item md={9}>
              <Typography fontSize={"14px"}>
                {row.original.controldescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={3}>
              <Typography fontSize={"14px"}>Service ID:</Typography>
            </Grid>
            <Grid item md={9}>
              <Typography fontSize={"14px"}>
                {finding?.Resources ? finding?.Resources[0]?.Id : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={3}>
              <Typography fontSize={"14px"}>Finding ID:</Typography>
            </Grid>
            <Grid item md={9}>
              <Typography fontSize={"14px"}>{finding?.Id}</Typography>
            </Grid>
          </Grid>
          {(() => {
            const remediationSteps = row.original.remediationsteps;

            if (!remediationSteps) {
              return null;
            }

            const parsedData = JSON.parse(remediationSteps);
            const hasDescription =
              parsedData.description &&
              typeof parsedData.description === "string" &&
              parsedData.description.trim() !== "";
            const hasDefaultValues =
              parsedData.defaultValues &&
              typeof parsedData.defaultValues === "object" &&
              Object.keys(parsedData.defaultValues).length > 0;

            if (!hasDescription && !hasDefaultValues) {
              return null;
            }

            return (
              <>
                {hasDescription && (
                  <Grid container sx={{ m: 1 }}>
                    <Grid item md={3}>
                      <Typography fontSize={"14px"}>
                        Remediation Description:
                      </Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography
                        fontSize={"14px"}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {parsedData.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {hasDefaultValues && (
                  <Grid container sx={{ m: 1 }}>
                    <Grid item md={3}>
                      <Typography fontSize={"14px"}>
                        Remediation Default Values:
                      </Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography
                        fontSize={"14px"}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {Object.entries(parsedData.defaultValues).map(
                          ([key, value]) => {
                            return (
                              <React.Fragment>
                                <b>{`${key}`}</b>: {`${value}`}
                                <br />
                              </React.Fragment>
                            );
                          }
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            );
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={() => {
              setError({
                status: false,
                msg: "",
              });
              initiateRemediation({
                context: {
                  apiName: "automation_approval",
                },
                variables: {
                  arn: arn,
                  findingId: finding?.Id,
                },
              })
                .then(({ data }: any) => {
                  let status: boolean =
                    data?.initiateRemediation?.status === "APPROVED";
                  if (status) {
                    handleConfirm(status || false);
                    handleClose();
                  } else {
                    setError({
                      status: true,
                      msg:
                        data?.initiateRemediation?.error ||
                        "Remediation request is not approved",
                    });
                  }
                })
                .catch((e) => {
                  console.log("initiateRemediation", e);
                  handleConfirm(false);
                  handleClose();
                });
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
        {error.status && (
          <>
            {error.msg === "ROLE_NOT_FOUND" ? (
              <>
                <Alert severity="error">
                  <Typography sx={{ fontSize: "12px" }}>
                    We couldn't find a suitable role with the necessary
                    permissions to enable continuous compliance of this service.
                    To update your existing role, please follow these
                    instructions.
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 1: Click the "Open CloudFormation" button below to open
                    CloudFormation in your AWS account
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 2: Select the "six-pillars" stack
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 3: Click "Update"
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 4: Select "Replace Existing Template"
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 5: In AUTOMATE+, click on the "Copy Role Stack URL"
                    button
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 6: In the AWS Console, paste the Role Stack URL into
                    the "Amazon S3 URL" field and click "Next" until you can
                    select "Submit"
                  </Typography>
                  <SixPillarsRoleButton
                    launch={false}
                    update={true}
                    open={true}
                    uuid={externalId}
                    accountRegion={defaultregion}
                    template={
                      accounttype === "AUTOMATED"
                        ? "roles/SixPillarRoleStack.template.json"
                        : "roles/SixPillarReadOnlyRoleStack.template.json"
                    }
                  ></SixPillarsRoleButton>
                </Alert>
              </>
            ) : (
              <Alert severity="error">{error.msg}</Alert>
            )}
          </>
        )}
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </>
  );
};

export default RemediateDialog;
