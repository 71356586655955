import { Row } from "@tanstack/react-table";
import Control from "../../models/Control";
import { FC, useState } from "react";
import { Button } from "@mui/material";
import RemediateFailedFindingsDialog from "./RemediateFailedFindingsDialog";
import useSnackBars from "../../hooks/useSnackbar";
import useAccount from "../../hooks/useAccount";

const RemediateFailedFindingsButton: FC<any> = ({
  row,
  accountRefIds,
  findings,
  loading,
}: {
  row: Row<Control>;
  accountRefIds: Array<string>;
  findings: Array<any>;
  loading: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { accounttype } = useAccount();
  const { setAlerts } = useSnackBars();
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = (status: boolean) => {
    if (status) {
      setAlerts([
        {
          severity: "success",
          msg: "Successfully submitted all findings to remediate.",
        },
      ]);
      setOpen(false);
    }
  };
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        disabled={
          loading ||
          row.original.intrusive === "INTRUSIVE" ||
          row.original.remediatestatus === "N/A" ||
          accounttype === "READ_ONLY"
        }
        onClick={() => {
          setOpen(true);
        }}
        autoFocus
      >
        Open Multi Remediate Dialog
      </Button>
      <RemediateFailedFindingsDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        row={row}
        accountRefIds={accountRefIds}
        findings={findings}
      ></RemediateFailedFindingsDialog>
    </>
  );
};

export default RemediateFailedFindingsButton;
