import { useState, useEffect } from "react";
import {
  useGetAwsControlsQuery,
  useGetSupportControlQuery,
} from "../generated";
import Control from "../models/Control";
import ConnectedAccount from "../models/ConnectedAccount";
interface ListSecurityControlsQueryInput {
  arn: string;
  connectedAccounts: Array<ConnectedAccount>;
}

const useListSecurityControlsQuery = ({
  arn,
  connectedAccounts,
}: ListSecurityControlsQueryInput) => {
  const [controls, setControls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data: sup1Control, refetch: refetchSupportControl } =
    useGetSupportControlQuery({
      variables: {
        arn: arn,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    });

  const { awsControls, refetch: refetchAwsControls } =
    useAwsControlsSubscription({ arn, connectedAccounts });

  const refetch = () => {
    setControls([]);
    setLoading(true);
    refetchSupportControl();
    refetchAwsControls();
  };

  const transformControls = (control: Control) => {
    if (control?.controlname === sup1Control?.getSupportControl?.controlname) {
      return {
        ...control,
        compliancestatus: sup1Control?.getSupportControl.compliancestatus,
      };
    }
    return control;
  };
  const updateData = (
    controlnames: string[],
    columnId: string,
    value: string
  ) => {
    refetchAwsControls();
  };

  useEffect(() => {
    if (sup1Control?.getSupportControl && awsControls.length > 0) {
      let data: any = awsControls.map(transformControls);
      setControls(data);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [sup1Control, awsControls]);

  useEffect(() => {
    if (
      controls.length === 0 &&
      sup1Control?.getSupportControl &&
      awsControls.length > 0
    ) {
      let data: any = awsControls.map(transformControls);

      setControls(data);
      setLoading(false);
    }
  }, [controls, loading]);

  return { controls, loading, error, refetch, updateData };
};

const useAwsControlsSubscription = ({ arn, connectedAccounts }: any) => {
  const [awsControls, setAwsControls] = useState([]);
  const { data, loading, refetch }: any = useGetAwsControlsQuery({
    variables: {
      arn: arn,
      connectedAccounts: connectedAccounts,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (Array.isArray(data?.getAwsControls)) {
      setAwsControls(data?.getAwsControls);
    }
  }, [data]);

  useEffect(() => {
    if (awsControls.length === 0 && !loading) refetch();
  }, [awsControls]);

  return { awsControls, refetch };
};

export { useListSecurityControlsQuery };
