import { useEffect, useRef, useState } from "react";
import {
  useGetTemplateEngineListTemplatesLazyQuery,
  useGetTemplateEngineSecurityResponseLazyQuery,
} from "../generated";
import useSnackBars from "./useSnackbar";

interface UploadedFile {
  fileName: string;
  fileAlias: string;
  fileSize: number;
  status: string;
  summaryId?: number;
  createdAt?: Date;
}

interface UploadTscanProps {
  uploadProgress: number;
  setUploadProgress: (index: number) => void;
  setTscanSummaryIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const useUploadTscan = ({
  uploadProgress,
  setUploadProgress,
  setTscanSummaryIds,
}: UploadTscanProps) => {
  const [GetTemplateScannerResponse] =
    useGetTemplateEngineSecurityResponseLazyQuery();
  const [listTemplates] = useGetTemplateEngineListTemplatesLazyQuery();
  const { setAlerts } = useSnackBars();
  const [apiPage, setApiPage] = useState(1);
  const [steps, setSteps] = useState(0);
  const uploadedFiles = useRef<Map<string, UploadedFile>>(
    new Map<string, UploadedFile>()
  );

  const getTotalUploadedFiles = (): number => {
    return Array.from(uploadedFiles.current.values()).filter((file) => {
      return file.status === "uploaded";
    }).length;
  };

  useEffect(() => {
    setApiPage(1);
    setSteps(0);
    uploadedFiles.current = new Map<string, UploadedFile>();
  }, []);

  // Map to store the latest summaryId per filename
  // Use useRef to persist latestFilesMap across renders without causing re-renders
  const latestFilesMap = useRef<{
    [filealias: string]: {
      filename: string;
      filealias: string;
      summaryId: number;
      createdAt: Date;
    };
  }>({});

  const getTemplates = async () => {
    try {
      const templateData = await listTemplates({
        context: { apiName: "template_scanner" },
        variables: { page: apiPage, limit: 200 },
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
      });
      const resultsJsonString =
        templateData?.data?.getTemplateEngineListTemplates?.results;
      if (resultsJsonString === "{}" || !resultsJsonString) return;

      const resultsJson = JSON.parse(resultsJsonString);

      const nextPage = templateData?.data?.getTemplateEngineListTemplates?.page;
      for (const summaryId in resultsJson) {
        const summaryDict = resultsJson[summaryId].summary;
        // Iterate over uploaded files to match filenames
        uploadedFiles.current.forEach((file) => {
          const fileName = file.fileName;
          const fileAlias = file.fileAlias;
          // If filename matches, update the map with the latest createdAt
          if (summaryDict.alias === fileAlias) {
            const createdAt = new Date(
              summaryDict.created_at.replace(" ", "T")
            );
            // Check if the file is already in the map, and update only if the new entry is more recent
            if (
              !latestFilesMap.current[fileAlias] ||
              createdAt > latestFilesMap.current[fileAlias].createdAt
            ) {
              // Update the map with the latest summaryId and createdAt
              latestFilesMap.current[fileAlias] = {
                filename: fileName,
                filealias: fileAlias,
                summaryId: summaryDict.summaryId,
                createdAt: createdAt,
              };
              setSteps(steps + 1);
            }
          }
        });
      }

      // Extract the latest summaryIds from the map and update setTscanSummaryIds
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateSummaryIds = () => {
    const latestSummaryIds = Object.values(latestFilesMap.current).map(
      (file) => file.summaryId
    );

    if (latestSummaryIds.length > 0) {
      // Update the state with all latest summaryIds
      setTscanSummaryIds(latestSummaryIds);
      // Set alerts for each file processed
      setAlerts(
        Object.values(latestFilesMap.current).map((summary) => ({
          severity: "success",
          msg: `Your uploaded file(${summary.filename}) with summary ID ${summary.summaryId} has finished processing.`,
        }))
      );
    }
  };
  useEffect(() => {
    const totalFiles = uploadedFiles.current.size;
    const totalUploadedFiles: number = getTotalUploadedFiles();
    console.log(
      "useEffect",
      uploadProgress,
      totalFiles,
      totalUploadedFiles,
      latestFilesMap
    );
    if (
      //Upload files to the server
      totalFiles > 0 &&
      totalUploadedFiles > 0 &&
      uploadProgress < 50 &&
      uploadProgress >= 5
    ) {
      let timer = setTimeout(() => {
        setUploadProgress(10 + (totalUploadedFiles * 40) / totalFiles);
      }, 10000);

      return () => {
        clearTimeout(timer);
      };
    } else if (
      //Check if the files are processed (long polling) no time limit
      totalFiles > 0 &&
      totalUploadedFiles > 0 &&
      uploadProgress > 50 &&
      uploadProgress < 90
    ) {
      let timer = setTimeout(() => {
        getTemplates();
        updateSummaryIds();
        setUploadProgress(
          55 +
            (Object.keys(latestFilesMap.current).length * 35) /
              uploadedFiles.current.size
        );
      }, 20000);

      return () => {
        clearTimeout(timer);
      };
    } else if (uploadProgress > 90) {
      // Check if all files are processed and update the summaryIds
      updateSummaryIds();
      setUploadProgress(95);
    } else {
      let timer = setTimeout(() => {
        setUploadProgress(uploadProgress + 1);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [uploadProgress]);

  const uploadIaCTemplateFile = async (uploadVariables: any) => {
    const response = GetTemplateScannerResponse({
      variables: uploadVariables,
      fetchPolicy: "no-cache",
      context: {
        apiName: "template_scanner",
      },
    });
    return response;
  };

  const getBase64 = (file: any): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (ev: ProgressEvent<FileReader>) => {
        resolve(ev.target?.result as string);
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const templateScannerResponse = async (file: any, alias: any) => {
    const result: string = await getBase64(file);
    const fileName = file.name;
    const fileAlias = alias + "-" + file.name;
    uploadedFiles.current.set(fileAlias, {
      fileName,
      fileAlias,
      fileSize: file.size,
      status: "uploading",
    });
    if (result.split("base64,").length > 1) {
      const base64Data = result.split("base64,")[1];
      const uploadVariables: any = {
        processType: "upload",
        fileName: fileName,
        fileAlias: fileAlias,
        fileString: base64Data,
      };
      const uploadFileResult = await uploadIaCTemplateFile(uploadVariables);
      const uploadedFile = uploadedFiles.current.get(fileAlias);
      if (
        uploadFileResult?.data?.getTemplateEngineSecurityResponse?.msg ===
        "File uploaded successfully"
      ) {
        if (uploadedFile) {
          (uploadedFile as UploadedFile).status = "uploaded";
          setSteps(getTotalUploadedFiles());
        }

        setAlerts([
          {
            severity: "success",
            msg: `File ${fileName} uploaded successfully!`,
          },
        ]);
      } else {
        if (uploadedFile) {
          (uploadedFile as UploadedFile).status = "uploadError";
        }
        setAlerts([
          {
            severity: "error",
            msg:
              "Error uploading file. " +
              uploadFileResult?.data?.getTemplateEngineSecurityResponse?.error,
          },
        ]);
      }
      return true;
    } else {
      setAlerts([
        {
          severity: "error",
          msg: `Error uploading ${fileName}. Please try again.`,
        },
      ]);
      return false;
    }
  };

  return {
    templateScannerResponse,
    getTotalUploadedFiles,
  };
};

export { useUploadTscan };
