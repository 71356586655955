import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import SharrDeployProgress from "../manage-accounts/SharrDeployProgress";
import { Box, CircularProgress, Stack, styled } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  useAutomateProgressStatusQuery,
} from "../../generated";

interface AccountProgressProps {
  arn: string;
  organisation: string;
  progressStatus: any;
  setProgressStatus: any;
  setTableUpdateData: any;
  accountDeleted: any;
  setAccountDeleted: any;
  setdisableButton: any;
  setCreatedDate: any;
  setTabSwitch: any;
  tabSwitch: any;
  setRollbackOngoing: (index: boolean) => void;
}

const PassedText = styled(Typography)({
  color: "green",
  component: "body1",
  variant: "body1",
  align: "left",
});

const FailedText = styled(Typography)({
  color: "red",
  component: "body1",
  variant: "body1",
  align: "left",
});

const InfoText = styled(Typography)({
  color: "grey",
  component: "body1",
  variant: "body1",
  align: "left",
});

export default function AccountProgress({
  arn,
  organisation,
  progressStatus,
  setProgressStatus,
  setTableUpdateData,
  accountDeleted,
  setAccountDeleted,
  setdisableButton,
  setCreatedDate,
  setTabSwitch,
  tabSwitch,
  setRollbackOngoing,
}: AccountProgressProps) {
  const [userDeploying, setUserDeploy] = useState(false);
  const [display, setDisplay] = useState(true);
  const [progressValue, setProgressValue] = useState(0);

  let accountid = arn.split(":")[4];

  //Call the Automate Progress Query API
  const { data: DeploymentStatusData, refetch } =
    useAutomateProgressStatusQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        accountid: accountid,
        organisation: organisation,
      },
      context: {
        apiName: "user_deploy_process",
      },
      onCompleted: (ProgressStatusData) => {
        setProgressStatus(
          ProgressStatusData?.automateProgressStatus?.status || ""
        );
      },
    });

  //Do Timezone conversion
  function timezone(date: any) {
    let localtime = new Date(date).toLocaleString();
    setCreatedDate(localtime);
  }

  useEffect(() => {
    const executionarn =
      DeploymentStatusData?.automateProgressStatus?.executionArn || "";
    if (
      DeploymentStatusData &&
      DeploymentStatusData?.automateProgressStatus?.progress === 100
    ) {
      setdisableButton(false);
      timezone(DeploymentStatusData?.automateProgressStatus?.created_at);
      //Check if the rollback state machine was triggered and if the account is set to be deleted
      //The second check helps new accounts to not be deleted because it takes a while to get the latest statemachine that is running
      if (
        executionarn.includes("user-app-rollback-state-machine") &&
        accountDeleted === true
      ) {
        setTableUpdateData(true);
        setAccountDeleted(false);
      }
      setUserDeploy(false);
    }

    if (
      DeploymentStatusData &&
      DeploymentStatusData?.automateProgressStatus?.progress! < 100
    ) {
      //Set the buttons on manage account to disable if a progress is ongoing and hasn't failed yet
      //This will prevent the users from clicking any other buttons accidentally when a progress is ongoing
      if (
        DeploymentStatusData?.automateProgressStatus?.status === "FAILED" ||
        DeploymentStatusData?.automateProgressStatus?.status === "N/A"
      ) {
        setdisableButton(false);
      } else {
        setdisableButton(true);
      }

      //Set the account to be deleted if the rollback statemachine has been triggered
      if (
        DeploymentStatusData?.automateProgressStatus?.status === "ONGOING" &&
        executionarn.includes("user-app-rollback-state-machine")
      ) {
        setRollbackOngoing(true);
        setAccountDeleted(true);
      }

      setDisplay(false);
      setUserDeploy(true);
      //Set the current progress value
      setProgressValue(DeploymentStatusData?.automateProgressStatus?.progress!);
    } else {
      setDisplay(true);
      setUserDeploy(false);
      setProgressValue(0);
    }

    //Trigger a refetch when a new account is deployed
    //and is being redirected from the connect account tab to the manage accounts tab
    if (tabSwitch === true) {
      refetch().then(() => {
        setTabSwitch(false);
      });
    }
  }, [DeploymentStatusData, progressStatus, accountDeleted, tabSwitch]);

  function progressStatusCheck() {
    if (progressStatus === "COMPLETED") {
      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CheckCircleOutlineOutlinedIcon
              sx={{ color: "green" }}
            ></CheckCircleOutlineOutlinedIcon>
            <Typography
              fontSize={"14px"}
              component={"span"}
              color={"success.main"}
              sx={{ ml: 0.5 }}
            >
              <PassedText>Completed</PassedText>
            </Typography>
          </Box>
        </>
      );
    } else if (progressStatus === "FAILED") {
      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CancelOutlinedIcon sx={{ color: "red" }}></CancelOutlinedIcon>
            <Typography
              fontSize={"14px"}
              component={"span"}
              color={"error.main"}
              sx={{ ml: 0.5 }}
            >
              <FailedText>Failed</FailedText>
            </Typography>
          </Box>
        </>
      );
    } else if (progressStatus === "N/A") {
      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <InfoOutlinedIcon sx={{ color: "grey" }}></InfoOutlinedIcon>
            <Typography
              fontSize={"14px"}
              component={"span"}
              color={"info.main"}
              sx={{ ml: 0.5 }}
            >
              <InfoText>N/A</InfoText>
            </Typography>
          </Box>
        </>
      );
    }

    return (
      <>
        {userDeploying === true || display === false ? (
          <SharrDeployProgress progressValue={progressValue} />
        ) : null}
      </>
    );
  }

  return (
    <Stack direction="row">
      {progressStatus ? (
        <>{progressStatusCheck()}</>
      ) : (
        <CircularProgress color={"secondary"} />
      )}
    </Stack>
  );
}
