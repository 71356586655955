import Typography from "@mui/material/Typography";
import { FC } from "react";
import { useListIacMilestonesQuery } from "../../../generated";

interface MilestoneNumberProps {
  awsWafrAccount: string;
  workloadid: string;
}

const MilestoneIacNumber: FC<MilestoneNumberProps> = ({
  awsWafrAccount,
  workloadid,
}: MilestoneNumberProps) => {
  const milestones = useListIacMilestonesQuery({
    variables: {
      workloadid: workloadid,
      awsWafrAccount: awsWafrAccount,
    },
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });
  let milestonenumber = 0;
  milestones.data?.listIacMilestones?.MilestoneSummaries.map(
    (value: any, index) => {
      if (index < 1) {
        milestonenumber = value.MilestoneNumber;
      }
      return <></>;
    }
  );

  return (
    <Typography
      component={"span"}
      variant="body1"
      color="text.secondary"
      paragraph
    >
      {milestonenumber}
    </Typography>
  );
};

export default MilestoneIacNumber;
