import {
  useToggleAutoRemediationMutation,
  OnResultDocument,
} from "../generated";
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";

const useUpdateControlRemediateStatus = () => {
  const [controlsStatus, setControlsStatus] = useState([]);
  const [passedControls, setPassedControls] = useState([]);
  const [status, setStatus] = useState(false);
  const [subcriptionId, setSubcriptionId] = useState("");
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const [toggleAutoRemediation] = useToggleAutoRemediationMutation();

  const reset = () => {
    setControlsStatus([]);
    setPassedControls([]);
    setSubcriptionId("");
    setLoading(false);
    setStatus(false);
  };

  const updateControlAutoRemediateStatus = (
    arn: string,
    controls: any,
    val: string
  ) => {
    setLoading(true);
    setControlsStatus([]);
    toggleAutoRemediation({
      context: {
        apiName: "automation_approval",
      },
      variables: {
        arn: arn,
        controlnames: controls,
        action: val,
      },
    }).then(({ data }: any) => {
      setPassedControls(controls);
      setSubcriptionId(data?.toggleAutoRemediation?.id);
    });
  };

  useEffect(() => {
    if (subcriptionId !== "") {
      client
        ?.subscribe({
          query: OnResultDocument,
          variables: {
            id: subcriptionId,
          },
        })
        .subscribe({
          next: ({ data }: any) => {
            setLoading(false);
            const controlsNew = JSON.parse(data?.onResult?.result);
            setControlsStatus(controlsNew);
            setStatus(true);
          },
          error: () => {
            setLoading(false);
            setStatus(false);
            const errorControls: any = passedControls.map((c: string) => ({
              controlname: c,
              remediatestatus: false,
            }));
            setControlsStatus(errorControls);
          },
        });
    }
  }, [subcriptionId]);

  return {
    updateControlAutoRemediateStatus,
    status,
    loading,
    controlsStatus,
    reset,
  };
};
export { useUpdateControlRemediateStatus };
