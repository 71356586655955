import { useState, useEffect, FC } from "react";
import {
  Typography,
  Box,
  Button,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { useLazyQuery } from "@apollo/react-hooks";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {
  GetUrlDocument,
  useGetExternalApiTokenLazyQuery,
} from "./../../generated";
import { LoadingButton } from "@mui/lab";

function decodeJwt(jwt: string): any {
  try {
    // Split the JWT to extract the payload
    const payloadBase64Url = jwt.split(".")[1];

    // Base64Url decode and parse the JSON
    const base64 = payloadBase64Url.replace("-", "+").replace("_", "/");
    const payload = JSON.parse(Buffer.from(base64, "base64").toString("utf8"));

    return payload;
  } catch (err) {
    console.error("Failed to decode JWT", err);
    return null;
  }
}

interface GenerateApiTokenBtnProps {
  open: boolean;
  closeModal: () => void;
}

const GenerateApiTokenBtn: FC<GenerateApiTokenBtnProps> = ({
  open,
  closeModal,
}) => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewToken, setViewToken] = useState(false);
  const [pythonScriptUrl, setPythonScriptUrl] = useState("");
  const [expiryDateString, setTokenExpiryDateString] = useState("");

  const [getExternalApiToken] = useGetExternalApiTokenLazyQuery({
    context: {
      apiName: "template_scanner",
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(token);
  };

  const [getRoleTemplateURL] = useLazyQuery(GetUrlDocument, {});

  const downloadScript = () => {
    return getRoleTemplateURL({
      variables: {
        template: "template-scanner/template_scanner_pipeline.sh", // value for 'template'
      },
    }).then((result) => {
      setPythonScriptUrl(result.data?.getTemplateS3URL);
    });
  };

  const generateToken = () => {
    return getExternalApiToken().then((result) => {
      const apiToken = result.data?.getExternalApiToken?.apiToken;
      if (apiToken && apiToken !== "") {
        const payload = decodeJwt(token);
        const expirationDate =
          payload && payload.exp ? new Date(payload.exp * 1000) : null; // Convert seconds to milliseconds
        setToken(result.data?.getExternalApiToken?.apiToken || "");
        if (expirationDate)
          setTokenExpiryDateString(
            `This API Token will expire on ${expirationDate.toLocaleString()}`
          );
      }
    });
  };
  const close = () => {
    setViewToken(false);
    setToken("");
    setTokenExpiryDateString("");
    closeModal();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => close()}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => close()}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" fontWeight="bold">
            Template Scanner API Token
          </Typography>
        </DialogTitle>
        {viewToken ? (
          <DialogContent sx={{ overflow: "hidden" }}>
            <Alert
              severity="warning"
              sx={{
                borderColor: "#FF6700",
                backgroundColor: "rgba(255,103,0,0.1)",
              }}
              icon={<InfoIcon sx={{ color: "#FF6700" }} fontSize="inherit" />}
            >
              This is the last time you can see this token.
            </Alert>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              gutterBottom
              style={{ marginTop: 16 }}
            >
              API Token:
            </Typography>
            <TextField
              fullWidth
              value={token}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                style: {
                  backgroundColor: "#f5f5f5",
                  borderRadius: 4,
                  padding: "0 8px",
                  wordBreak: "break-all", // Ensure the token wraps within the container.
                },
              }}
            />
            <Box marginTop={2}>
              <Typography variant="body1">
                {expiryDateString !== "" ? expiryDateString : null}
              </Typography>
            </Box>
            <Box marginTop={2} display="flex" alignItems="center">
              <IconButton onClick={handleCopy}>
                <ContentCopyIcon />
              </IconButton>
              <Typography fontWeight="bold">Copy API Token</Typography>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent sx={{ overflow: "hidden" }}>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ marginTop: 16 }}
            >
              Would you like to generate a token ?
            </Typography>
            <Alert
              severity="warning"
              icon={<InfoIcon sx={{ color: "#FF6700" }} fontSize="inherit" />}
            >
              By clicking this button, we will reset your existing token. if it
              exists.
            </Alert>
          </DialogContent>
        )}
        <DialogActions>
          {viewToken ? (
            <Button
              variant="contained"
              href={pythonScriptUrl}
              color="secondary"
              endIcon={<OpenInNewIcon />}
              disabled={pythonScriptUrl === ""}
              target="_blank"
            >
              Open Sample Script
            </Button>
          ) : (
            <>
              <Button variant="outlined" color="primary" onClick={close}>
                Close
              </Button>
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={loading}
                onClick={async () => {
                  setLoading(true);
                  let result = await Promise.all([
                    generateToken(),
                    downloadScript(),
                  ]);
                  setLoading(false);
                  setViewToken(true);
                }}
              >
                Generate Token
              </LoadingButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateApiTokenBtn;
