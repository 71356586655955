import { useGetControlMappingsQuery } from "../generated";
import { useEffect, useState } from "react";

const useGetTscanRules = () => {
  const [rules, setRules] = useState({
    rule_mappings: {},
    control_mappings: {},
  });
  const { data, loading } = useGetControlMappingsQuery({
    variables: {
      resultList: "",
    },
    context: {
      apiName: "template_scanner",
    },
  });

  useEffect(() => {
    if (data?.getControlMappings?.rules) {
      let rules = JSON.parse(data.getControlMappings.rules);
      setRules(rules);
    }
  }, [data]);

  return { loading, rules };
};

export default useGetTscanRules;
