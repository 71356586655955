import { FC, useState, useEffect } from "react";
import { Container, Typography, Paper, Stack, Grid } from "@mui/material";
import { changeLocation } from "./PageChanges";

interface TotalGraphProps {
  locationName: string;
  displayName: string;
  total: number;
}

const TotalGraph: FC<TotalGraphProps> = ({
  locationName,
  displayName,
  total,
}: TotalGraphProps) => {
  return (
    <Paper
      onClick={() => changeLocation(locationName)}
      sx={{
        cursor: "pointer",
        width: "276px",
        height: "116px",
        background: "#F7F7F7",
        padding: "24px",
      }}
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="body1" align="left" fontWeight="bold">
          {displayName}
        </Typography>
        <Typography variant="body1" align="left" fontWeight="bold">
          {total}
        </Typography>
      </Stack>
    </Paper>
  );
};
export default TotalGraph;
