import { FC, useEffect, useState } from "react";
import { Box, CircularProgress, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import MilestoneNumber from "./MilestoneNumber";
import DeleteWorkload from "./DeleteWorkload";
import HighRiskNumbers from "../common/HighRiskNumbers";
import UpdateButton from "../common/UpdateButton";
import DownloadReports from "../common/DownloadReports";
import StyledTableCell from "../../common/StyledTableCell";
import { useGetWorkloadQuery, useListMilestonesQuery } from "../../../generated";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import useAccount from "../../../hooks/useAccount";
import ConnectedAccount from "../../../models/ConnectedAccount";

interface ListWorkLoadItemProps {
  arn: string;
  workLoadId: string;
  workLoadName: string;
  updatedAt: string;
  updateWorkLoad: (
    workLoadId: string,
    workLoadName: string,
    highRiskCount: number,
    initialHighRiskCount: number,
    technicalReviewArn: string,
    connectedAccounts: Array<ConnectedAccount>
  ) => void;
  lenses: string[];
  highRisks: number;
  workLoadlens: string;
}

const ListWorkLoadItem: FC<ListWorkLoadItemProps> = ({
  arn,
  workLoadId,
  workLoadName,
  updatedAt,
  updateWorkLoad,
  lenses,
  highRisks,
  workLoadlens,
}: ListWorkLoadItemProps) => {
  const { accounts, connectedAccounts } = useAccount();
  const [connectedAccountsDropDown, setConnectedAccountsDropDown] =
    useState(connectedAccounts);
  const [accountIdRef, setAccountIdRef] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [initialHighRiskCount, setInitialHighRiskCount] = useState(0);
  const [technicalReviewArn, setTechnicalReviewArn] = useState("");
  const [accountAssessed, setAccountAssessed] = useState("");
  
  const { data, loading, refetch } = useListMilestonesQuery({
    variables: {
      arn: arn,
      workloadid: workLoadId,
    },
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: getWorkloadData, loading: getWorkloadLoading } = useGetWorkloadQuery({
    variables: {
      arn: arn,
      WorkloadId: workLoadId,// value for 'WorkloadId'
    },
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const firstHighRiskCount: number = Math.max(
      ...(data?.listMilestones?.MilestoneSummaries.map(
        (milestone) => milestone.RiskCounts?.HIGH || 0
      ) || [0, 0])
    );

    setInitialHighRiskCount(firstHighRiskCount);
  }, [data, loading]);

  useEffect(() => {
    refetch({arn:arn, workloadid: workLoadId});
  }, [refetch, arn, workLoadId]);

  const getFilteredConnectedAccounts = () => {
    return connectedAccountsDropDown
      .filter((connectedAccount: ConnectedAccount) => {
        return connectedAccount.accountIdRef === accountIdRef;
      })
      .map((connectedAccount: ConnectedAccount) => {
        return {
          accountIdRef: connectedAccount.accountIdRef,
          region: connectedAccount.region,
        };
      });
  };
  
  useEffect(() => {
    if(getWorkloadData){
      const account = accounts.find(
        (i: any) => {
          for(let x = 0; x < i.connectedAccounts.length; x++){
            if(i.connectedAccounts?.[x]?.accountIdRef === getWorkloadData?.getWorkload?.AccountIds?.[0]){
              return i;
            }
          }
      });
      if(account){
        setConnectedAccountsDropDown(account.connectedAccounts);
        setAccountIdRef(getWorkloadData?.getWorkload?.AccountIds?.[0] || "");
        setAccountAssessed(`${account?.accessnickname} - ${getWorkloadData?.getWorkload?.AccountIds?.[0]}`);
        setTechnicalReviewArn(account.arn);  
      } else {
        setAccountAssessed("N/A");
        setTechnicalReviewArn("");  
      }
    }
  }, [getWorkloadData, accounts]);

  //Update getFilteredConnectedAccounts() when accountIdRef changes
  useEffect(() => {
    getFilteredConnectedAccounts();
  }, [accountIdRef]);

  return (
    <>
      <TableRow>
        <StyledTableCell>{workLoadName}</StyledTableCell>
        <StyledTableCell>{new Date(updatedAt).toLocaleString("en-GB")}</StyledTableCell>
        <StyledTableCell align="center">
          <MilestoneNumber
            arn={arn}
            workloadid={workLoadId}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <HighRiskNumbers highrisknumber={highRisks} loading={loading} />
        </StyledTableCell>
        <StyledTableCell align="center">
          {getWorkloadLoading ? 
            <CircularProgress size={15} />
            :
            <UpdateButton
              arn={arn}
              workloadid={workLoadId}
              milestonename={workLoadName}
              updateWorkLoad={() => {
                updateWorkLoad(workLoadId, workLoadName, highRisks, initialHighRiskCount, technicalReviewArn, getFilteredConnectedAccounts());
              }}
            />
          }
        </StyledTableCell>
        <StyledTableCell align="center">
          <DownloadReports
            arn={arn}
            workloadname={workLoadName}
            workloadid={workLoadId}
            workloadlens={workLoadlens}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <DeleteWorkload
            arn={arn}
            workloadid={workLoadId}
            workloadname={workLoadName}
          />
        </StyledTableCell>
        <StyledTableCell>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: "block" }}>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Accounts Assessed:</b> {accountAssessed}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Lenses:</b> {lenses}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>AWS Regions:</b> {getWorkloadData?.getWorkload?.AwsRegions?.[0] || "N/A"}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Workload Id:</b> {getWorkloadData?.getWorkload?.WorkloadId || "N/A"}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
  </>
  );
};

export default ListWorkLoadItem;
