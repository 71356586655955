import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  LinearProgress,
  Alert,
} from "@mui/material";
import * as yup from "yup";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import useSnackBars from "../../hooks/useSnackbar";
import useCognito from "../../hooks/useCognito";

const organisationNameRegExp = /^[a-zA-Z0-9_-\s+]{1,32}$/;
const phoneRegExp = /^\+[0-9]+$/;

const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  organisationName: yup
    .string()
    .matches(organisationNameRegExp, "Company Name is not valid")
    .required("Company Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(
      phoneRegExp,
      "Please enter a valid number with + and country code and no spaces."
    )
    .required("Phone number is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const MarketplaceSignup: FC = () => {
  const navigate = useNavigate();
  const { userPool } = useCognito();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, msg: "" });
  const { setAlerts } = useSnackBars();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      organisationName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setError({ status: false, msg: "" });

      let attributeList = [];

      let dataEmail = {
        Name: "email",
        Value: values.email,
      };

      let dataPhoneNumber = {
        Name: "phone_number",
        Value: values.phone,
      };

      let firstName = {
        Name: "given_name",
        Value: values.firstName,
      };

      let lastName = {
        Name: "family_name",
        Value: values.lastName,
      };
      let organisationName = {
        Name: "custom:organisation",
        Value: values.organisationName,
      };
      let attributeEmail = new CognitoUserAttribute(dataEmail);
      let attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
      let attributeFirstName = new CognitoUserAttribute(firstName);
      let attributeLastName = new CognitoUserAttribute(lastName);
      let attributeOrganisationName = new CognitoUserAttribute(
        organisationName
      );

      attributeList.push(attributeEmail);
      attributeList.push(attributePhoneNumber);
      attributeList.push(attributeFirstName);
      attributeList.push(attributeLastName);
      attributeList.push(attributeOrganisationName);

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("x-amzn-marketplace-token");

      userPool.signUp(
        values.email,
        values.password,
        attributeList,
        [],
        function (err: any, result: any) {
          setLoading(false);
          if (err) {
            setError({ status: true, msg: err.message || JSON.stringify(err) });
            return;
          }
          // POST request using fetch with error handling
          fetch(
            "https://gsulisw4ej.execute-api.ap-southeast-2.amazonaws.com/prod/customer",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify({
                regToken: token,
                companyName: values.organisationName,
                contactPerson: values.firstName,
                contactPhone: values.phone,
                contactEmail: values.email,
              }),
            }
          )
            .then(async (response) => {
              const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
              const data = isJson && (await response.json());
              // check for error response
              if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
              }
              setAlerts([
                {
                  severity: "success",
                  msg: `${result?.codeDeliveryDetails?.AttributeName} sent to ${result?.codeDeliveryDetails?.Destination}`,
                },
              ]);

              navigate(`/confirm-email/${values.email}`);
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        }
      );
    },
  });

  return (
    <div>
      {process.env.COGNITO_USER_POOL_ID}
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant="h3"
            fontSize={"22px"}
            fontWeight={"500"}
            color={"secondary"}
            sx={{ pt: 0, pb: 4 }}
          >
            Register
          </Typography>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="First Name"
            value={formik.values.firstName}
            sx={{ maxWidth: "350px", pb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            label="Last Name"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
          <TextField
            fullWidth
            id="organisationName"
            name="organisationName"
            label="Company Name"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.organisationName}
            onChange={formik.handleChange}
            error={
              formik.touched.organisationName &&
              Boolean(formik.errors.organisationName)
            }
            helperText={
              formik.touched.organisationName && formik.errors.organisationName
            }
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            type="email"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            id="phone"
            name="phone"
            label="Phone Number (+61 etc. format)"
            placeholder="(+ Country Code)XXX XXX XXX"
            type="tel"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Create Password"
            type="password"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.password && Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Button
            color="primary"
            disabled={loading}
            variant="contained"
            type="submit"
            sx={{
              mb: 2,
              width: "198px",
              backgroundColor: "secondary.main",
              fontSize: "16px",
              textTransform: "none",
            }}
          >
            Register
          </Button>
          {error.status && <Alert severity="error">{error.msg}</Alert>}
          <Divider />
          {loading && <LinearProgress color="secondary" />}
        </Box>
      </form>
    </div>
  );
};

export default MarketplaceSignup;
