import React, {useState, useEffect} from "react";
import {
  Button,
  IconButton,
  Grid,
  Stack,
  Box,
  Paper,
  TextField,
  Container,
  Alert,
  CircularProgress,
  Typography,
  Snackbar,
} from "@mui/material";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

import Heading from "../common/Heading";
import UploadIcon from '@mui/icons-material/Upload';

import { 
    useGetTemplateEngineSecurityResponseLazyQuery, 
    useGetTemplateEngineListTemplatesLazyQuery
} from "../../generated/index";

interface UploadedFile {
    file: File;
    alias: string;
}

interface Alert {
    type: 'success' | 'error' | 'warning' | 'info';
    message: any;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}

const LinearWithValueLabel = ({ progress }: any) => {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progress} />
        </Box>
    );
};

const getResultsPageUrl = (summaryId: string) => {
    summaryId = encodeURIComponent(summaryId);
    return `/template-scanner-results?id=${summaryId}`;
};

const ScannerUpload = () => {
    const [file, setFile] = useState<File | null>(null);
    const [alerts, setAlerts] = useState<Alert[]>([]);
    const [GetTemplateScannerResponse] = useGetTemplateEngineSecurityResponseLazyQuery();
    const [GetListTemplates] = useGetTemplateEngineListTemplatesLazyQuery();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const [fileUploaded, setFileUploaded] = useState(false);

    const [fileAlias, setFileAlias] = useState("");
    const [loading, setLoading] = useState(false);

    const handleFileSelect = (event: any) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          setFile(selectedFile);
        }
    };

    const handleCloseAlert = (alertIndex: number) => {
        setAlerts(alerts.filter((_: any, index: number) => index !== alertIndex));
    };

    useEffect(() => {
        console.log("Files is ", file);
    }, [file]);

    const checkListTemplatesApi = () => {
        GetListTemplates({
            context: {
                apiName: "template_scanner"
            },
            fetchPolicy: 'network-only',
            onCompleted: (data) => {
                const resultsJsonString = data?.getTemplateEngineListTemplates?.results;
                if (!resultsJsonString) return;
                const resultsJson = JSON.parse(resultsJsonString);
                for (const summaryId in resultsJson) {
                  const summaryDict = resultsJson[summaryId].summary;
                  for (const currentFileIdx in uploadedFiles) {
                    const currentFile = uploadedFiles[currentFileIdx];
                    const { file, alias } = currentFile;
                    const fileName = file.name;
                    const fileAlias = alias === "" ? "N/A" : alias;
                    if (summaryDict.filename === fileName && summaryDict.alias === fileAlias) {
                        const newAlertMessage = 
                        <Typography>
                            {`Your uploaded file ${fileName} with alias ${fileAlias} has finished processing, you can see the results here `} <a href={getResultsPageUrl(summaryId)}>File Results</a>
                        </Typography>
                        setAlerts(prevAlerts => [...prevAlerts, { type: 'success', message: newAlertMessage }]);
                    }
                  }
                }
            }
        })
    }

    useEffect(() => {
        if (fileUploaded) {
            if (uploadProgress < 100) {
                const timer = setTimeout(() => setUploadProgress(uploadProgress + 10), 30000);
                return () => clearTimeout(timer);
            } else {
                setUploadProgress(0); // Reset progress for the next file
                setFileUploaded(false);
                if (uploadedFiles.length > 0) {
                    checkListTemplatesApi();
                    setUploadedFiles(prevFiles => prevFiles.slice(1)); // Remove the checked file from the queue
                }
            }
        }
    }, [uploadProgress, uploadedFiles, fileUploaded]);

    const handleFileUpload = () => {
        if (!file) return;
        
        setLoading(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const result = reader.result ? reader.result as string : "";
            if (result.split("base64,").length > 1) {
                const fileName = file.name;
                const base64Data = result.split("base64,")[1];
                const uploadVariables: any = {
                    processType: "upload", 
                    fileName: fileName,
                    fileString: base64Data,
                }
                if (fileAlias !== "") {
                    uploadVariables["fileAlias"] = fileAlias;
                }

                GetTemplateScannerResponse({
                    variables: uploadVariables,
                    context: {
                        apiName: "template_scanner"
                    },
                    onCompleted: (data) => {
                        setLoading(false);
                        setFile(null);
                        setUploadedFiles(prevFiles => [...prevFiles, { file, alias: fileAlias }]);
                        setFileAlias("");
                        if (data.getTemplateEngineSecurityResponse?.msg === "File uploaded successfully") {
                            setAlerts(prevAlerts => [...prevAlerts, {type: 'success', message: `File ${fileName} uploaded successfully!`}]);
                            setUploadProgress(0);
                            setFileUploaded(true);
                        }else {
                            setAlerts(prevAlerts => [...prevAlerts, {type: 'error', message: "Error uploading file. " + data.getTemplateEngineSecurityResponse?.error}]);
                        }
                    }
                })
            }
        }
    }

    return (
        <Container sx={{height: "294px"}} component={Paper}>
            <Stack direction="column" spacing={10}>
                {alerts.map((alert: any, index: number) => (
                    <Snackbar
                        key={index}
                        open={true}
                        autoHideDuration={30000}
                        onClose={() => handleCloseAlert(index)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <Alert onClose={() => handleCloseAlert(index)} severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                ))}
            </Stack>
            <Heading heading={"Upload template"} />
            <Box sx={{ width: "100%", pb: "30px", pr: "20px", pt: "20px" }}>
                <Stack direction="row" spacing={4}>
                    <TextField
                        id="fileAlias"
                        label="File Alias"
                        variant="outlined"
                        sx={{ width: "414.4px", height: "76px" }}
                        InputLabelProps={{
                            shrink: true,
                        }}      
                        value={fileAlias}
                        helperText={
                            <Typography component={'span'} variant="body1">
                                Enter an alias for the file (optional)
                            </Typography>
                        }
                        onChange={(e) => setFileAlias(e.target.value)}
                    />
                    <Grid container 
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                    >
                            <TextField
                                id="standard-edit"
                                label="Upload template to scan"
                                helperText={
                                    <Typography component={'span'} variant="body1" color="rgba(0, 0, 0, 0.6)">
                                        Allowed File Extensions: .tf, .tf.json, .tfvars, .yml, .json, .yaml, .dockerfile, .txt, .template
                                    </Typography>
                                }
                                variant="outlined"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={file && `File to upload: ${file.name}` || ""}
                                sx={{ width: "414.4px", height: "76px" }}
                                InputProps={{
                                    endAdornment: (
                                    <IconButton component="label" color="secondary">
                                        <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleFileSelect}
                                        accept=".tf,.tf.json,.tfvars,.yml,.json,.yaml,.dockerfile,.txt,.template"
                                        />
                                        <UploadIcon/>
                                    </IconButton>
                                ),
                                }}
                            />
                            {
                                !loading ?
                                    <Button
                                        onClick={handleFileUpload}
                                        variant="contained"
                                        color="secondary"
                                        type='submit'
                                        sx={{
                                            width: "139px",
                                            height: "60px",
                                        }}>
                                        Upload
                                    </Button>
                                :
                                <CircularProgress color="secondary"/>
                            }
                        </Grid>
                </Stack>
            </Box>
            {fileUploaded || uploadProgress > 0 ?
                <LinearWithValueLabel progress={uploadProgress} /> : null}
        </Container>
    )
}
export default ScannerUpload;