import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

interface HriCounterProps {
  totalReducedHri: number;
  initialHriCount: number;
  questionnairetab: boolean;
}

export default function HriCounter({
  totalReducedHri,
  initialHriCount,
  questionnairetab,
}: HriCounterProps) {
  
  let percentage = Math.round(((initialHriCount-totalReducedHri)/initialHriCount) * 100);
  
  return (
    <Box display={"flex"} flexDirection={"row"}>
      <Typography fontWeight="bold">Total HRIs:</Typography>
      <Typography fontWeight="bold" noWrap>
        &nbsp;
      </Typography>
      <Typography>
        {totalReducedHri}/{initialHriCount}
      </Typography>
      <Typography fontWeight="bold" noWrap>
        &nbsp;
      </Typography>
      {
       questionnairetab ? 
       <Typography>({percentage}% of HRIs retired)</Typography> 
       : null 
      }
    </Box>
  );
}
