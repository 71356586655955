import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import {styled, Button} from '@mui/material';

interface DataItem {
    account_id: string;
    account_alias: string;
    created_at: string;
    control_id: string;
    description: string;
    finding_id: string;
}

interface Props {
    dataItems: DataItem[];
}

const GenerateButton = styled(Button)({
    width: "92px",
    height: "40px",
    border: "1px solid #0E1956",
    fontWeight: "bold",
});

const TablePDF = ({dataItems}: Props): any => {  
    const generatePDF = async () => {
      const pdf = new jsPDF('l', 'pt', 'a3');
      autoTable(pdf, {
        head: [['Account ID', 'Account Name', 'Created At', 'Control Id', 'Description', 'Finding Id']],
        body: dataItems.map((item) => [
            item.account_id,
            item.account_alias,
            item.created_at,
            item.control_id,
            item.description,
            item.finding_id,
        ]),
        headStyles: {
            fillColor: "#1b2a3a"
        },
        styles: {cellWidth: "wrap"},
        columnStyles: {
            4: {cellWidth: 200},
        }
      })
      const currentDate = new Date().toISOString();
      pdf.save(`events-${currentDate}.pdf`);  
    };

    return (
    <div>
        <GenerateButton onClick={generatePDF}>PDF</GenerateButton>
    </div>
    );
};

export default TablePDF;