import React, { createContext, useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackBarContext = createContext<{
  setAlerts: (alerts: AlertMsg[]) => void;
}>({ setAlerts: (alerts) => {} });

interface AlertMsg {
  severity: AlertColor;
  msg: string;
}

export function SnackBarProvider({ children }: any) {
  const [alerts, setAlerts] = useState<AlertMsg[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    if (alerts.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [alerts]);

  return (
    <SnackBarContext.Provider value={{ setAlerts: setAlerts }}>
      {children}
      {alerts.map((alert, index) => {
        return (
          <Snackbar
            open={open}
            key={index}
            autoHideDuration={3000}
            onClose={() => {
              setOpen(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={alert.severity} sx={{ width: "100%" }}>
              {alert.msg}
            </Alert>
          </Snackbar>
        );
      })}
    </SnackBarContext.Provider>
  );
}
