import React, { FC, useState, useRef, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  IconButton,
  InputAdornment,
  InputLabel,
  FormControl,
  Grid,
  MenuItem,
  Collapse,
  Box,
  SvgIcon,
} from "@mui/material";

import { CSVLink } from "react-csv";

import { useGetEventsQuery } from "../../generated/index";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import DeleteEvent from "./DeleteEvent";
import TablePDF from "./TablePdf";
import StyledTableCell from "../common/StyledTableCell";
import StyledSelect from "../common/StyledSelect";
import Heading from "../common/Heading";

const GenerateButton = styled(Button)({
  width: "92px",
  height: "40px",
  border: "1px solid #0E1956",
  fontWeight: "bold",
});

const PassedText = styled(Typography)({
  color: "green",
  component: "body1",
  variant: "body1",
  align: "left",
});

const FailedText = styled(Typography)({
  color: "red",
  component: "body1",
  variant: "body1",
  align: "left",
});

const RefreshButton: any = styled(Button)({
  textTransform: "none",
  width: "115px",
  height: "40px",
  color: "primary",
  border: "1px solid #6F6F6F",
  fontWeight: "bold",
});

const AscSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const descIcon = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

interface ExpandableRowInterface {
  item: any;
  index: number;
  setTableUpdateData: any;
}

interface Data {
  account_id: string;
  account_alias: string;
  created_at: string;
  control_id: string;
  finding_id: string;
  description: string;
  status: string;
}

function ExpandableRow({
  item,
  index,
  setTableUpdateData,
}: ExpandableRowInterface) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow key={index}>
        <StyledTableCell>{item.account_id}</StyledTableCell>
        <StyledTableCell>{item.created_at}</StyledTableCell>
        <StyledTableCell>{item.control_id || "N/A"}</StyledTableCell>
        <StyledTableCell>{item.description}</StyledTableCell>
        <StyledTableCell>
          {item.status === "PASSED" || item.status === "Successful" ? (
            <>
              <CheckCircleOutlineOutlinedIcon sx={{ color: "green" }} />{" "}
              <PassedText>Passed</PassedText>
            </>
          ) : (
            <>
              <CancelOutlinedIcon sx={{ color: "red" }} />
              <FailedText>Failed</FailedText>
            </>
          )}
        </StyledTableCell>
        <StyledTableCell>
          <DeleteEvent
            eventId={item.finding_id}
            setTableUpdate={setTableUpdateData}
          />
        </StyledTableCell>
        <StyledTableCell>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: "block" }}>
              <Typography
                variant="body1"
                align="left"
                sx={{ fontWeight: "bold" }}
                gutterBottom
              >
                Control ID: {item.control_id || "N/A"}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                Finding ID: {item.finding_id || "N/A"}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

let dataItems: Data[] = [];
let hasFiltered: boolean = false;

function getDescription(text: string) {
  let startIndex = text.indexOf("Description :");
  if (startIndex === -1) {
    return "";
  }
  startIndex += "Description :".length;
  let endIndex = text.indexOf("Workflow Status");
  return text.substring(startIndex, endIndex).trim();
}

const DetectPage: FC = () => {
  const [tableUpdateData, setTableUpdateData] = useState(false);
  const [page, setPage] = useState(0);
  const [token, setToken] = useState<string>("");
  const [apiPage, setApiPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortDirection, setSortDirection] = useState("desc");

  const csvLink: any = useRef();
  const events = (events: any) => {
    if (
      Array.isArray(events?.getEvents?.items) &&
      events?.getEvents.items.length > dataItems.length
    ) {
      const newPage = apiPage + 1;
      setApiPage(newPage);
      setToken(events.getEvents.nextToken);
    } else if (
      Array.isArray(events?.getEvents?.items) &&
      events?.getEvents.items.length === dataItems.length
    ) {
      setToken("");
    } else {
      setToken("");
    }
    dataItems = [];
    const items = [...events?.getEvents.items];
    items
      .sort((a: any, b: any) => {
        return new Date(b.created).valueOf() - new Date(a.created).valueOf();
      })
      .map((item: any) => {
        dataItems.push({
          account_id: item.account_ref,
          account_alias: item.account_aliasname,
          created_at: item.created,
          control_id: item.control_ref,
          finding_id: item.finding_ref,
          description: item.description,
          status: item.status,
        });
      });
  };

  const { fetchMore, loading, refetch } = useGetEventsQuery({
    onCompleted: events,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!loading && apiPage > 0)
      fetchMore({
        variables: {
          page: apiPage,
          nextToken: token,
          limit: 100,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          console.log(
            "updateQuery",
            prev.getEvents.nextToken === fetchMoreResult.getEvents.nextToken
          );
          const sortedItems = [
            ...prev.getEvents.items,
            ...fetchMoreResult.getEvents.items,
          ].sort((a: any, b: any) => {
            return (
              new Date(b.created).valueOf() - new Date(a.created).valueOf()
            );
          });
          return {
            getEvents: {
              __typename: prev.getEvents.__typename,
              items: sortedItems,
              nextToken: fetchMoreResult.getEvents.nextToken,
            },
          };
        },
      });
  }, [apiPage, loading]);

  const [filteredData, setFilteredData] = useState<Data[]>(dataItems);
  let currentItems = hasFiltered ? filteredData : dataItems;

  const refreshButtonClick = () => {
    dataItems = [];
    setApiPage(1);
    setTableUpdateData(true);
  };

  const generateCSVButtonClick = () => {
    csvLink.current.link.click();
  };

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set filtered flag if not already set
    if (!hasFiltered) hasFiltered = true;
  
    const { value: searchValue } = event.target;
    const search = searchValue.toLowerCase();
  
    const found = dataItems.filter((item: Data) => {
      const accountId   = item.account_id?.toLowerCase() ?? '';
      const controlId   = item.control_id?.toLowerCase() ?? '';
      const createdAt   = item.created_at?.toLowerCase() ?? '';
      const findingId   = item.finding_id?.toLowerCase() ?? '';
      const status      = item.status?.toLowerCase() ?? '';
      const description = item.description?.toLowerCase() ?? '';
  
      return accountId.includes(search) ||
             controlId.includes(search) ||
             createdAt.includes(search) ||
             findingId.includes(search) ||
             status.includes(search) ||
             description.includes(search);
    });
    console.log(found);
    setFilteredData(found);
  };

  if (tableUpdateData) {
    refetch();
    setTableUpdateData(false);
  }

  const handleSort = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    if (hasFiltered) {
      setFilteredData(
        filteredData.sort((a: Data, b: Data) => {
          if (sortDirection === "asc") {
            return (
              new Date(a.created_at).valueOf() -
              new Date(b.created_at).valueOf()
            );
          } else {
            return (
              new Date(b.created_at).valueOf() -
              new Date(a.created_at).valueOf()
            );
          }
        })
      );
    } else {
      dataItems = dataItems.sort((a: Data, b: Data) => {
        if (sortDirection === "asc") {
          return (
            new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
          );
        } else {
          return (
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
          );
        }
      });
    }
  };

  return (
    <Container component="main">
      <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
        <CSVLink
          data={dataItems}
          filename="events_data.csv"
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
        <Stack spacing={2}>
          <Heading
            heading={
              window.location.pathname === "/detect"
                ? "Ransomware - Detect"
                : "Findings"
            }
          />
          <Paper
            sx={{
              backgroundColor: "#F7F7F7",
              margin: "32px",
            }}
          >
            <Stack spacing={2} padding="16px">
              <Typography
                component="h6"
                variant="h6"
                align="left"
                sx={{ fontWeight: "bold", width: "1120px", height: "24px" }}
                color="#383737"
                gutterBottom
              >
                Generate Reports
              </Typography>
              <Stack spacing={2} direction="row">
                <GenerateButton onClick={generateCSVButtonClick}>
                  CSV
                </GenerateButton>
                <TablePDF dataItems={dataItems} />
              </Stack>
            </Stack>
          </Paper>
          <Paper>
            <Grid container flexDirection="row">
              <Paper
                component="form"
                elevation={0}
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  display: "flex",
                  border: 0,
                  boxShadow: 0,
                  flexGrow: 1,
                }}
              >
                <TextField
                  id="SearchTextField"
                  label="Search"
                  sx={{
                    width: "305px",
                    height: "40px",
                    marginLeft: "32px",
                    alignSelf: "center",
                  }}
                  onInput={requestSearch}
                  onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchOutlinedIcon color="secondary" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Paper>
              <Paper
                component="form"
                elevation={0}
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  mt: "32px",
                  display: "flex",
                  justifyContent: "flex-end",
                  flexGrow: 1,
                }}
              >
                <InputLabel
                  id="page-size"
                  sx={{
                    alignSelf: "center",
                    p: 1,
                    color: "#000000",
                    fw: "400",
                  }}
                >
                  Show
                </InputLabel>
                <FormControl
                  sx={{ m: 1, marginRight: "32px", minWidth: 119 }}
                  size="small"
                >
                  <StyledSelect
                    labelId="page-size-select-label"
                    id="page-size-select"
                    defaultValue={25}
                    onChange={(event: any) => {
                      setRowsPerPage(event.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </StyledSelect>
                </FormControl>
                <RefreshButton
                  id="refresh-button"
                  variant="outlined"
                  sx={{ alignSelf: "center", p: 1, marginRight: "32px" }}
                  onClick={refreshButtonClick}
                >
                  <RefreshOutlinedIcon
                    sx={{ width: "16px", height: "16px", margin: "8px" }}
                  />
                  Refresh
                </RefreshButton>
              </Paper>
            </Grid>
            <TableContainer sx={{ padding: "32px" }} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Account</StyledTableCell>
                    <StyledTableCell onClick={handleSort}>
                      Created {sortDirection === "asc" ? AscSvg : descIcon}
                    </StyledTableCell>
                    <StyledTableCell>Control ID</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                    <StyledTableCell>Collapse</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item: any, index: number) => {
                          return (
                            <ExpandableRow
                              item={item}
                              index={index}
                              setTableUpdateData={setTableUpdateData}
                            />
                          );
                        })}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              variant="outlined"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: "10px",
                color: "primary.main",
                borderColor: "primary.main",
              }}
              shape="rounded"
              count={
                Math.ceil(currentItems.length / rowsPerPage)
              }
              page={page + 1}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPage(value - 1);
              }}
            />
          </Paper>
        </Stack>
      </Container>
    </Container>
  );
};

export default DetectPage;
