import { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useGetUserDeployEventsQuery} from '../../generated';
import { 
    TableContainer, 
    Table, 
    TableHead, 
    TableRow, 
    TableBody, 
    Paper, 
    Grid,
    Container,
    Pagination,
    InputLabel,
    FormControl,
    MenuItem,
    SelectChangeEvent,
    InputAdornment,
    OutlinedInput,
    styled,
    Box,
} from '@mui/material';
import StyledSelect from '../common/StyledSelect';
import SearchIcon from "@mui/icons-material/Search";
import StyledTableCell from '../common/StyledTableCell';
import Loader from '../common/Loader';
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface DeploymentEventsProps {
    organisation: any;
}

interface Data {
    account_id: string
    task_type: string;
    status: string;
    created_at: string;
    task: string;
}

const PassedText = styled(Typography)({
    color: "green",
    component: "body1",
    variant: "body1",
    align: "left",
});

const FailedText = styled(Typography)({
    color: "red",
    component: "body1",
    variant: "body1",
    align: "left",
});

const DeploymentEvents: FC<DeploymentEventsProps> = ({organisation}: DeploymentEventsProps) => {
    const [tableUpdateData, setTabelUpdateData] = useState(false);
    const [queryCall, setQueryCall] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState<Data[]>([]);
    
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const { data, loading, error, refetch } = useGetUserDeployEventsQuery({
        variables: {
            organisation: organisation // value for 'organisation'
        },  
        context: {
          apiName: "user_deploy_process"
        }
    });

    if(tableUpdateData === true){
        setTabelUpdateData(false);
        setQueryCall(true);
    }

    //Set the Data
    const transformdata = (): Data[] => {
        return (
        data?.getUserDeployEvents?.items.map((getUserAccounts: any): Data => {            
            const data: Data = {
                account_id: getUserAccounts?.account_id,
                task_type: getUserAccounts?.task_type,
                status: getUserAccounts?.status,
                created_at: getUserAccounts?.created_at,
                task: getUserAccounts?.task,
            };

            return data;
        }) || []
        );
    };
    //Use effect to load accounts and also trigger automate progress account query when a new account is dpeloyed
    useEffect(() => {
        if (!loading && data?.getUserDeployEvents?.items) {
        const accountsData: Data[] = transformdata();
        setFilteredData(accountsData);
        }
    }, [data, loading]);
    
    //Use effect for search box
    useEffect(() => {
        if (searchTerm && searchTerm !== "")
        setFilteredData(
            transformdata().filter((d) =>
            Object.values(d).some((value) =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
            )
        );
    }, [searchTerm]);

    useEffect(() => {
        refetch({ organisation: organisation });
    }, [data, queryCall, organisation, refetch]);


    if (error) {
        return <Typography component={"span"} variant="h6" color="text.primary" paragraph>error...</Typography>
    };

    function statusCheck(status: string) {
        if (status === "Successful") {
            return (
            <>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <CheckCircleOutlineOutlinedIcon sx={{ color: "green" }}></CheckCircleOutlineOutlinedIcon>
                <Typography fontSize={"14px"} component={"span"} color={"success.main"} sx={{ ml: 0.5 }}>
                    <PassedText>{status}</PassedText>
                </Typography>
                </Box>
            </>
            );
        }
        return (
            <>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <CancelOutlinedIcon sx={{ color: "red" }}></CancelOutlinedIcon>
                <Typography fontSize={"14px"} component={"span"} color={"error.main"} sx={{ ml: 0.5 }}>
                <FailedText>{status}</FailedText>
                </Typography>
            </Box>
            </>
        );
    }

    function timezone(date:any){
        var localtime = new Date(date).toLocaleString();
        return localtime
    }
    
    return  (
        <Container maxWidth="lg" sx={{ width: 1 }} component={Paper}>
            <Grid container flexDirection="row" sx={{ ml: 0}}>
                <Paper
                    component="form"
                    sx={{
                        p: "0px 0px",
                        mb: 0,
                        mt: "2px",
                        display: "flex",
                        border: 0,
                        boxShadow: 0,
                    }}
                    >
                    <FormControl
                        sx={{ m: 1, ml: 0, width: "305px", alignSelf: "center" }}
                        variant="outlined"
                        size="small"
                    >
                        <InputLabel htmlFor="component-outlined">Search</InputLabel>
                        <OutlinedInput
                        label="Search"
                        id="component-outlined"
                        type={"text"}
                        value={searchTerm}
                        onChange={handleSearch}
                        endAdornment={
                            <InputAdornment position="end">
                            <SearchIcon sx={{ color: "secondary.main" }} />
                            </InputAdornment>
                        }
                        placeholder="Search"
                        inputProps={{ "aria-label": "Search" }}
                        />
                    </FormControl>
                </Paper>
                <Paper
                    component="form"
                    elevation={0}
                    sx={{
                        p: "2px 4px",
                        mb: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        flexGrow: 1,
                    }}
                    >
                    <InputLabel id="page-size" sx={{ alignSelf: "center", p: 1 }}>
                        Show
                    </InputLabel>
                    <FormControl sx={{ m: 1, minWidth: 119 }} size="small">
                        <StyledSelect
                        labelId="page-size-select-label"
                        id="page-size-select"
                        defaultValue={rowsPerPage}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                            setRowsPerPage(Number(e.target.value));
                            setPage(0);
                        }}
                        >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        </StyledSelect>
                    </FormControl>
                </Paper>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Account ID</StyledTableCell>
                            <StyledTableCell>Task Type</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Created At</StyledTableCell>
                            <StyledTableCell>Task</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {loading ? (
                        <TableRow>
                            <StyledTableCell colSpan={12} sx={{ justifyContent: 'center' }}>
                            <Loader></Loader>
                            </StyledTableCell>
                        </TableRow>
                    ) : (
                        filteredData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((DeploymentEvents: any) => {
                            return (
                                <>
                                <TableRow key={DeploymentEvents.account_id}>
                                    <StyledTableCell>{DeploymentEvents.account_id}</StyledTableCell>
                                    <StyledTableCell>{DeploymentEvents.task_type}</StyledTableCell>
                                    <StyledTableCell>{statusCheck(DeploymentEvents.status)}</StyledTableCell>
                                    <StyledTableCell>{timezone(DeploymentEvents.created_at)}</StyledTableCell>
                                    <StyledTableCell>{DeploymentEvents.task}</StyledTableCell>
                                </TableRow>
                                </>
                            );
                        })
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid
                container
                flexDirection="row"
                justifyContent={"flex-end"}
                sx={{ p: 0, pt: 2, pb: 2 }}
            >
                <Pagination
                count={
                    data?.getUserDeployEvents?.items.length
                    ? Math.ceil(data?.getUserDeployEvents?.items.length / rowsPerPage)
                    : 0
                }
                variant="outlined"
                sx={{
                    color: "primary.main",
                    borderColor: "primary.main",
                }}
                shape="rounded"
                page={page + 1}
                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                    setPage(value - 1);
                }}
                />
            </Grid>
        </Container>
    );
};

export default DeploymentEvents;