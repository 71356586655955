import { Box, Switch, TableCell, TableRow } from "@mui/material";
import { useEffect } from "react";
import ComplianceStatus from "../../common/ComplianceStatus";
import StyledTableCell from "../../common/StyledTableCell";

interface QuestionBestPracticeProps {
  index: number;
  rows: any;
  row: any;
  rowSelection: any;
  setRowSelection: any;
  rowNames: any;
  setRowNames: any;
  switchBehavior: any;
  process: string;
  activeSpinner: boolean;
}

export default function QuestionIacBestPractice({
  index,
  rows,
  row,
  rowSelection,
  setRowSelection,
  rowNames,
  setRowNames,
  switchBehavior,
  process,
  activeSpinner,
}: QuestionBestPracticeProps){

  //MAR - Multi Automated Remediation
  //RowSelection displays all the rows that have been selected in the MAR Modal
  //RowNames keeps track of which rows were selected and passes it to the updateBestPracticeMutation
  //SetFailedRows adds controls that have a failed finding to the array and passes it to the MAR Modal
  const handleQuestionAnswer = (row:any) => {
    if(rowSelection.includes(row)){
      setRowSelection(rowSelection.filter(
        (r:any) => r.checkRef !== row.checkRef,
      ));
      setRowNames(rowNames.filter(
        (n:any) => n !== row.checkRef,
      ));
    } else {
      setRowSelection([
        ...rowSelection,row
      ]);
      setRowNames([
        ...rowNames,row.checkRef
      ]);
    }
  };

  //This useEffect triggers if Select All Recommendations? is selected
  useEffect(() => {
    const passedRows = rows.filter((row: any) => row.overallFinding === "PASSED" || row.overallFinding === "UNKNOWN");
    const selectedRows = rows.filter((row: any) => row.overallFinding === "PASSED");
    if (switchBehavior === 'All') {
      setRowSelection(rows);
      setRowNames(rows.map((row:any) => row.checkRef));
    } else {
      setRowSelection(selectedRows);
      setRowNames(passedRows.map((row: any) => row.checkRef));
    }
  }, [switchBehavior]);

  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <StyledTableCell component="th" scope="row">
        {index + 1}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row.checkRef}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" width={"75%"}>
        {row.description}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Box sx={{ display: "flex", justifyContent: "center", borderBottom:'set' }}>
          <ComplianceStatus
            value={row.overallFinding}
          ></ComplianceStatus>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="center" width={"100%"}>
        <Switch
          checked={rowSelection.includes(row) || row.overallFinding === "PASSED"} // Set checked state based on switchBehavior
          disabled={
            row.overallFinding === "PASSED" || 
            row.overallFinding === "UNKNOWN" || 
            activeSpinner
          }
          color="success"
          onClick={() => {
            handleQuestionAnswer(row);
          }}
        />
      </StyledTableCell>
    </TableRow>
  );
}