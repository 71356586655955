import { FC } from "react";
import { SvgIcon } from "@mui/material";
const ProfileSvg: FC = () => (
  <SvgIcon fontSize="inherit">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.25 18.75V21.75C23.25 22.5784 22.5784 23.25 21.75 23.25H18.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 0.75H21.75C22.5784 0.75 23.25 1.42157 23.25 2.25V5.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 5.25V2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H5.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 23.25H2.25C1.42157 23.25 0.75 22.5784 0.75 21.75V18.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.125C14.2782 13.125 16.125 11.2782 16.125 9C16.125 6.72183 14.2782 4.875 12 4.875C9.72183 4.875 7.875 6.72183 7.875 9C7.875 11.2782 9.72183 13.125 12 13.125Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.365 19.125C17.4114 16.4275 14.8611 14.624 12 14.624C9.13894 14.624 6.58858 16.4275 5.63501 19.125"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default ProfileSvg;
