interface Dictionary<T> {
  [Key: string]: T;
}

const changeLocation = (location: string) => {
  let finalLocation: string;
  const locationDictionary: Dictionary<string> = {
    rules: "/rules",
    failures: "/rules?c=FAILED",
    compliant: "/rules?c=PASSED",
    cis120: "/rules?cs=CIS120",
    cis140: "/rules?cs=CIS140",
    pci: "/rules?cs=PCIDSS",
    soc2: "/rules?cs=SOC2",
    cdr: "/rules?cs=CDR",
    iso: "/rules?cs=ISO",
    iso2022: "/rules?cs=ISO2022",
    afsbp: "/rules?cs=AFSBP",
    nist: "/rules?cs=NIST",
    nistcsf: "/rules?cs=NISTCSF",
    wat: "/wat",
    so: "/rules?cs=S0",
    slza: "/rules?cs=SLZA",
    e8: "/rules?cs=E8",
    nzism: "/rules?cs=NZISM",
    watf: "/rules?cs=WAT",
    genai: "/rules?cs=GENAI",
    cccc: "/rules?cs=CCCC",
    fedramp: "/rules?cs=FEDRAMP",
    pci40: "/rules?cs=PCIDSS40",
    controltower: "/rules?cs=CT",
    glba: "/rules?cs=GLBA",
    acsc: "/rules?cs=ACSC",
    gxpeu: "/rules?cs=GXPEU",
    gxp21: "/rules?cs=GXP",
    cis8: "/rules?cs=CIS8",
    nistsp: "/rules?cs=NISTSP",
    hippa: "/rules?cs=HIPAA2013",
    cis71: "/rules?cs=CIS71",
    cis130: "/rules?cs=CIS130",
  };
  finalLocation = locationDictionary[location]
    ? locationDictionary[location]
    : "/rules";
  window.location.href = finalLocation;
};

export { changeLocation };
