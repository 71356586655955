import React, { FC, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";

import {
  Button,
  Dialog,
  Grid,
  Typography,
  LinearProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from "@mui/material";

import Control from "../../models/Control";
import useSnackBars from "../../hooks/useSnackbar";
import { Row } from "@tanstack/react-table";
import dayjs from "dayjs";

import { useScheduleRemediationRequestMutation } from "../../generated/index";
import { LoadingButton } from "@mui/lab";

const ScheduleAutoRemediation: FC<any> = ({
  open,
  handleClose,
  row,
  finding,
}: {
  open: boolean;
  handleClose: any;
  row: Row<Control>;
  finding: any;
}) => {
  const [scheduledAt, setScheduledAt] = useState("");
  const [error, setError] = useState({ status: false, msg: "" });
  const [loading, setLoading] = useState(false);
  const { setAlerts } = useSnackBars();
  const [scheduleRemediationRequestMutation] =
    useScheduleRemediationRequestMutation();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Schedule Remediation</DialogTitle>
        <DialogContent>
          <Grid container sx={{ m: 1 }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ m: 1 }}
            >
              <Grid item>
                <Typography fontSize={"20px"}>
                  You are about to schedule the remediation for the following
                  issue:
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={3}>
              <Typography fontSize={"14px"} fontWeight="bold">
                Control ID :
              </Typography>
            </Grid>
            <Grid item md={9}>
              <Typography fontSize={"14px"}>
                {row.original.controlname}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={3}>
              <Typography fontSize={"14px"} fontWeight="bold">
                Control:
              </Typography>
            </Grid>
            <Grid item md={9}>
              <Typography fontSize={"14px"}>
                {row.original.controldescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={3}>
              <Typography fontSize={"14px"} fontWeight="bold">
                Service ID:
              </Typography>
            </Grid>
            <Grid item md={9}>
              <Typography fontSize={"14px"}>
                {finding?.Resources ? finding?.Resources[0]?.Id : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={3}>
              <Typography fontSize={"14px"} fontWeight="bold">
                Finding ID:
              </Typography>
            </Grid>
            <Grid item md={9}>
              <Typography fontSize={"14px"}>{finding?.Id}</Typography>
            </Grid>
          </Grid>
          {(() => {
            const remediationSteps = row.original.remediationsteps;

            if (!remediationSteps) {
              return null;
            }

            const parsedData = JSON.parse(remediationSteps);
            const hasDescription =
              parsedData.description &&
              typeof parsedData.description === "string" &&
              parsedData.description.trim() !== "";
            const hasDefaultValues =
              parsedData.defaultValues &&
              typeof parsedData.defaultValues === "object" &&
              Object.keys(parsedData.defaultValues).length > 0;

            if (!hasDescription && !hasDefaultValues) {
              return null;
            }

            return (
              <>
                {hasDescription && (
                  <Grid container sx={{ m: 1 }}>
                    <Grid item md={3}>
                      <Typography fontSize={"14px"} fontWeight="bold">
                        Remediation Description:
                      </Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography
                        fontSize={"14px"}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {parsedData.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {hasDefaultValues && (
                  <Grid container sx={{ m: 1 }}>
                    <Grid item md={3}>
                      <Typography fontSize={"14px"} fontWeight="bold">
                        Remediation Default Values:
                      </Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography
                        fontSize={"14px"}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {Object.entries(parsedData.defaultValues).map(
                          ([key, value]) => {
                            return (
                              <React.Fragment>
                                <b>{`${key}`}</b>: {`${value}`}
                                <br />
                              </React.Fragment>
                            );
                          }
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            );
          })()}
          <Grid container sx={{ m: 1 }}>
            <Grid item md={3}>
              <Typography fontSize={"14px"} fontWeight="bold">
                Schedule:
              </Typography>
            </Grid>
            <Grid item md={9}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Schedule remediation."
                  onChange={(newValue: string | null) => {
                    const formattedDate = newValue
                      ? dayjs(newValue).format("DD/MM/YY HH:mm:ss")
                      : "";
                    setScheduledAt(formattedDate);
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          {error.status && <Alert severity="error">{error.msg}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            disabled={loading}
            loading={loading}
            onClick={() => {
              setLoading(true);
              scheduleRemediationRequestMutation({
                variables: {
                  scheduledAt: scheduledAt,
                  findingid: finding?.Id,
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                context: {
                  apiName: "automation_approval",
                },
              })
                .then(({ data }) => {
                  if (data?.scheduleRemediationRequest?.status === 1) {
                    setAlerts([
                      {
                        severity: "success",
                        msg: "The submission for scheduled remediation has been completed.",
                      },
                    ]);
                    handleClose();
                  } else {
                    setError({
                      status: true,
                      msg: data?.scheduleRemediationRequest?.msg || "",
                    });
                  }
                  setLoading(false);
                })
                .catch((err) => {
                  setError({
                    status: true,
                    msg: err.message || JSON.stringify(err),
                  });
                  setLoading(false);
                });
            }}
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </>
  );
};

export default ScheduleAutoRemediation;
