import React, { HTMLProps, useEffect, useRef } from "react";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { Checkbox, SvgIcon } from "@mui/material";

const IndeterminateAllCheckbox = ({
  indeterminate,
  className = "",
  onChange,
  checked,
  ...rest
}: {
  indeterminate?: boolean;
  checked: boolean;
} & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <Checkbox
      inputRef={ref}
      onChange={onChange}
      checked={checked}
      icon={
        <SvgIcon
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="2"
            width="16"
            height="16"
            rx="3.5"
            fill="white"
            stroke="#6F6F6F"
          />
        </SvgIcon>
      }
      checkedIcon={
        <CheckBoxOutlinedIcon
          fill="none"
          htmlColor="white"
        ></CheckBoxOutlinedIcon>
      }
    />
  );
};

const IndeterminateCheckbox = ({
  indeterminate,
  className = "",
  onChange,
  checked,
  ...rest
}: {
  indeterminate?: boolean;
  checked: boolean;
} & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <Checkbox
      inputRef={ref}
      checked={checked}
      onChange={onChange}
      icon={
        <SvgIcon
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="2"
            width="16"
            height="16"
            rx="3.5"
            fill="white"
            stroke="#6F6F6F"
          />
        </SvgIcon>
      }
      checkedIcon={
        <CheckBoxOutlinedIcon
          fill="none"
          htmlColor="primary"
        ></CheckBoxOutlinedIcon>
      }
    />
  );
};

interface IStandardCheckboxProps {
  indeterminate?: boolean;
  checked: boolean;
  className?: string;
  value: string;
  name: string;
  onChange: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

const StandardCheckbox = ({
  indeterminate,
  name,
  value,
  onChange,
  checked,
}: IStandardCheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <Checkbox
      inputRef={ref}
      checked={checked}
      name={name}
      value={value}
      onChange={onChange}
      icon={
        <SvgIcon
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="2"
            width="16"
            height="16"
            rx="3.5"
            fill="white"
            stroke="#6F6F6F"
          />
        </SvgIcon>
      }
      checkedIcon={
        <CheckBoxOutlinedIcon
          fill="none"
          htmlColor="primary"
        ></CheckBoxOutlinedIcon>
      }
    />
  );
};

export { IndeterminateCheckbox, IndeterminateAllCheckbox, StandardCheckbox };
