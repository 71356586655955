import React, { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingProps {
  show: boolean;
}

const LoadingOverlay: FC<LoadingProps> = ({ show }) => {
  return (
    <>
    {
        show && (
            <div style={{
              position: 'fixed',
              top: 140,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <CircularProgress size="50px" color="secondary" />
            </div>
          )
    }
    </>
  );
};

export default LoadingOverlay;
