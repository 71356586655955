import React, { FC, useEffect, useState } from 'react';
import {
    Container,
    Typography,
    Tabs,
    Tab,
    Box,
} from '@mui/material';

import Heading from "../common/Heading";

import ScannerResults from './ScannerResults';
import ScannerUpload from './ScannerUpload';
import GenerateToken from './GenerateToken';
import { client } from "./../../lib/clients/apolloClient";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <Container
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </Container>
    );
}
  
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ScannerPage: FC = () => {
    const [value, setValue] = useState(0);

    const handleChange = async (
      event: React.SyntheticEvent,
      newValue: number
    ) => {
      setValue(newValue);
      client.refetchQueries({include: "active"});
    };

    const handleTabChange = (newValue: number) => {
      setValue(newValue);
      client.refetchQueries({include: "active"});
    }

    useEffect(() => {
        const queryParemeters = new URLSearchParams(window.location.search);
        const tabId: number = Number(queryParemeters.get("tabId"));
        const tabNumbers = [0, 1, 2];
        if (!tabId || !tabNumbers.includes(tabId)) {
            return;
        }
        handleTabChange(tabId);
    }, [])

    return (
    <Container component="main">
      <Container maxWidth="lg">
        <Heading heading={"Template Scanner"} />
        <Typography
          variant="h6"
          color="primary.main"
          paragraph
        >
        This will scan CloudFormation and Terraform templates against defined rules to report security and compliance issues, so the template can be updated to mitigate the risks.
        It may take up to 5 minutes for a file to finish processing.
        </Typography>
      </Container>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label="Upload" {...a11yProps(0)} />
            <Tab label="Review Results" {...a11yProps(1)} />
            <Tab label="Generate Token" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {<ScannerUpload/>}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {<ScannerResults/>}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {<GenerateToken/>}
        </TabPanel>
      </Box>
    </Container>
  );
}

export default ScannerPage;