import { createTheme } from "@mui/material/styles";

/**
 * @primary
 * color is Deep Orange with shade 500
 * @secondary
 * color is Blue Grey with shade 900
 * Can be seen in the below link
 * New Color Scheme - https://m2.material.io/resources/color/#!/?view.left=0&view.right=1&secondary.color=ff6700&primary.color=0e1956&primary.text.color=ffffff&secondary.text.color=ffffff
 */
export const CustomTheme = createTheme({
  palette: {
    background: {
      default: "#F7F7F7",
    },
    text: {
      primary: "#383737",
    },
    primary: {
      light: "#413f83",
      main: "#0e1956",
      dark: "#00002d",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff993f",
      main: "#ff6700",
      dark: "#c43500",
      contrastText: "#fff",
    },
    success: {
      main: "#008A02",
    },
    error: {
      main: "#E90000",
    },
  },
});

export default CustomTheme;
