import { FC } from "react";
import { SvgIcon } from "@mui/material";
const LogoutSvg: FC = () => (
  <SvgIcon fontSize="inherit">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_44_2838)">
        <path
          d="M7.5 12.0039H23.25"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5 15.7539L23.25 12.0039L19.5 8.25391"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7501 16.5V21C15.7857 21.7902 15.1761 22.4606 14.3861 22.5H2.11307C1.32346 22.4601 0.7144 21.7898 0.75007 21V3C0.713843 2.20998 1.3232 1.53937 2.11307 1.5H14.3861C15.1761 1.53938 15.7857 2.20981 15.7501 3V7.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_44_2838">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default LogoutSvg;
