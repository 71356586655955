import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Chip, Box, Paper, Typography } from "@mui/material";

type SetFilesState = React.Dispatch<React.SetStateAction<File[]>>;

interface FileDropzoneProps {
  files: File[];
  setFiles: SetFilesState;
  disabled: boolean;
}

const FileDropzone = ({ files, setFiles, disabled }: FileDropzoneProps) => {
  // A map to hold the preview URLs for each file
  const [previews, setPreviews] = useState<Map<string, string>>(new Map());

  // Handle file drop event
  const onDrop = (acceptedFiles: File[]) => {
    console.log(acceptedFiles);
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    const newPreviews = new Map(previews);
    acceptedFiles.forEach((file) => {
      newPreviews.set(file.name, URL.createObjectURL(file));
    });
    setPreviews(newPreviews);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/*": [], // This already covers text files
      "application/json": [], // JSON files
      "application/x-yaml": [".yaml", ".yml"], // YAML files
      "text/yaml": [".yaml", ".yml"], // YAML can also be text/yaml
      "application/x-tf": [".tf"], // Terraform file type
      "text/plain": [".tf"], // Terraform can be plain text too
    },
  });

  // Handle file deletion
  const handleDelete = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));

    const updatedPreviews = new Map(previews);
    const previewUrl = previews.get(fileName);

    if (previewUrl) {
      URL.revokeObjectURL(previewUrl); // Revoke object URL when file is deleted
      updatedPreviews.delete(fileName);
    }

    setPreviews(updatedPreviews);
  };

  // Cleanup: Revoke object URLs to avoid memory leaks
  useEffect(() => {
    return () => {
      previews.forEach((url) => URL.revokeObjectURL(url)); // Clean up all URLs
    };
  }, [previews]);

  return (
    <Box sx={{ width: "100%", textAlign: "center", mt: 2 }}>
      <Paper
        elevation={3}
        sx={{
          p: 0,
          border: "2px dashed #ff6700",
          backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            p: 10,
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="body1" color="primary">
              Drop the files here ...
            </Typography>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Drag 'n' drop some files here, or click to select files
            </Typography>
          )}
        </Box>
      </Paper>

      <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
        {files.map((file) => (
          <Chip
            key={file.name}
            label={file.name}
            onDelete={!disabled ? () => handleDelete(file.name) : undefined}
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FileDropzone;
