import { FC } from "react";
import { OnResultDocument } from "../../generated";
import { useSubscription } from "@apollo/react-hooks";

interface OnResultProps {
  statemachineid: string;
  setSubscriptionResult: any;
  setStateMachineId: any;
}

const OnResult: FC<OnResultProps> = ({
  statemachineid,
  setSubscriptionResult,
  setStateMachineId,
}: OnResultProps) => {
  useSubscription(OnResultDocument, {
    variables: {
      id: statemachineid || "",
    },
    onData: (onResultData) => {
      let final_result = onResultData?.data?.data?.onResult?.result;
      setSubscriptionResult(final_result);
      setStateMachineId(null);
    },
  });
  return <></>;
};

export default OnResult;
