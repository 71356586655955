import { useState } from "react";
import { useInitiateRemediationsMutation } from "../generated";

interface InitiateRemediations {
  technicalReviewArn: string;
}

interface ControlStatus {
  error: string;
  findingId: string;
  status: string;
  template: string;
}

const useInitiateRemediations = ({
  technicalReviewArn
}: InitiateRemediations) => {
  const [controlsStatus, setControlsStatus] = useState<ControlStatus[]>([]);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initiateRemediations] = useInitiateRemediationsMutation();
  
  async function initiateRemediationsQuery(accountRefId: string, controlname:string){
    let account_ref = technicalReviewArn.split(":")[4];
    if(accountRefId !== ""){
        account_ref = accountRefId;
    }
    return initiateRemediations({
      context: {
        apiName: "automation_approval",
      },
      variables: {
        arn: technicalReviewArn,
        accountRef: account_ref,
        control: controlname,
      },
    })
    .catch((e) => {
      console.log("ERROR", e);
      setStatus(false);
    })
  }

  const initiateBestPracticeRemediations = async (accountRefId:string, controls: string[]) => {
    setLoading(true);
    const promises: any[] = [];
    for (let i = 0; i < controls.length; i++) {
      const controlname = controls[i];
      promises.push(
        initiateRemediationsQuery(accountRefId, controlname)
      );
    }
    const response = await Promise.all(promises);
    response.map(({ data }: any) => {
      setStatus(true);
      let errorFindingRemediationResults: any[] = [];
      data?.initiateRemediations?.forEach(
        (findingRemediationResult: any) => {
          setControlsStatus((oldArray) => [...oldArray, findingRemediationResult]);
          if (findingRemediationResult.status !== "APPROVED") {
            errorFindingRemediationResults.push(
            findingRemediationResult
            );
          }
        }
      );
      if (errorFindingRemediationResults.length > 0) {
        setStatus(false);
      } else {
        setStatus(true);
      }
    });
    setLoading(false);
  };
  return {initiateBestPracticeRemediations, status, loading, controlsStatus};
};

export { useInitiateRemediations };