import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { DownloadSelfAssessmentDocument } from "../../generated";
import { useLazyQuery } from "@apollo/client";
import Alert from '@mui/material/Alert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OnResult from "../subscriptions/OnResult";
import Button from "@mui/material/Button";

interface DownloadSelfAssessmentProps {
  arn: string;
}

export default function DownloadSelfAssessment({arn}: DownloadSelfAssessmentProps){
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [stateMachineId, setStateMachineId] = useState(null);
  const [subscriptionResult, setSubscriptionResult] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [ downloadSelfAssessment, { error:SAError }] = useLazyQuery(DownloadSelfAssessmentDocument, { 
    onCompleted: (SelfAssessmentData) => {
      setStateMachineId(SelfAssessmentData?.downloadSelfAssessment?.id)
      if(SelfAssessmentData?.downloadSelfAssessment?.status === "COMPLETE"){
        Complete(SelfAssessmentData?.downloadSelfAssessment?.result);
      }
    } 
  });

  function Download(){
    setActiveSpinner(true);
    setDisabled(true);
    downloadSelfAssessment({
      variables: { 
        arn:arn, 
      },
      context: {
        apiName: "well_architected"
      },
    });
  }

  useEffect(() => {
    if(subscriptionResult !== null){
      Complete(subscriptionResult);
    }
  }, [subscriptionResult]);

  const onDownloadClick = (url:string, filename:string) => {
    const element = document.createElement("a");
    element.href = url;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  function Complete(result:any){
    var obj = JSON.parse(result);
    onDownloadClick(obj.url, obj.filename);
    setSubscriptionResult(null);
    setActiveSpinner(false);
    setDisabled(false);
  }

  return (
    <>
      <Button disabled={disabled} onClick={() => Download()} 
      id="wat-setup-btn" 
      type="submit" 
      variant="contained"
      endIcon={activeSpinner ? <CircularProgress /> : <CloudDownloadIcon />}
      sx={{
        width:  257,
        height: 40,
        bgcolor: "secondary.main",
        color: "secondary.contrastText",
        "&:hover": {
          bgcolor:"secondary.main",
          color: "secondary.contrastText"
        }
      }}
      >Update & Download
      {stateMachineId !== null ? <OnResult statemachineid={stateMachineId} 
      setSubscriptionResult={setSubscriptionResult} setStateMachineId={setStateMachineId}/> : null}
      </Button>
      {SAError ? <Alert severity="error">Self-Assessment download failed</Alert>: null}
    </>
  );
}
