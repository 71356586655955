import { FC, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { useCognitoDeleteUserMutation } from "./../../generated";
import useSnackBars from "../../hooks/useSnackbar";
import LinearProgress from "@mui/material/LinearProgress";

interface DeleteUserProps {
  arn: string;
  refresh: any;
  username: string;
  email: string;
  disabled: boolean;
}

const DeleteUser: FC<DeleteUserProps> = ({
  arn,
  refresh,
  username,
  email,
  disabled,
}: DeleteUserProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { setAlerts } = useSnackBars();

  const [cognitoDeleteUserMutation, { loading }] =
    useCognitoDeleteUserMutation({
      variables: {
        arn: arn,
        username: username,
      },
    });

  function handleSubmit() {
    cognitoDeleteUserMutation()
      .then((result) => {
        console.log(result);
        setOpen(false);
        if (result.data?.cognitoDeleteUser?.status === 1) {
          setAlerts([
            {
              severity: "success",
              msg: "User removed.",
            },
          ]);
          refresh();
        } else {
          setAlerts([
            {
              severity: "error",
              msg: "User removal failed.",
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Stack direction="row">
      <IconButton disabled={disabled} onClick={handleOpen} color="secondary">
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to remove "{email}". Do you want to continue ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
          <Button
            disabled={loading}
            onClick={handleSubmit}
            variant="contained"
            startIcon={<DeleteIcon />}
            sx={{
              bgcolor: "secondary.main",
              "&:hover": {
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </Stack>
  );
};

export default DeleteUser;
