import { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Alert, CircularProgress, Modal, Stack } from "@mui/material";
import {
  useSubmitIacEmailMutation,
} from "../../../generated";
import useSnackBars from "../../../hooks/useSnackbar";
import LoadingButton from "@mui/lab/LoadingButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
type RowQuestion = {
  id: string;
  description: string;
  status: string;
  updateStatus: string;
};

interface AnswerQuestionnaireIacModalProps {
  wafrArn: string;
  rows: RowQuestion[];
  createdWorkloads: any;
  changeTab: (index: number) => void;
}

export default function AnswerQuestionnaireIacModal({
  wafrArn,
  rows,
  createdWorkloads,
  changeTab,
}: AnswerQuestionnaireIacModalProps) {
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState<string[]>([]);
  const { setAlerts } = useSnackBars();
  const [canDownload, setCanDownload] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false);

  const [submitIacEmailMutation] = useSubmitIacEmailMutation({
    context: {
      apiName: "well_architected",
    },
  });

  const handleSubmit = async () => {
    let isUpdating = rows.some((r) => r.updateStatus === "IN_PROGRESS");
    if (isUpdating) {
      setAlerts([
        {
          severity: "error",
          msg: "Please Wait! Workload is being updated.",
        },
      ]);
    } else {
      setOpen(true);
      setDisabled(true);
      setActiveSpinner(true);
  
      const emailPromises = Object.entries(createdWorkloads).map(
        async ([key, value]) => {
          const submitemail = await submitIacEmailMutation({
            variables: {
              workLoadId: typeof value === "string" ? value : "",
              stage: "FINAL_STAGE",
              awsWafrAccount: wafrArn,
            },
            context: {
              apiName: "well_architected",
            },
          });
          if (submitemail?.data?.submitIacEmail?.result) {
            return submitemail.data.submitIacEmail.result;
          } else {
            throw new Error("Email URL generation failed");
          }
        }
      );
  
      try {
        const links = await Promise.all(emailPromises);
        setLink(links);
      } catch (error: any) {
        setAlerts([
          {
            severity: "error",
            msg: error.message,
          },
        ]);
      } finally {
        setDisabled(false);
        setActiveSpinner(false);
        setCanDownload(true);
      }
    }
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="secondary"
        type="submit"
        disabled={disabled}
        loading={activeSpinner}
        onClick={handleSubmit}
        sx={{
          width: 157,
          height: 40,
          bgcolor: "secondary.main",
          "&:hover": {
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
        loadingIndicator={
          <CircularProgress color={"secondary"} size={22} />
        }
        autoFocus
      >
        Submit
      </LoadingButton>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs md={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Please wait
              </Typography>
            </Grid>
            <Grid item xs md={12}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Thank you for submitting your Well-Architected Framework Review
                - your Well-Architected report is being generated and will be
                lodged with AWS to apply for AWS service credits if eligible.
                The 6pillars team will be in touch in the coming days with an
                update on your application.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Typically, this should take approx. 1 minute.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
                Please do not navigate away, close or refresh your browser until
                you receive confirmation on-screen that your workload has been
                successfully created as this will impact the workload creation
                process.
              </Typography>
              <Alert variant="outlined" severity="info">
                Note: Please ensure that “pop-ups” for the AUTOMATE+ SaaS site/URLs 
                are set to always allow, so that your report can be downloaded. 
                You can also download them on the Review Workloads tab.
              </Alert>
            </Grid>
            <Grid item xs md={4} sx={{ mt: 2 }}></Grid>
            <Grid item xs md={8} sx={{ mt: 2 }}>
              <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
                <Button
                  onClick={() => {
                    setOpen(false);
                    changeTab(4);
                  }}
                  variant="outlined"
                  sx={{
                    width: 100,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!canDownload}
                  onClick={() => {
                    setOpen(false);
                    changeTab(4);
                    // Download each report
                    link.forEach(reportUrl => window.open(reportUrl, '_blank'));
                  }}
                  variant="contained"
                  sx={{
                    width: 250,
                    bgcolor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    },
                  }}
                >
                  Download Report
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
