import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Button } from "@mui/material";
import ControlStandard from "../../models/ControlStandard";

const sanitizeForCSV = (input: string): string => {
  return `"${input.replace(/"/g, '""').replace(/\r?\n|\r/g, " ")}"`;
};

const ExportButton = ({
  type,
  table,
  filter,
  sx,
}: {
  type: string;
  table: any;
  filter: any;
  sx: any;
}) => {
  const blockedComplianceStandards = [
    "AWS Well Architected Framework",
    "Foundational Technical Review",
  ];
  const getHeaders = (type: string = "PDF"): string[] => {
    const baseHeader = [
      "Control ID",
      "Description",
      "Compliance",
      "Severity",
      "Control",
      "Continuous Compliance",
      "Remediation",
    ];
    // Add specific headers based on the compliance standard if the type is CSV.
    if (
      type === "CSV" &&
      filter.complianceStandard !== "" &&
      !blockedComplianceStandards.includes(filter.complianceStandard)
    ) {
      baseHeader.unshift("Control Standard ID");
    }
    return baseHeader;
  };

  const getRows = (type: string = "PDF") => {
    let rows: any = [];
    let includeControlStandard = false;
    if (
      filter.complianceStandard !== "" &&
      !blockedComplianceStandards.includes(filter.complianceStandard)
    ) {
      includeControlStandard = true;
    }

    table.getSortedRowModel().rows.forEach((row: any) => {
      let alertstatus = "N/A";
      if (row.original.alertstatus === "ENABLED") {
        alertstatus = "ON";
      } else if (row.original.alertstatus === "DISABLED") {
        alertstatus = "OFF";
      } else {
      }
      let automation = "N/A";
      if (row.original.remediatestatus === "ENABLED") {
        automation = "ON";
      } else if (row.original.remediatestatus === "DISABLED") {
        automation = "OFF";
      } else {
      }
      let description =
        '"' + row.original.controldescription.replace(/,/g, ";") + '"';
      if (includeControlStandard) {
        row.original.controlAssociations.forEach(
          (controlStandard: ControlStandard) => {
            if (
              controlStandard?.control &&
              controlStandard.standard.includes(filter.complianceStandard)
            ) {
              const newControlData = [
                sanitizeForCSV(controlStandard.control),
                row.original.controlname,
                description,
                row.original.compliancestatus,
                row.original.severity,
                alertstatus,
                automation,
                row.original.remediationUrl,
              ];
              rows.push(newControlData);
            }
          }
        );
      } else {
        const controlData: string[] = [
          row.original.controlname,
          description,
          row.original.compliancestatus,
          row.original.severity,
          alertstatus,
          automation,
          row.original.remediationUrl,
        ];
        rows.push(controlData);
      }
    });
    return rows;
  };

  const csvLines = (lines: any) => {
    return lines
      .map((e: any) => {
        return e.join(",");
      })
      .join("\r\n");
  };

  const exportCsv = () => {
    let csvFile = new Blob(
      [csvLines([getHeaders("CSV")]) + "\r\n" + csvLines(getRows("CSV"))],
      {
        type: "text/csv",
      }
    );
    let link = document.createElement("a");
    link.href = URL.createObjectURL(csvFile);

    link.setAttribute("visibility", "hidden");
    link.download = `controls-${
      filter.complianceStandard === "" ? "all" : filter.complianceStandard
    }.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportPdf = () => {
    const doc = new jsPDF("l", "mm", "a3");
    autoTable(doc, {
      head: [getHeaders()],
      body: getRows(),
    });
    doc.save(
      `controls-${
        filter.complianceStandard === "" ? "all" : filter.complianceStandard
      }.pdf`
    );
  };

  return (
    <Button
      variant="outlined"
      onClick={() => {
        type === "PDF" ? exportPdf() : exportCsv();
      }}
      sx={sx}
    >
      {type}
    </Button>
  );
};

export default ExportButton;
