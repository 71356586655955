import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Button,
  styled,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  CircularProgress,
  Typography,
} from "@mui/material";

import Heading from "../common/Heading";
import useAccount from "./../../hooks/useAccount";

import {
  useGetRemediationDataQuery,
  useRejectRemediationRequestMutation,
  useApproveRemediationRequestMutation,
  useDeleteRemediationMutation,
} from "../../generated/index";

import StyledTableCell from "../common/StyledTableCell";
import useSnackBars from "../../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

interface RemediationResult {
  remediationSteps?: string;
  controlDescription?: string;
  controlBehaviour?: number;
  controlSeverity?: string;
  controlResource?: string;
  status?: string;
  scheduled?: string;
  description?: string;
  findings?: any;
}

const parseRemediationSteps = (jsonStr: string) => {
  try {
    const jsonObj = JSON.parse(jsonStr);
    const description = jsonObj.description;
    const defaultValues =
      JSON.stringify(jsonObj.defaultValues) !== "{}"
        ? JSON.stringify(jsonObj.defaultValues)
        : "N/A";
    return (
      <Typography>
        Description: {description}
        <br />
        Default Values: {defaultValues}
      </Typography>
    );
  } catch (error) {
    return "N/A";
  }
};

const getSeverityColor = (severity: string) => {
  severity = severity.toUpperCase();
  if (severity === "HIGH" || severity === "CRITICAL") {
    return "error.main";
  } else if (severity === "MEDIUM") {
    return "secondary.main";
  } else if (severity === "LOW") {
    return "success.main";
  } else {
    return "text";
  }
};

const StyledButton = styled(Button)({
  textTransform: "none",
  width: "160px",
  height: "40px",
});

const RemediationResultView = () => {
  const queryParemeters = new URLSearchParams(window.location.search);
  let remediationId = queryParemeters.get("id");

  remediationId = remediationId ? decodeURIComponent(remediationId) : "0";

  const { arn } = useAccount();
  const [dataMap, setDataMap] = useState<RemediationResult>({});
  const [loading, setLoading] = useState(true);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const { setAlerts } = useSnackBars();
  const navigate = useNavigate();
  const { data, error, refetch } = useGetRemediationDataQuery({
    variables: {
      arn: arn,
      remediationId: parseInt(remediationId),
    },
    context: {
      apiName: "automation_approval",
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [rejectRemediationRequestMutation, { data: rejectRemediationData }] =
    useRejectRemediationRequestMutation();

  const [approveRemediationRequestMutation, { data: approveRemediationData }] =
    useApproveRemediationRequestMutation();

  const [deleteRemediationMutation, { data: deleteRemediationData }] = 
    useDeleteRemediationMutation();

  useEffect(() => {
    if (data) {
      const remediationData = JSON.parse(data.getRemediationData?.data);
      const findings = remediationData.findings;
      console.log(findings);
      setDataMap(remediationData);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (!remediationId) {
      navigate("/automation-approval");
    }
  }, [remediationId]);

  const handleRejectOnClick = (remediationId: number) => {
    setRejected(true);
    rejectRemediationRequestMutation({
      variables: {
        arn: arn,
        remediationId: remediationId,
      },
      context: {
        apiName: "automation_approval",
      },
    })
      .then((result) => {
        if (result?.data?.rejectRemediationRequest?.status === 1) {
          setAlerts([
            {
              severity: "success",
              msg: "Remediation request rejected successfully.",
            },
          ]);
          navigate("/automation-approval");
        } else {
          setAlerts([
            {
              severity: "error",
              msg: "Remediation request rejection failed.",
            },
          ]);
        }
        setRejected(false);
      })
      .catch((error) => {
        setRejected(false);
      });
  };

  const handleApproveOnClick = (remediationId: number) => {
    setApproved(true);
    approveRemediationRequestMutation({
      variables: {
        arn: arn,
        remediationId: remediationId,
      },
      context: {
        apiName: "automation_approval",
      },
    })
      .then((result) => {
        if (result?.data?.approveRemediationRequest?.status === 1) {
          setAlerts([
            {
              severity: "success",
              msg: "Remediation request approved successfully.",
            },
          ]);
          navigate("/automation-approval");
        } else {
          setAlerts([
            {
              severity: "error",
              msg: "Remediation request approval failed.",
            },
          ]);
        }
        setApproved(false);
      })
      .catch((error) => {
        setApproved(false);
      });
  };

  const handleDeleteOnClick = (remediationId: string) => {
    setDeleted(true);
    deleteRemediationMutation({
      variables: {
        arn: arn,
        remediationId: remediationId,
      },
      context: {
        apiName: "automation_approval",
      },
    })
      .then((result) => {
        if (result?.data?.deleteRemediation?.status === 1) {
          setAlerts([
            {
              severity: "success",
              msg: "Remediation request deleted successfully.",
            },
          ]);
          navigate("/automation-approval");
        } else {
          setAlerts([
            {
              severity: "error",
              msg: "Remediation request delete failed.",
            },
          ]);
        }
        setDeleted(false);
      })
      .catch((error) => {
        setDeleted(false);
      });
  };

  return (
    <Container component="main">
      <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
        <Heading heading="Remediation Approval Result" />
        <Grid container flexDirection="row">
          <TableContainer sx={{ padding: "32px" }} component={Paper}>
            <Table>
              <TableBody>
                {!loading ? (
                  <>
                    <TableRow>
                      <StyledTableCell>
                        <b>Remediation Steps</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {parseRemediationSteps(dataMap.remediationSteps || "")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Control Description</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap.controlDescription}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Control Behaviour</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap.controlBehaviour}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Control Severity</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          color={getSeverityColor(
                            dataMap.controlSeverity || "LOW"
                          )}
                        >
                          {dataMap.controlSeverity}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Control Resource</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap?.controlResource
                          ? dataMap?.controlResource
                          : "N/A"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Status</b>
                      </StyledTableCell>
                      <StyledTableCell>{dataMap.status}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Scheduled</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap?.scheduled ? new Date(dataMap.scheduled).toLocaleString() : "N/A"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Custom Standards</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap?.findings.customStandard.join(", ")}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Finding Account ID</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap?.findings?.findings
                          ? dataMap?.findings?.findings[0].AwsAccountId
                          : "N/A"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Service ID</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap?.findings?.findings
                          ? dataMap?.findings?.findings[0].Resources[0].Id
                          : "N/A"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Name</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap?.findings?.findings
                          ? dataMap?.findings?.findings[0].Resources[0].Name
                          : "N/A"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Finding ID</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap?.findings?.findings
                          ? dataMap?.findings?.findings[0].Id.split(
                              "/"
                            ).reverse()[0]
                          : "N/A"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Finding Title</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dataMap?.findings?.findings
                          ? dataMap?.findings?.findings[0].Title
                          : "N/A"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>
                        <b>Actions</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Grid
                          container
                          spacing={4}
                          alignItems="center"
                          direction="row"
                          padding="12px"
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={2}>
                            <StyledButton
                              color="primary"
                              variant="outlined"
                              disabled={rejected || approved}
                              onClick={() => navigate("/automation-approval")}
                            >
                              Go Back
                            </StyledButton>
                          </Grid>
                          <Grid item xs={2}>
                            <LoadingButton
                              color="secondary"
                              variant="contained"
                              loading={approved}
                              size="small"
                              disabled={approved || dataMap.status != "PENDING"}
                              loadingPosition="end"
                              sx={{ m: 2, width: "160px", height: "40px" }}
                              onClick={() =>
                                handleApproveOnClick(
                                  parseInt(remediationId || "0")
                                )
                              }
                            >
                              Approve
                            </LoadingButton>
                          </Grid>
                          <Grid item xs={2}>
                            <LoadingButton
                              color="secondary"
                              variant="contained"
                              size="small"
                              disabled={
                                approved ||
                                rejected ||
                                dataMap.status != "PENDING"
                              }
                              loading={rejected}
                              sx={{ m: 2, width: "160px", height: "40px" }}
                              onClick={() =>
                                handleRejectOnClick(
                                  parseInt(remediationId || "0")
                                )
                              }
                              loadingPosition="end"
                            >
                              Reject
                            </LoadingButton>
                          </Grid>
                          <Grid item xs={2}>
                            <LoadingButton
                              color="secondary"
                              variant="contained"
                              size="small"
                              loading={deleted}
                              disabled={dataMap.status !== "SCHEDULED"}
                              sx={{ m: 2, width: "160px", height: "40px" }}
                              onClick={() =>
                                handleDeleteOnClick(
                                  remediationId || "0"
                                )
                              }
                              loadingPosition="end"
                            >
                            Delete
                          </LoadingButton>
                        </Grid>
                        </Grid>
                      </StyledTableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <StyledTableCell align="center" colSpan={2}>
                      <CircularProgress color="secondary" />
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </Container>
  );
};

export default RemediationResultView;
