import { ThemeProvider } from "@mui/material/styles";
import CustomTheme from "./css/ColorPalettes";
import Landing from "./components/authentication/landing";
import { ApolloProvider } from "@apollo/client";
import { client } from "./lib/clients/apolloClient";
import { AccountProvider } from "./providers/AccountProvider";
import { SnackBarProvider } from "./providers/SnackbarProvider";
import { CognitoProvider } from "./providers/CognitoProvider";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { publicRoutes, authProtectedRoutes } from "./routes/routes";
import Layout from "./components/layout/layout";
import Authmiddleware from "./routes/auth";
import { useState } from "react";

const theme = CustomTheme;

export default function App() {
  const [refeshStatus, setRefeshStatus] = useState(false);
  const logout = () => {
    setRefeshStatus(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <CognitoProvider logout={logout}>
        <SnackBarProvider>
          <Router>
            <Routes>
              {publicRoutes.map((r) => {
                return (
                  <Route
                    key={r.path}
                    index={r.path === "/signup"}
                    path={r.path}
                    element={<Landing type={r.type}></Landing>}
                  />
                );
              })}
              {authProtectedRoutes.map((r) => {
                const Component = r.component;
                return (
                  <Route
                    key={r.path}
                    index={r.path === "/"}
                    path={r.path}
                    element={
                      <ApolloProvider client={client}>
                        <AccountProvider>
                          <Authmiddleware
                            layout={Layout}
                            component={Component}
                            isAuthProtected={true}
                            multiaccount={r.multiAccount}
                            path={r.path}
                          ></Authmiddleware>
                        </AccountProvider>
                      </ApolloProvider>
                    }
                  />
                );
              })}
            </Routes>
          </Router>
        </SnackBarProvider>
      </CognitoProvider>
    </ThemeProvider>
  );
}
