import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import {
  useStartStackSetDeploySfnMutation,
  useGetSecurityHubMembersQuery,
} from "../../generated";
import LoadingButton from "@mui/lab/LoadingButton";
import useSnackBars from "../../hooks/useSnackbar";
import useRegions from "../../hooks/useRegions";
import Loader from "../common/Loader";
import { StandardCheckbox } from "../common/Checkboxes";

interface OrganisationDeployProps {
  arn: string;
  defaultregion: string;
}

const validationSchema = yup.object({
  administratorRoleArn: yup
    .string()
    .required("Administrator Role Arn is required"),
  executionRoleName: yup.string().required("Execution Role Arn is required"),
  permissionModel: yup.string().required("Execution Role Arn is required"),
  regions: yup
    .array(yup.string())
    .required()
    .min(1, "At least one region is required"),
  accountRefs: yup
    .array(yup.string().min(12).max(12))
    .required()
    .min(1, "At least one aws account is required"),
});

export default function OrganisationDeployDialog({
  arn,
  defaultregion,
}: OrganisationDeployProps) {
  const [open, setOpen] = useState(false);
  const regions = useRegions();
  const [execusionArn, setExecusionArn] = useState("");
  const [loading, setLoading] = useState(false);
  const { setAlerts } = useSnackBars();
  const handleClose = (event: any) => {
    setOpen(false);
    setLoading(false);
    setExecusionArn("");
  };

  //Delete account api
  const [startStackSetDeploySfnMutation] = useStartStackSetDeploySfnMutation();
  const {
    data,
    loading: accountsLoading,
    error,
  } = useGetSecurityHubMembersQuery({
    variables: {
      arn: arn,
    },
    context: {
      apiName: "user_deploy_process",
    },
  });
  const accountRefs: Array<string> = [];
  const selected_regions: Array<string> = [];

  const formik = useFormik({
    initialValues: {
      administratorRoleArn: `arn:aws:iam::${
        arn.split(":")[4]
      }:role/AWSCloudFormationStackSetAdministrationRole`,
      executionRoleName: "AWSCloudFormationStackSetExecutionRole",
      permissionModel: "SELF_MANAGED",
      regions: selected_regions,
      accountRefs: accountRefs,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      startStackSetDeploySfnMutation({
        variables: {
          arn: arn,
          administratorRoleArn: values.administratorRoleArn,
          executionRoleName: values.executionRoleName,
          permissionModel: values.permissionModel,
          accountRefs: values.accountRefs,
          regions: values.regions,
        },
        context: {
          apiName: "user_deploy_process",
        },
      }).then((result) => {
        setLoading(false);
        if (result.data?.startStackSetDeploySfn?.executionArn) {
          setExecusionArn(result.data?.startStackSetDeploySfn?.executionArn);
          setOpen(false);
          formik.resetForm();
          setAlerts([
            {
              severity: "success",
              msg: "Please check your Cloudformation Stacksets to deploy to accounts your like",
            },
          ]);
        } else {
          setOpen(false);
          setAlerts([
            {
              severity: "error",
              msg: "Stack set deployment failed",
            },
          ]);
        }
      });
    },
  });

  useEffect(() => {}, [data, accountsLoading, error]);

  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: 100,
          bgcolor: "secondary.main",
          "&:hover": {
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
        onClick={() => {
          setOpen(true);
          setLoading(false);
        }}
      >
        Step 2
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ pb: 0.5, fontSize: "16px" }}>
          <b>
            Step 2 : Uses a CloudFormation stack to configure AWS (ASR)
            Automated Security Remediation member stacks to additional AWS
            accounts in your AWS Organisation Unit (OU)
          </b>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ overflow: "hidden", pt: 0 }}>
            <Typography fontSize={"12px"} sx={{ pt: 2, pb: 1 }}>
              We require a few details in order to create two new AWS StackSet’s
              in your AWS account.
            </Typography>
            <Typography fontSize={"12px"} sx={{ pb: 1 }}>
              New Stackset's
            </Typography>
            <Typography fontSize={"12px"} sx={{ pb: 1 }}>
              StackSet 1: ASR IAM roles required by each Auto Remediation
              Playbook
            </Typography>
            <Typography fontSize={"12px"} sx={{ pb: 1 }}>
              StackSet 2: ASR Auto Remediation Playbooks​
            </Typography>
            <Typography fontSize={"12px"} sx={{ pt: 1, pb: 3 }}>
              Please provide the relevant AWS Organisation Delegated
              Administrator Account Role ARN & the AWS Organisation Member
              Account Execution Role Name and select which regions you wish to
              deploy AUTOMATE+ functionality to, below:
            </Typography>
            <TextField
              fullWidth
              id="administratorRoleArn"
              name="administratorRoleArn"
              label="AWS Organisation Delegated Administrator Account Role ARN"
              value={formik.values.administratorRoleArn}
              sx={{ pb: 2, mt: 0 }}
              onChange={formik.handleChange}
              error={
                formik.touched.administratorRoleArn &&
                Boolean(formik.errors.administratorRoleArn)
              }
              helperText={
                formik.touched.administratorRoleArn &&
                formik.errors.administratorRoleArn
              }
            />
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="executionRoleName"
                name="executionRoleName"
                label="AWS Organisation Member Account Execution Role Name"
                value={formik.values.executionRoleName}
                sx={{ pb: 2 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.executionRoleName &&
                  Boolean(formik.errors.executionRoleName)
                }
                helperText={
                  formik.touched.executionRoleName &&
                  formik.errors.executionRoleName
                }
              />
            </FormControl>
            <Box sx={{ overflow: "auto" }}>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
                error={
                  formik.touched.accountRefs &&
                  Boolean(formik.errors.accountRefs)
                }
              >
                <FormLabel
                  component="legend"
                  sx={{ pb: "20px" }}
                  error={
                    formik.touched.accountRefs &&
                    Boolean(formik.errors.accountRefs)
                  }
                >
                  Select AWS SecurityHub Conneted AWS Organisation Member
                  Accounts to Deploy
                </FormLabel>
                <FormGroup>
                  {accountsLoading ? (
                    <Box color={"grey"}>
                      <Loader></Loader>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {data?.getSecurityHubMembers?.map((member: any) => {
                    const checked = formik.values.accountRefs.some(
                      (id) => id === member.AccountId
                    );
                    return (
                      <FormControlLabel
                        key={member.AccountId}
                        control={
                          <StandardCheckbox
                            name="accountRefs"
                            value={member.AccountId}
                            checked={checked}
                            onChange={(e) => {
                              if (e.target?.checked) {
                                if (
                                  !formik.values.accountRefs.includes(
                                    member.AccountId
                                  )
                                ) {
                                  formik.setFieldValue("accountRefs", [
                                    ...formik.values.accountRefs,
                                    member.AccountId,
                                  ]);
                                }
                              } else {
                                const accountsRef = [
                                  ...formik.values.accountRefs,
                                ];

                                formik.setFieldValue(
                                  "accountRefs",
                                  accountsRef.filter(
                                    (id) => id != member.AccountId
                                  )
                                );
                              }
                            }}
                          ></StandardCheckbox>
                        }
                        label={member.AccountId}
                      />
                    );
                  })}
                </FormGroup>
                <FormHelperText
                  error={
                    formik.touched.accountRefs &&
                    Boolean(formik.errors.accountRefs)
                  }
                >
                  {formik.touched.accountRefs && formik.errors.accountRefs}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box sx={{ overflow: "auto" }}>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
                error={formik.touched.regions && Boolean(formik.errors.regions)}
              >
                <FormLabel
                  component="legend"
                  error={
                    formik.touched.regions && Boolean(formik.errors.regions)
                  }
                  sx={{ pb: "20px" }}
                >
                  Select AWS Regions Required to Deploy Automate+ ASR in AWS
                  Organisation Member Accounts
                </FormLabel>
                <FormGroup>
                  <Grid container spacing={2}>
                    {Array.from(regions.keys()).map((region: any) => {
                      const checked = formik.values.regions.some(
                        (selectedRegion) => selectedRegion === region
                      );
                      return (
                        <Grid item xs={6} sx={{ paddingTop: "0px" }}>
                          <FormControlLabel
                            key={region}
                            sx={{ minWidth: "250px", paddingTop: "0px" }}
                            control={
                              <StandardCheckbox
                                name="regions"
                                value={region}
                                checked={checked}
                                onChange={(e) => {
                                  if (e.target?.checked) {
                                    if (
                                      !formik.values.regions.includes(region)
                                    ) {
                                      formik.setFieldValue("regions", [
                                        ...formik.values.regions,
                                        region,
                                      ]);
                                    }
                                  } else {
                                    const selectedRegions = [
                                      ...formik.values.regions,
                                    ];

                                    formik.setFieldValue(
                                      "regions",
                                      selectedRegions.filter(
                                        (selectedRegion) =>
                                          selectedRegion != region
                                      )
                                    );
                                  }
                                }}
                              ></StandardCheckbox>
                            }
                            label={regions.get(region)}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormGroup>
                <FormHelperText
                  error={
                    formik.touched.regions && Boolean(formik.errors.regions)
                  }
                >
                  {formik.touched.regions && formik.errors.regions}
                </FormHelperText>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              disabled={loading}
              loading={loading}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </form>
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </>
  );
}
