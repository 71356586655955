import {
  Paper,
  Grid,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import ExportButton from "../common/ExportButton";

const FilterCheckbox = ({ name, checked, value, handleChange }: any) => {
  return (
    <Checkbox
      checked={checked}
      onChange={(e, checked) => {
        if (checked) {
          handleChange(name, value);
        } else {
          handleChange(name, "");
        }
      }}
    />
  );
};

const Filter = ({ filter, setFilter, table }: any) => {
  const handleChange = (name: string, value: string) => {
    const data: any = {};
    data[name] = value;
    setFilter({ ...filter, ...data });
  };

  return (
    <Grid container justifyContent="flex-start" flexDirection={"column"}>
      <Box m={4} mb={1} sx={{ flexGrow: 1 }}>
        <Stack direction="row" spacing={3}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              flexGrow: 1,
              backgroundColor: "background.default",
            }}
          >
            <Grid container justifyContent="flex-start">
              <Typography
                component="h4"
                align="left"
                color="primary.main"
                gutterBottom
                sx={{ pt: 0, fontWeight: 500 }}
              >
                Other
              </Typography>
            </Grid>
            <Grid container justifyContent="flex-start">
              <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"compliant"}
                    checked={filter?.compliant === "PASSED"}
                    value={"PASSED"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Compliant"
              />
              <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"compliant"}
                    checked={filter?.compliant === "FAILED"}
                    value={"FAILED"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Non-Compliant"
              />
            </Grid>
            <Grid container justifyContent="flex-start">
              <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"destructive"}
                    checked={filter?.destructive === "YES"}
                    value={"YES"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Possibly Disruptive"
              />
              <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"destructive"}
                    checked={filter?.destructive === "NO"}
                    value={"NO"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Non-Disruptive"
              />
            </Grid>
          </Paper>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              flexGrow: 1,
              backgroundColor: "background.default",
            }}
          >
            <Grid
              item
              md={4}
              flexDirection={"column"}
              justifyContent="flex-start"
            >
              <Typography
                component="h4"
                align="left"
                color="primary.main"
                gutterBottom
                sx={{
                  pt: 0,
                  fontWeight: 500,
                }}
              >
                Generate Reports
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <ExportButton
                  type={"CSV"}
                  table={table}
                  filter={filter}
                  sx={{
                    m: 0,
                    mr: 1,
                    mb: 1,
                    mt: 2,
                    flexGrow: 0,
                    alignSelf: "strech",
                    fontWeight: 600,
                  }}
                ></ExportButton>
                <ExportButton
                  type={"PDF"}
                  table={table}
                  filter={filter}
                  sx={{
                    m: 0,
                    mr: 5,
                    mb: 1,
                    mt: 2,
                    flexGrow: 0,
                    alignSelf: "strech",
                    fontWeight: 600,
                  }}
                ></ExportButton>
              </Box>
            </Grid>
          </Paper>
        </Stack>
      </Box>
      <Box m={1} ml={4} mb={3} sx={{ flexGrow: 1 }}>
        <Link
          component="button"
          variant="body2"
          color="secondary.main"
          onClick={() => {
            setFilter({
              complianceStandard: "",
              destructive: "",
              compliant: "",
            });
          }}
        >
          Clear Selection
        </Link>
      </Box>
    </Grid>
  );
};

export default Filter;
