import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { AutomateProgressStatusDocument, GetCallerIdentityDocument, GetUrlDocument, UserUpgradeProcessDocument } from "../../generated";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { useLazyQuery } from "@apollo/react-hooks";
import { CircularProgress } from "@mui/material";

interface UpgradeAccountProps {
  organisation: string;
  arn: string;
  accessnickname: string;
  externalId: string;
  defaultregion: string;
  disableButton: any;
  setProgressStatus: any;
  setAutomateProgress: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function UpgradeAccount({
  organisation,
  arn,
  accessnickname,
  externalId,
  defaultregion,
  disableButton,
  setProgressStatus,
  setAutomateProgress
}: UpgradeAccountProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [disable, setDisable] = useState(false);
  const [upgradeDisable, setUpgradeDisable] = useState(true); //set this back to false when we need to enable upgrade button
  const [upgradeStatus, setUpgradeStatus] = useState(false);
  const [accId, setAccId] = useState("");

  const [getEnvAccountId] = useLazyQuery(GetCallerIdentityDocument);

  //Get the account id from the arn
  let accountid = arn.split(":")[4];

  // Gets the account id of the 6pillars aws account 
  // and passess it to one of the variables for the cfn template
  useEffect(() => {
    getEnvAccountId({
      variables: {
        arn: "",
      },
      onCompleted: (CallerIdentityData) => {
        setAccId(CallerIdentityData?.getCallerIdentity?.Account);
      },
    })
  }, [accId]);

  //Set the upgrade button to disable if a progress is ongoing
  // useEffect(() => {
  //   if(disableButton === true){
  //     setUpgradeDisable(true);
  //   } else {
  //     setUpgradeDisable(false);
  //   }
  // }, [disableButton]);

  // Call Automate Progress Status Query Once Upgrade Process Statemachine has been triggered
  const [automateProgressStatus] = useLazyQuery(AutomateProgressStatusDocument, {
    onCompleted: (ProgressStatusData) => {
      setProgressStatus(ProgressStatusData?.automateProgressStatus?.status || "");
      setUpgradeStatus(false);
      setAutomateProgress(true);
      handleClose();
    },
  })

  //Call the Upgrade Process API to trigger the upgrade statemachine
  const [userUpgradeProcess] = useLazyQuery(UserUpgradeProcessDocument, {
    onCompleted: () => {
      //Add a delay before calling Automate Progress API so that the Upgrade Process Statemachine can start
      //And an entry can be made into the database with the new statemachine execution arn
      setTimeout(() => 
        automateProgressStatus({
          variables: {
            accountid: accountid,
            organisation: organisation,
          },  
          context: {
            apiName: "user_deploy_process"
          },
        }),30000
      );
    }
  });


  // Get CFN Template for upgrade stack and open it in new tab window
  const [getRoleTemplateURL] = useLazyQuery(GetUrlDocument, {
    onCompleted: (RoleTemplateURLData) => {
      let launchurl = `https://console.aws.amazon.com/cloudformation/home?region=${defaultregion}#/stacks/create/review?stackName=six-pillars-upgrade&templateURL=${encodeURIComponent(
        RoleTemplateURLData?.getTemplateS3URL
      )}&param_ExternalId=${externalId}&param_AccountId=${accId}`;
      setTimeout(() => window.open(launchurl), 1000);
      handleOpen();
    },
  });

  function Buttonclick() {
    setDisable(true);
    setUpgradeStatus(true);

    getRoleTemplateURL({
      variables: {
        template: "user-deploy-process-templates/SixPillarsUpgradeRoleStack.template.json", // value for 'template'
      },
    });
 
    userUpgradeProcess({
      variables: {
        organisation: organisation,// value for 'organisation'
        accessnickname: accessnickname,// value for 'accessnickname'
        arn: arn,// value for 'arn'
        externalid: externalId,// value for 'externalid'
        region: defaultregion,// value for 'region'
        accounttype: "AUTOMATED",// value for 'accounttype'
      },
      context: {
        apiName: "user_deploy_process"
      },
    });

    setAccId("");
  }

  return (
    <Stack direction="row">
      <Button
        onClick={handleOpen}
        disabled={upgradeDisable}
        id="upgrade-setup-btn"
        type="submit"
        variant="contained"
        sx={{
          width: 90,
          height: 40,
          bgcolor: "secondary.main",
          "&:hover": {
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
      >
        Upgrade
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs md={10}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                AWS AccountID: {arn.split(":")[4]}
              </Typography>
            </Grid>
            <Grid item xs md={2}>
              <Button onClick={() => handleClose()}>
                <CloseIcon />
              </Button>
            </Grid>
            <Grid item xs md={12}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                This will use <b>CloudFormation</b> to upgrade AUTOMATE from your
                AWS Account. Do you wish to continue?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Please ensure you are logged into the above AWS account before
                you proceed.
              </Typography>
            </Grid>
            <Grid item xs md={4} sx={{ mt: 2 }}></Grid>
            <Grid item xs md={8} sx={{ mt: 2 }}>
              <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
                <Button
                  disabled={disable}
                  onClick={() => Buttonclick()}
                  type="submit"
                  variant="outlined"
                  sx={{
                    width: 90,
                    height: 40,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  {upgradeStatus === true ? (
                    <CircularProgress color={"secondary"} /> 
                  ) : "Yes" }
                </Button>
                <Button
                  disabled={disable}
                  onClick={() => handleClose()}
                  type="submit"
                  variant="contained"
                  sx={{
                    width: 90,
                    height: 40,
                    bgcolor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    },
                  }}
                >
                  No
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Stack>
  );
}