import { useCreateIacMilestoneMutation } from "../generated";

const useCreateIacMilestone = () => {

    const [createIacMilestoneMutation] = useCreateIacMilestoneMutation();

    const createIacMilestone = async (
        workloadid: string, milestonename: string, awsWafrAccount: string
    ) => {
        const milestonecreate = await createIacMilestoneMutation({
            variables: {
              workloadid: workloadid,
              milestonename: milestonename,
              awsWafrAccount: awsWafrAccount,
            },
            context: {
                apiName: "well_architected",
            },
            notifyOnNetworkStatusChange: true,
        });
        return milestonecreate;
    };
    return {createIacMilestone};
}

export { useCreateIacMilestone };