import { FC } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

interface EditUserProps {
  email: string;
  disabled: boolean;
}

const EditUser: FC<EditUserProps> = ({ email, disabled }: EditUserProps) => {
  const navigate = useNavigate();
  const performRedirect = () => {
    const path = "edit";
    navigate(path);
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={performRedirect}
        color="secondary"
      >
        <EditIcon />
      </IconButton>
    </>
  );
};

export default EditUser;
