import { FC } from "react";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";

interface HeadingProps {
  heading: string;
}

const Heading: FC<HeadingProps> = ({ heading }) => {
  return (
    <>
      <Helmet>
        <title>{heading}</title>
      </Helmet>
      <Typography variant="h3" color="primary.main" gutterBottom sx={{ pt: 4 }}>
        {heading}
      </Typography>
    </>
  );
};

export default Heading;
