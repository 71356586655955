import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  List,
  ListItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItemButton,
  ListItemText,
  DialogActions,
  Button,
  CircularProgress,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Formik, Form, Field, FieldProps } from "formik";
import * as yup from "yup";

interface RollbackAccountModalProps {
  arn: string;
  open: boolean;
  disable: boolean;
  handleClose: () => void;
  handleProceed: (values: any) => void;
  rollbackStatus: boolean;
  displayQuestions: boolean;
  formikRef: any;
  templateUrl: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  question1: yup.string().required("Please select an answer"),
  // question2: yup.string().required("Please select an answer"),
  // question3: yup.string().required("Please select an answer")
});

const RollbackAccountModal: React.FC<RollbackAccountModalProps> = ({
  arn,
  open,
  disable,
  handleClose,
  handleProceed,
  rollbackStatus,
  displayQuestions,
  formikRef,
  templateUrl,
}) => {
  const questions = [
    "Would you like to keep AWS Security Hub enabled in your AWS account?",
    // "Would you like to keep AWS config enabled?",
    // "Would you like to keep ASR and remove Automate?"
  ];

  const initialValues = {
    question1: "",
    // question2: "",
    // question3: ""
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleProceed(values);
      }}
    >
      {(formik) => (
        <Form>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              AWS Account ID: {arn.split(":")[4]}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              {displayQuestions === true ? (
                <>
                  <Typography gutterBottom>
                    This will use <b>CloudFormation</b> to remove AUTOMATE from
                    your AWS Account. Please ensure you are logged into the
                    above AWS account before you proceed.
                  </Typography>
                  <Typography gutterBottom>
                    Click <b>"Open Template"</b> to launch the cloudformation
                    template.
                  </Typography>
                  <Typography gutterBottom>
                    After you have launched the template you can close this
                    modal.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography gutterBottom>
                    Before we proceed please select any services you would like
                    to keep in your AWS Account.
                  </Typography>
                  <List dense>
                    {questions.map((question, index) => {
                      const labelId = `radio-buttons-group-${index}`;
                      return (
                        <ListItem key={index} disablePadding>
                          <ListItemButton>
                            <ListItemText id={labelId} primary={question} />
                            <Field name={`question${index + 1}`}>
                              {({ field, form }: FieldProps) => (
                                <>
                                  <RadioGroup
                                    row
                                    {...field}
                                    aria-labelledby={labelId}
                                  >
                                    <FormControlLabel
                                      sx={{
                                        color:
                                          form.errors[`question${index + 1}`] &&
                                          form.touched[`question${index + 1}`]
                                            ? "red"
                                            : undefined,
                                      }}
                                      value="Yes"
                                      control={<Radio disabled={disable} />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      sx={{
                                        color:
                                          form.errors[`question${index + 1}`] &&
                                          form.touched[`question${index + 1}`]
                                            ? "red"
                                            : undefined,
                                      }}
                                      value="No"
                                      control={<Radio disabled={disable} />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </>
                              )}
                            </Field>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Typography gutterBottom>
                    Please ensure you are logged into the above AWS account
                    before you proceed. Do you wish to continue?
                  </Typography>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {displayQuestions === true ? (
                <Button
                  href={templateUrl}
                  id="automate-template-launch-rollback-btn"
                  type="submit"
                  target={"_blank"}
                  rel={"noopener"}
                  variant="contained"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    },
                  }}
                >
                  Open Template <OpenInNewIcon />
                </Button>
              ) : (
                <>
                  <Button
                    autoFocus
                    disabled={disable}
                    onClick={handleClose}
                    type="button"
                    variant="outlined"
                    sx={{
                      width: 157,
                      height: 40,
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  >
                    No
                  </Button>
                  <Button
                    autoFocus
                    disabled={disable}
                    onClick={formik.submitForm}
                    type="submit"
                    variant="contained"
                    sx={{
                      width: 157,
                      height: 40,
                      bgcolor: "secondary.main",
                      "&:hover": {
                        bgcolor: "secondary.main",
                        color: "secondary.contrastText",
                      },
                    }}
                  >
                    {rollbackStatus === true ? (
                      <CircularProgress color={"secondary"} size={25} />
                    ) : (
                      "Yes"
                    )}
                  </Button>
                </>
              )}
            </DialogActions>
          </BootstrapDialog>
        </Form>
      )}
    </Formik>
  );
};

export default RollbackAccountModal;
