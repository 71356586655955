import { SvgIcon } from '@mui/material';

const CircleSVG = (props: any) => {
    let fillColor = "";
    let isZero = false;
    if (props.percent > 0 && props.percent <= 65) {
        //red
        fillColor = "#E90000"
    }else if (props.percent > 65 && props.percent <= 85) {
        //orange
        fillColor = "#FF6700"
    }else if (props.percent > 85 && props.percent <= 100) {
        //green
        fillColor = "#008A02"
    }else if (props.percent === 0 || Number.isNaN(props.percent)) {
        fillColor = "#0069B5"
        isZero = true;
    }else {
        fillColor = "#0069B5"
        isZero = true;
    }
    return (
        <SvgIcon {...props}>
            {
            isZero ? 
            <circle stroke="#0069B5" cx="23.5" cy="23.5001" r="23.5" fill="white" /> :
            <circle cx="23.5" cy="23.5001" r="23.5" fill={fillColor} />
            }
            <text fill={isZero ? "#0069B5" : "white"} fontSize="16px" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{isZero ? "0%" : props.text}</text> 
        </SvgIcon>
    )
}
export default CircleSVG;