import {
  Paper,
  Grid,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import EmailDetailedFindings from "../common/EmailDetailedFindings";
import ExportSelection from "../common/ExportSelection";
const FilterCheckbox = ({ name, checked, value, handleChange }: any) => {
  return (
    <Checkbox
      checked={checked}
      onChange={(e, checked) => {
        if (checked) {
          handleChange(name, value);
        } else {
          handleChange(name, "");
        }
      }}
    />
  );
};

const Filter = ({ filter, setFilter, table }: any) => {
  const handleChange = (name: string, value: string) => {
    const data: any = {};
    data[name] = value;
    setFilter({ ...filter, ...data });
  };

  return (
    <Grid container justifyContent="flex-start" flexDirection={"column"}>
      <Box m={4} mb={1} sx={{ flexGrow: 1 }}>
        <Stack direction="row" spacing={3}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              minWidth: "78%",
              backgroundColor: "background.default",
            }}
          >
            <Grid container justifyContent="flex-start">
              <Typography
                component="h4"
                align="left"
                color="primary.main"
                gutterBottom
                sx={{ pt: 0, fontWeight: 500 }}
              >
                Compliance Standards filters
              </Typography>
            </Grid>
            <Grid
              container
              direction={"row"}
              sx={{ overflowY: "scroll", height: "300px" }}
            >
              <Grid
                item
                md={6}
                display={"flex"}
                justifyContent="flex-start"
                flexDirection={"column"}
              >
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "AWS Well Architected Framework"
                      }
                      value={"AWS Well Architected Framework"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary", flexWrap: "nowrap" }}
                  label="AWS Well-Architected Framework Review"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "Secure Landing Zone Assessment"
                      }
                      value={"Secure Landing Zone Assessment"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="AWS Secure Landing Zone Assessment"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "Foundational Technical Review"
                      }
                      value={"Foundational Technical Review"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary", flexWrap: "nowrap" }}
                  label="AWS Foundational Technical Review"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "AWS Foundational Security Best Practices v1.0.0"
                      }
                      value={"AWS Foundational Security Best Practices v1.0.0"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="AWS Foundational Security Best Practices v1.0.0"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "CIS AWS Foundations Benchmark v1.2.0"
                      }
                      value={"CIS AWS Foundations Benchmark v1.2.0"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="CIS AWS Foundations Benchmark v1.2.0"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "CIS Benchmark for CIS Amazon Web Services Foundations Benchmark, v1.4.0, Level 1 and 2"
                      }
                      value={
                        "CIS Benchmark for CIS Amazon Web Services Foundations Benchmark, v1.4.0, Level 1 and 2"
                      }
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="CIS AWS Foundations Benchmark v1.4.0"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "NZISM"}
                      value={"NZISM"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="NZISM"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "PCI DSS v4.0"}
                      value={"PCI DSS v4.0"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="PCI DSS v4.0"
                />
              </Grid>
              <Grid
                item
                md={6}
                display={"flex"}
                justifyContent="flex-start"
                flexDirection={"column"}
              >
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "NIST Cybersecurity Framework version 1.1"
                      }
                      value={"NIST Cybersecurity Framework version 1.1"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary", flexWrap: "nowrap" }}
                  label="NIST CSF"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "PCI DSS V3.2.1"}
                      value={"PCI DSS V3.2.1"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="PCI DSS V3.2.1"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "CDR"}
                      value={"CDR"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Consumer Data Right (CDR)"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "ISO"}
                      value={"ISO"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="ISO 27001:2013"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "ISO-IEC 27001:2022 Annex A"
                      }
                      value={"ISO-IEC 27001:2022 Annex A"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="ISO 27001:2022"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "SOC 2"}
                      value={"SOC 2"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="SOC 2"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "Essential Eight"}
                      value={"Essential Eight"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Essential Eight"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "HIPAA Final Omnibus Security Rule 2013"
                      }
                      value={"HIPAA Final Omnibus Security Rule 2013"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="HIPAA Final Omnibus Security Rule 2013"
                />
                {/* <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "AWS Generative AI Best Practices Framework v1"
                      }
                      value={"AWS Generative AI Best Practices Framework v1"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="AWS Generative AI Best Practices Framework"
                /> */}
              </Grid>
              <Grid
                item
                md={6}
                display={"flex"}
                justifyContent="flex-start"
                flexDirection={"column"}
              >
                {/* <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"complianceStandard"}
                    checked={
                      filter?.complianceStandard ===
                      "Canadian Centre for Cyber Security - Medium"
                    }
                    value={"Canadian Centre for Cyber Security - Medium"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Canadian Centre for Cyber Security - Medium"
              /> */}
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "FedRAMP Moderate Baseline"
                      }
                      value={"FedRAMP Moderate Baseline"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="FedRAMP Moderate Baseline"
                />
                {/* <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"complianceStandard"}
                    checked={
                      filter?.complianceStandard ===
                      "Gramm-Leach-Bliley Act (GLBA)"
                    }
                    value={"Gramm-Leach-Bliley Act (GLBA)"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Gramm-Leach-Bliley Act (GLBA)"
              /> */}
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "ACSC Information Security Manual"
                      }
                      value={"ACSC Information Security Manual"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="ACSC Information Security Manual"
                />
                {/* <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"complianceStandard"}
                    checked={
                      filter?.complianceStandard ===
                      "CIS Controls v7.1 IG1"
                    }
                    value={"CIS Controls v7.1 IG1"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="CIS Controls v7.1"
              /> */}
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "CIS Benchmark for CIS Amazon Web Services Foundations Benchmark, v1.3.0, Level 1 and 2"
                      }
                      value={
                        "CIS Benchmark for CIS Amazon Web Services Foundations Benchmark, v1.3.0, Level 1 and 2"
                      }
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="CIS Foundations Benchmark, v1.3.0"
                />
                {/* <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"complianceStandard"}
                    checked={
                      filter?.complianceStandard ===
                      "AWS Control Tower Guardrails"
                    }
                    value={"AWS Control Tower Guardrails"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="AWS Control Tower Guardrails"
              /> */}
              </Grid>
              <Grid
                item
                md={6}
                display={"flex"}
                justifyContent="flex-start"
                flexDirection={"column"}
              >
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "NIST 800-53 (Rev. 5) Low-Moderate-High"
                      }
                      value={"NIST 800-53 (Rev. 5) Low-Moderate-High"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary", flexWrap: "nowrap" }}
                  label="NIST Special Publication 800-53 Revision 5"
                />
                {/* <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"complianceStandard"}
                    checked={
                      filter?.complianceStandard ===
                      "GxP 21 CFR Part 11"
                    }
                    value={"GxP 21 CFR Part 11"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="GxP 21 CFR"
              /> */}
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "HIPAA Security Rule 2003"
                      }
                      value={"HIPAA Security Rule 2003"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="HIPAA Security Rule 2003"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard === "NIST SP 800-171 Rev. 2"
                      }
                      value={"NIST SP 800-171 Rev. 2"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="NIST SP 800-171 Rev. 2"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "GxP EU Annex 11"}
                      value={"GxP EU Annex 11"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="GxP EU Annex 11"
                />
              </Grid>
            </Grid>
          </Paper>
          <Box
            sx={{
              p: 0,
              backgroundColor: "white",
              minWidth: "21%",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                p: 2,
                backgroundColor: "background.default",
                flexShrink: 0,
              }}
            >
              <Grid container>
                <Grid item md={12}>
                  <Typography
                    component="h4"
                    align="left"
                    color="primary.main"
                    gutterBottom
                    sx={{
                      pt: 0,
                      fontWeight: 500,
                      textWrap: "nowrap",
                    }}
                  >
                    Generate Reports
                  </Typography>
                </Grid>
                <Grid item md={12} display={"flex"} direction={"row"}>
                  <ExportSelection
                    filter={filter}
                    table={table}
                  />
                </Grid>
                <Grid item md={12} display={"flex"} direction={"row"}>
                  <EmailDetailedFindings 
                    filter={filter}
                    sx={{
                      m: 0,
                      ml: 1,
                      mb: 1,
                      width: "100%",
                      fontWeight: 600,
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper
              elevation={2}
              sx={{
                p: 2,
                mt: 2,
                backgroundColor: "background.default",
              }}
            >
              <Grid container justifyContent="flex-start">
                <Typography
                  component="h4"
                  align="left"
                  color="primary.main"
                  gutterBottom
                  sx={{ pt: 0, fontWeight: 500 }}
                >
                  Other Filters
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-start">
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"compliant"}
                      checked={filter?.compliant === "PASSED"}
                      value={"PASSED"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Compliant"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"compliant"}
                      checked={filter?.compliant === "FAILED"}
                      value={"FAILED"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Non-Compliant"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"destructive"}
                      checked={filter?.destructive === "YES"}
                      value={"YES"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Possibly Disruptive"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"destructive"}
                      checked={filter?.destructive === "NO"}
                      value={"NO"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Non-Disruptive"
                />
              </Grid>
            </Paper>
          </Box>
        </Stack>
      </Box>
      <Box m={1} ml={4} mb={3} sx={{ flexGrow: 1 }}>
        <Link
          component="button"
          variant="body2"
          color="secondary.main"
          onClick={() => {
            setFilter({
              complianceStandard: "",
              destructive: "",
              compliant: "",
            });
          }}
        >
          Clear Selection
        </Link>
      </Box>
    </Grid>
  );
};

export default Filter;
