import { createContext, useState, useEffect } from "react";
import  { CognitoUserPool } from "amazon-cognito-identity-js";

const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID || "";
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID || "";

const poolData = {
  UserPoolId: COGNITO_USER_POOL_ID, // Your user pool id here
  ClientId: COGNITO_CLIENT_ID, // Your client id here
};

interface Cognito {
  userPool: CognitoUserPool;
  idToken: any;
  accessToken: any;
  cognitoUser: any;
  logout: () => void;
}

export const CognitoContext = createContext<Cognito>({
  userPool: new CognitoUserPool(poolData),
  idToken: null,
  accessToken: null,
  cognitoUser: null,
  logout: () => {},
});

export function CognitoProvider({ children, logout }: any) {
  const userPool = new CognitoUserPool(poolData);
  const [idTokenStr, setIdTokenStr] = useState<string>("");
  const [accessTokenStr, setAccessTokenStr] = useState<string>("");
  const [user, setUser] = useState({});
  useEffect(() => {
    const userStr = localStorage.getItem("user");
    setIdTokenStr(localStorage.getItem("idToken") || "");
    setAccessTokenStr(localStorage.getItem("accessToken") || "");
    if (userStr) {
      setUser(JSON.parse(userStr));
    }
  }, []);
  // const [idToken, setIdToken] = useState<string>("");
  // const [accessToken, setAccessToken] = useState<string>("");
  // if (userStr) {
  //   setUser(JSON.parse(userStr));
  // }

  // if (idTokenStr) {
  //   setIdToken(idTokenStr);
  // }

  // if (accessTokenStr) {
  //   setAccessToken(accessTokenStr);
  // }

  const logoutCognito = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIdTokenStr("");
    setAccessTokenStr("");
    setUser({});
    userPool.getCurrentUser()?.globalSignOut({
      onSuccess: (msg: string) => {
        console.log(msg);
        window.location.reload();
      },
      onFailure: (error: Error) => {
        console.log(error);
        window.location.reload();
      },
    });
  };

  return (
    <CognitoContext.Provider
      value={{
        idToken: idTokenStr,
        accessToken: accessTokenStr,
        cognitoUser: user,
        logout: logoutCognito,
        userPool,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
}
