import ConfirmEmail from "../components/authentication/confirmemail";
import ConfirmPassword from "../components/authentication/confirmpassword";
import ForgotPasword from "../components/authentication/forgotpassword";
import Login from "../components/authentication/login";
import Signup from "../components/authentication/signup";
import MarketplaceSignup from "../components/authentication/MarketplaceSignup";
import InputPage from "../components/code-generator/InputPage";
import ListComplianceControls from "../components/compliance/ListComplianceControls";
import ListSecurityControls from "../components/controls/ListSecurityControls";
import DashboardPage from "../components/dashboard/DashboardPage";
import IntegrationsPage from "../components/integration/IntegrationPage";
import NewOrganization from "../components/new-organization/NewOrganization";
import EditUserPage from "../components/profile/EditUserPage";
import ProfilePage from "../components/profile/ProfilePage";
import ListProtectSecurityControls from "../components/protect/ListProtectSecurityControls";
import DetectPage from "../components/ransomware-detect/DetectPage";
import Utilities from "../components/utilities/Utilities";
import FTRAutomation from "../components/wat/ftr/FTRAutomation";
import Wat from "../components/wat/cloud-infra/WatCloudInfra";
import TemplateScanner from "../components/template-scanner/TemplateScanner";
import Accounts from "../components/manage-accounts/Accounts";
// import Marketplace from "../components/marketplace/Register";
import ScannerFileResults from "../components/template-scanner/ScannerFileResults";
import AutomationApprovals from "../components/remediation-approval/AutomationApprovals";
import ManageAutomation from "../components/remediation-approval/ManageAutomation";
import RemediationResult from "../components/remediation-approval/RemediationResult";
import WatMainPage from "../components/wat/WatMainPage";
import WatIac from "../components/wat/iac/WatIac";
const authProtectedRoutes = [
  { path: "/", component: DashboardPage, multiAccount: true },
  {
    path: "/compliancedashboard",
    component: DashboardPage,
    multiAccount: true,
  },
  {
    path: "/ransomwaredashboard",
    component: DashboardPage,
    multiAccount: true,
  },
  { path: "/rules", component: ListSecurityControls, multiAccount: true },
  {
    path: "/compliancereports",
    component: ListComplianceControls,
    multiAccount: true,
  },
  {
    path: "/protect",
    component: ListProtectSecurityControls,
    multiAccount: true,
  },
  { path: "/accounts", component: Accounts, multiAccount: false },
  { path: "automationgenerator", component: InputPage, multiAccount: false },
  { path: "findings", component: DetectPage, multiAccount: false },
  { path: "detect", component: DetectPage, multiAccount: true },
  { path: "/integrations", component: IntegrationsPage, multiAccount: false },
  {
    path: "/new-organization",
    component: NewOrganization,
    multiAccount: false,
  },
  { path: "/profile", component: ProfilePage, multiAccount: false },
  { path: "/profile/edit", component: EditUserPage, multiAccount: false },
  { path: "/utilities", component: Utilities, multiAccount: false },
  { path: "/wat", component: WatMainPage, multiAccount: false },
  { path: "/watinfra", component: Wat, multiAccount: true },
  { path: "/watiac", component: WatIac, multiAccount: false },
  {
    path: "/template-scanner",
    component: TemplateScanner,
    multiAccount: false,
  },
  {
    path: "/template-scanner-results",
    component: ScannerFileResults,
    multiAccount: false,
  },
  { path: "/ftrautomation", component: FTRAutomation, multiAccount: true },
  {
    path: "/automation-approval",
    component: AutomationApprovals,
    multiAccount: true,
  },
  {
    path: "/automation-approval/settings",
    component: ManageAutomation,
    multiAccount: false,
  },
  {
    path: "/automation-approval/result/",
    component: RemediationResult,
    multiAccount: false,
  },
  // { path: "/marketplace", component: Marketplace, multiAccount: false },
];

const publicRoutes = [
  { path: "/login", element: Login, type: "login" },
  { path: "/signup", element: Signup, type: "signup" },
  { path: "/register", element: MarketplaceSignup, type: "marketplace-signup" },
  { path: "/forgot-password", element: ForgotPasword, type: "forgot-password" },
  {
    path: "/confirm-password/:email",
    element: ConfirmPassword,
    type: "confirm-password",
  },
  {
    path: "/confirm-email/:email",
    element: ConfirmEmail,
    type: "confirm-email",
  },
];

export { publicRoutes, authProtectedRoutes };
