import Typography from "@mui/material/Typography";
import {
  Grid,
  Button,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function Sow() {
  return (
      <Grid container sx={{ mb: 2 }} spacing={2} alignItems="center">
        <Grid item md={10} justifyContent="flex-end">
          <Typography
            component={"span"}
            variant="h6"
            color="text.primary"
            paragraph
          >
            If you haven't been redirected to the Review and Fix Misconfigurations page, 
            click on the button to take you there
          </Typography>
        </Grid>
        <Grid item md={2} alignItems="flex-end">
          <Button
            id="wat-setup-btn"
            type="submit"
            variant="contained"
            endIcon={<OpenInNewIcon />}
            href="/rules?cs=WAT&c=FAILED"
            target={"_blank"}
            sx={{
              width: 157,
              height: 40,
              bgcolor: "secondary.main",
              "&:hover": {
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
              },
            }}
          >
            Click Here
          </Button>
        </Grid>
      </Grid>
  );
};
