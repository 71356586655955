import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import useRegions from "../../hooks/useRegions";

interface RollbackAccountProps {
  regions: Map<string, string>;
  accountRegion: any;
  setAccountRegion: any;
}

export default function RegionDropdown({
  regions,
  accountRegion,
  setAccountRegion,
}: RollbackAccountProps) {
  const handleRegion = (event: SelectChangeEvent) => {
    setAccountRegion(event.target.value as string);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="dep_arn">Region</InputLabel>
      <Select
        id="dep_region"
        value={accountRegion}
        label="Region"
        onChange={handleRegion}
        size="small"
      >
        {Array.from(regions.keys()).map((region: any) => {
          return (
            <MenuItem key={region} value={region}>
              {regions.get(region)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
