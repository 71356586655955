import { FC, useState } from "react";

import {
  Button,
  Dialog,
  LinearProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

import Control from "../../models/Control";
import { Row } from "@tanstack/react-table";
import useAccount from "../../hooks/useAccount";
import { useGetLambdaCreationResultLazyQuery } from "../../generated/index";

interface ICustomRemediationDialog {
  open: boolean;
  handleClose: any;
  handleSubmit: any;
  row: Row<Control>;
  finding: any;
}

const CustomRemediationDialog: FC<any> = ({
  open,
  handleClose,
  handleSubmit,
  row,
  finding,
}: ICustomRemediationDialog) => {
  const { arn } = useAccount();
  const [remediationAction, setRemediationAction] = useState("");
  const [getLambdaCreationResult, { loading }] =
    useGetLambdaCreationResultLazyQuery();

  const onSubmit = () => {
    if (remediationAction === "") {
      alert("Could not submit, empty instructions!");
      return;
    }
    getLambdaCreationResult({
      variables: {
        prompt: remediationAction,
        arn,
        controlID: row.original.controlname,
        customRemediation: true,
      },
      onCompleted: () => {
        handleSubmit();
      },
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5" color="primary" fontWeight="bold">
            Request Custom Remediation Action
          </Typography>
          <Typography variant="h6">
            Control ID: {row.original.controlname}
          </Typography>
          <Typography variant="h6">
            Control Description: {finding?.Title}
          </Typography>
          <Typography variant="h6">
            Finding ID: {finding?.Id?.split("/").reverse()[0]}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" fontWeight="bold">
            Describe preferred Remediation Action:
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={16}
            fullWidth
            value={remediationAction}
            onChange={(e) => setRemediationAction(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="large">
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            disabled={loading}
            onClick={onSubmit}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </>
  );
};

export default CustomRemediationDialog;
