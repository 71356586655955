import { FC, useEffect, useState } from "react";
import { Container, Typography, Paper, Grid } from "@mui/material";
import RollbackAccount from "./RollbackAccount";
import RegionDropdown from "./RegionDropdown";
import useRegions from "../../hooks/useRegions";
import { useGetUrlQuery } from "../../generated";

const AccountRollback: FC = () => {
  const regions = useRegions();

  const [accountRegion, setAccountRegion] = useState("");
  const { data, loading } = useGetUrlQuery({
    variables: {
      template: "templates/SixPillarRollbackStack.template.json", // value for 'template'
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setAccountRegion("ap-southeast-2");
  }, [regions]);

  return (
    <Container sx={{ width: 1 }} component={Paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={12}>
          <Typography variant="h4" color="primary.main" gutterBottom>
            Remove AUTOMATE/+ from your AWS Account
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body2">
            By clicking the Launch Rollback button you will launch a
            CloudFormation template which will remove AUTOMATE+ from your AWS
            Account.
          </Typography>
        </Grid>
        <Grid item md={4}>
          <RegionDropdown
            regions={regions}
            accountRegion={accountRegion}
            setAccountRegion={setAccountRegion}
          />
        </Grid>
        <Grid item md={8}></Grid>
        <Grid item md={4}>
          <RollbackAccount
            loading={loading}
            url={`https://console.aws.amazon.com/cloudformation/home?region=${accountRegion}#/stacks/create/review?stackName=six-pillars-rollback&templateURL=${encodeURIComponent(
              data?.getTemplateS3URL
            )}`}
          />
        </Grid>
        <Grid item md={8}></Grid>
        <Grid item md={12}></Grid>
      </Grid>
    </Container>
  );
};

export default AccountRollback;
