import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useGetUrlLazyQuery,
  useStartStackSetRollbackSfnMutation,
} from "../../generated";
import LoadingButton from "@mui/lab/LoadingButton";
import useSnackBars from "../../hooks/useSnackbar";
import { OpenInNew } from "@mui/icons-material";

interface OrganisationRollbackDialog {
  arn: string;
  defaultregion: string;
  externalId: string;
}

export default function OrganisationRollbackDialog({
  arn,
  defaultregion,
  externalId,
}: OrganisationRollbackDialog) {
  const [open, setOpen] = useState(false);
  const [stackUrl, setStackUrl] = useState("");
  const { setAlerts } = useSnackBars();
  const [loading, setLoading] = useState(false);
  const [startStackSetRollbackSfnMutation] =
    useStartStackSetRollbackSfnMutation();
  const handleClose = (event: any) => {
    setOpen(false);
    setLoading(false);
  };

  const [getUrl] = useGetUrlLazyQuery();

  const startRollback = async () => {
    setLoading(true);
    const adminAccountId = arn.split(":")[4];
    const rollbackRoleStackUrl = await getUrl({
      variables: {
        template: "roles/SixPillarsOrganisationRollbackRoleStack.template.json",
      },
      defaultOptions: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
    }).then((result) => {
      return result.data?.getTemplateS3URL;
    });

    const url = `https://console.aws.amazon.com/cloudformation/home?region=${defaultregion}#/stacks/create/review?stackName=six-pillars-organisation-rollback&templateURL=${encodeURIComponent(
      rollbackRoleStackUrl
    )}&param_AdministratorAccountId=${adminAccountId}&param_ExternalId=${externalId}`;

    const startRollbackSfn = await startStackSetRollbackSfnMutation({
      variables: {
        arn: arn,
      },
      context: {
        apiName: "user_deploy_process",
      },
    }).then((result) => {
      if (result.data?.startStackSetRollbackSfn?.executionArn) {
        setAlerts([
          {
            severity: "success",
            msg: "Please check your Cloudformation Stacksets to deploy to accounts your like",
          },
        ]);
        return result.data?.startStackSetRollbackSfn?.executionArn;
      } else {
        setAlerts([
          {
            severity: "error",
            msg: "Starting rollback process failed.",
          },
        ]);
        return "";
      }
    });
    setStackUrl(url);
    window.open(url, "_blank")?.focus();
    setLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: 100,
          bgcolor: "secondary.main",
          "&:hover": {
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
        onClick={() => {
          setOpen(true);
          setLoading(false);
        }}
      >
        Rollback
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ pb: 0.5, fontSize: "16px" }}>
          <b>
            This will rollback any organisation features deployed to your
            account.
          </b>
        </DialogTitle>
        <DialogContent>
          <Typography
            color={"red"}
            fontSize={"16px"}
            sx={{ pt: 5, pl: 5, pr: 5 }}
          >
            Before you click "Open", please make sure you are logged into the
            correct AWS account({arn.split(":")[4]}) and please ensure that
            “pop-ups” for the AUTOMATE+ SaaS site/URLs are set to always allow.​
          </Typography>

          {stackUrl != "" && (
            <Typography
              color={"red"}
              fontSize={"16px"}
              sx={{ pt: 5, pl: 5, pr: 5 }}
            >
              If a new tab doesn't open please{" "}
              <Link href={stackUrl} target="_blank">
                click here
              </Link>
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            disabled={loading}
            loading={loading}
            onClick={startRollback}
            loadingIndicator={
              <CircularProgress color={"secondary"} size={22} />
            }
          >
            Start Rollback
          </LoadingButton>
        </DialogActions>
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </>
  );
}
