import { FC, useEffect } from "react";
import { Box } from "@mui/material";
import { useGetUrlQuery } from "../../generated";
import LoadingButton from "@mui/lab/LoadingButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const SixPillarsRoleButton: FC<any> = ({
  update,
  open,
  template,
}: {
  update: boolean;
  open: boolean;
  template: string;
}) => {
  const { data, loading, refetch } = useGetUrlQuery({
    variables: {
      template: template, // value for 'template'
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {update && (
        <LoadingButton
          variant="contained"
          color="secondary"
          size="small"
          sx={{ m: 2 }}
          disabled={loading}
          onClick={() => {
            window.open("https://console.aws.amazon.com/cloudformation/home");
          }}
          endIcon={<OpenInNewIcon />}
          loadingPosition="end"
        >
          Open CloudFormation
        </LoadingButton>
      )}
      {open && (
        <LoadingButton
          variant="contained"
          color="secondary"
          disabled={loading}
          size="small"
          loading={loading}
          sx={{ m: 2 }}
          onClick={() => {
            navigator.clipboard.writeText(data?.getTemplateS3URL);
          }}
          autoFocus
          endIcon={<ContentCopyIcon />}
          loadingPosition="end"
        >
          Role stack URL
        </LoadingButton>
      )}
    </Box>
  );
};

export default SixPillarsRoleButton;
