import {
  Box,
  Paper,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";

import Heading from "../common/Heading";
import CircleIcon from '@mui/icons-material/Circle';
import { OpenInNew } from "@mui/icons-material";

const GenerateToken = () => {

    return (
        <Container sx={{height: "294px"}} component={Paper}>
            <Heading heading={"Steps to generate API Token"} />
            <Box sx={{ width: "100%", pb: "20px", pr: "20px" }}>
                <List>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <CircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Before using template scanner you need to create a new API user." />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <CircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Go to Manage Users page and on the top right corner of the page you will see an “Actions” dropdown." />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <CircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Click on it and select the “Generate API Token” option." />
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <CircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="You will be shown the API token, this is valid only for 3 months. Please make sure you safely secure this token." />
                    </ListItem>
                </List>
                <Link
                    sx={{ pt: 4, pb: 2, textDecoration: "none", cursor: "pointer" }}
                    href="/profile" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <Typography variant="caption" fontSize={"16px"} color="secondary">
                        Click here to navigate to the Manage User page{" "}
                    </Typography>
                    <OpenInNew fontSize="small" />
                </Link>
            </Box>
        </Container>
    )
}
export default GenerateToken;