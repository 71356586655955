import { FC, useState, useEffect } from "react";
import { Container, Typography, Paper, Stack } from "@mui/material";
import useAccount from "./../../hooks/useAccount";
import LoadingOverlay from "../common/LoadingOverlay";
import {
  useGetCognitoAccountQuery,
  useGetDashboardOverviewQuery,
} from "./../../generated";
import Heading from "../common/Heading";
import { Helmet } from "react-helmet";
import GraphList from "./DashboardGraphs";
import TotalGraph from "./TotalGraph";

const getDashboardType = () => {
  if (window.location.pathname === "/ransomwaredashboard") {
    return "ransomware";
  } else if (window.location.pathname === "/compliancedashboard") {
    return "compliance";
  } else {
    return "all";
  }
};

const DashboardPage: FC = () => {
  const { arn } = useAccount();

  const [totals, setTotals]: any = useState(null);

  const { data: getUserdata } = useGetCognitoAccountQuery({
    variables: {},
  });

  const { data: dashboardData, loading: dashboardLoading } =
    useGetDashboardOverviewQuery({
      variables: {
        arn: arn, // value for 'arn'
      },
    });

  useEffect(() => {
    if (!dashboardLoading) {
      let totalItems = dashboardData?.getDashboardOverview?.totalItems;
      let totalCompliant = dashboardData?.getDashboardOverview?.totalCompliant;
      let totalFail = dashboardData?.getDashboardOverview?.totalFail;
      const totals = {
        totalItems,
        totalCompliant,
        totalFail,
      };
      setTotals(totals);
    }
  }, [dashboardData, dashboardLoading]);

  return (
    <>
      <LoadingOverlay show={!totals} />
      <Container maxWidth="lg" sx={{ marginTop: "32px" }}>
        <Stack spacing={4}>
          {getDashboardType() === "all" ? (
            <Typography
              component="h5"
              variant="h5"
              align="left"
              color="primary"
              fontWeight="bold"
            >
              Welcome back, {getUserdata?.getCognitoAccount?.firstName}{" "}
              {getUserdata?.getCognitoAccount?.lastName}
              <Helmet>
                <title>Dashboard</title>
              </Helmet>
            </Typography>
          ) : (
            <Heading
              heading={
                getDashboardType() === "ransomware"
                  ? "Ransomware Dashboard"
                  : "Compliance Dashboard"
              }
            />
          )}
          {getDashboardType() === "all" ? (
            <Paper sx={{ width: "1216px", height: "232px", padding: "32px" }}>
              <Typography
                component="h5"
                variant="h5"
                align="left"
                color="primary"
                fontWeight="bold"
              >
                Overview
              </Typography>
              <Stack direction="row" spacing={2}>
                <TotalGraph
                  locationName={"rules"}
                  displayName={"Total no of controls"}
                  total={totals !== null ? totals.totalItems : 0}
                ></TotalGraph>
                <TotalGraph
                  locationName={"failures"}
                  displayName={"Total Failures"}
                  total={totals !== null ? totals.totalFail : 0}
                ></TotalGraph>
                <TotalGraph
                  locationName={"compliant"}
                  displayName={"Compliant Controls"}
                  total={totals !== null ? totals.totalCompliant : 0}
                ></TotalGraph>
              </Stack>
            </Paper>
          ) : null}
          <Paper sx={{ width: "1216px", padding: "32px" }}>
            <Typography
              component="h5"
              variant="h5"
              align="left"
              color="primary"
              fontWeight="bold"
            >
              Compliance Overview
            </Typography>
              <Stack direction="column" spacing={2}>
                {
                  GraphList(getDashboardType())
                }
              </Stack>
          </Paper>
        </Stack>
      </Container>
    </>
  );
};
export default DashboardPage;
