import { FC } from "react";
import { Link, Box, Typography } from "@mui/material";

const Footer: FC<any> = () => {
  const date = new Date();
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        display: "flex",
        flexDirection: "row",
        p: 1,
        pl: 3,
      }}
    >
      <Typography
        variant="caption"
        fontSize={"16px"}
        color={"white"}
        sx={{ flexGrow: 1 }}
      >
        © {date.getFullYear()} Copyright:{" "}
        <Link href="//6pillars.ai" sx={{ color: "white" }}>
          6pillars.ai
        </Link>
      </Typography>
      <Link
        href="mailto:support@6pillars.ai"
        target="_blank"
        sx={{ color: "white", mr: 4, display: { xs: "none", md: "flex" } }}
      >
        Contact Support
      </Link>
    </Box>
  );
};

export default Footer;
