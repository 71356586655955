import React, { FC, useState, useEffect } from "react";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Stack,
  Container,
  Button,
} from "@mui/material";
import ResetPassword from "./ResetPassword";
import SetupMFA from "./SetupMFA";
import Heading from "../common/Heading";
import { useGetCognitoAccountQuery } from "../../generated";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditUserPage: FC = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data } = useGetCognitoAccountQuery({
    variables: {},
  });

  return (
    <Container component="main">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Heading
          heading={
            " User: " +
            data?.getCognitoAccount?.firstName +
            " " +
            data?.getCognitoAccount?.lastName
          }
        />
      </Stack>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Reset Password" {...a11yProps(0)} />
            <Tab label="Setup MFA" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ResetPassword></ResetPassword>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SetupMFA></SetupMFA>
        </TabPanel>
      </Box>
    </Container>
  );
};
export default EditUserPage;
