import { FC } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Link,
  Alert,
  LinearProgress,
} from "@mui/material";
import * as yup from "yup";

const validationSchema = yup.object({
  otp: yup.string().required("OTP is required"),
});

const Mfa: FC<any> = ({
  email,
  session,
  cognitoUser,
  loading,
  setLoading,
  error,
  setError,
}: any) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setError({ status: false, msg: "" });
      cognitoUser.sendMFACode(values.otp, session, "SOFTWARE_TOKEN_MFA");
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant="h3"
            fontSize={"22px"}
            fontWeight={"500"}
            color={"secondary"}
            sx={{ pt: 0, pb: 4 }}
          >
            Multi Factor Authentication
          </Typography>
          <Typography
            variant="h5"
            fontSize={"16px"}
            fontWeight={"500"}
            color={"black"}
            sx={{ pt: 0, pb: 4 }}
          >
            Please enter your one time password here
          </Typography>
          <TextField
            fullWidth
            id="otp"
            name="otp"
            label="OTP"
            value={formik.values.otp}
            sx={{ maxWidth: "350px", pb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.otp && Boolean(formik.errors.otp)}
            helperText={formik.touched.otp && formik.errors.otp}
          />
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
            sx={{ mb: 2, width: "90px", backgroundColor: "secondary.main" }}
          >
            Confirm
          </Button>
          {error.status && <Alert severity="error">{error.msg}</Alert>}
          <Divider />
          <Link
            sx={{ pt: 4, pb: 2, textDecoration: "none", cursor: "pointer" }}
            onClick={() => {
              navigate("/signup");
            }}
          >
            <Typography variant="caption" fontSize={"16px"} color="secondary">
              Don't have an account? Sign up for FREE here.
            </Typography>
          </Link>
          {loading && <LinearProgress color="secondary" />}
        </Box>
      </form>
    </div>
  );
};

export default Mfa;
