import {
  Paper,
  Grid,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import ExportButton from "../common/ExportButton";

const FilterCheckbox = ({ name, checked, value, handleChange }: any) => {
  return (
    <Checkbox
      checked={checked}
      onChange={(e, checked) => {
        if (checked) {
          handleChange(name, value);
        } else {
          handleChange(name, "");
        }
      }}
    />
  );
};

const Filter = ({ filter, setFilter, table }: any) => {
  const handleChange = (name: string, value: string) => {
    const data: any = {};
    data[name] = value;
    setFilter({ ...filter, ...data });
  };

  return (
    <Grid container justifyContent="flex-start" flexDirection={"column"}>
      <Box m={4} mb={1} sx={{ flexGrow: 1 }}>
        <Stack direction="row" spacing={3}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              backgroundColor: "background.default",
              minWidth: "60%",
            }}
          >
            <Grid container justifyContent="flex-start">
              <Typography
                component="h4"
                align="left"
                color="primary.main"
                gutterBottom
                sx={{ pt: 0, fontWeight: 500 }}
              >
                Compliance Standards filters
              </Typography>
            </Grid>
            <Grid container flexDirection={"row"}>
              <Grid
                item
                md={8}
                justifyContent="flex-start"
                display={"flex"}
                flexDirection={"column"}
              >
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "AWS Foundational Security Best Practices v1.0.0"
                      }
                      value={"AWS Foundational Security Best Practices v1.0.0"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="AWS Foundational Security Best Practices v1.0.0"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "CIS AWS Foundations Benchmark v1.2.0"
                      }
                      value={"CIS AWS Foundations Benchmark v1.2.0"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="CIS AWS Foundations Benchmark v1.2.0"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "CIS AWS Foundations Benchmark v1.4.0"
                      }
                      value={"CIS AWS Foundations Benchmark v1.4.0"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="CIS AWS Foundations Benchmark v1.4.0"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "Secure Landing Zone Assessment"
                      }
                      value={"Secure Landing Zone Assessment"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Secure Landing Zone Assessment"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "Essential Eight"
                      }
                      value={"Essential Eight"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Essential Eight"
                />
              </Grid>
              <Grid
                item
                md={4}
                justifyContent="flex-start"
                flexDirection={"column"}
              >
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "ISO"}
                      value={"ISO"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="ISO 27001"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "SOC 2"}
                      value={"SOC 2"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="SOC 2"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "PCI DSS v3.2.1"}
                      value={"PCI DSS v3.2.1"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="PCI DSS v3.2.1"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={filter?.complianceStandard === "CDR"}
                      value={"CDR"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="Consumer Data Right (CDR)"
                />
                <FormControlLabel
                  control={
                    <FilterCheckbox
                      name={"complianceStandard"}
                      checked={
                        filter?.complianceStandard ===
                        "NZISM"
                      }
                      value={"NZISM"}
                      handleChange={handleChange}
                    />
                  }
                  sx={{ color: "text.primary" }}
                  label="NZISM"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              backgroundColor: "background.default",
            }}
          >
            <Grid container justifyContent="flex-start">
              <Typography
                component="h4"
                align="left"
                color="primary.main"
                gutterBottom
                sx={{ pt: 0, fontWeight: 500 }}
              >
                Other Filters
              </Typography>
            </Grid>
            <Grid container justifyContent="flex-start">
              <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"compliant"}
                    checked={filter?.compliant === "PASSED"}
                    value={"PASSED"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Compliant"
              />
              <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"compliant"}
                    checked={filter?.compliant === "FAILED"}
                    value={"FAILED"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Non-Compliant"
              />
              <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"destructive"}
                    checked={filter?.destructive === "YES"}
                    value={"YES"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Possibly Disruptive"
              />
              <FormControlLabel
                control={
                  <FilterCheckbox
                    name={"destructive"}
                    checked={filter?.destructive === "NO"}
                    value={"NO"}
                    handleChange={handleChange}
                  />
                }
                sx={{ color: "text.primary" }}
                label="Non-Disruptive"
              />
            </Grid>
          </Paper>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              backgroundColor: "background.default",
              flexShrink: 0,
            }}
          >
            <Grid
              container
              flexDirection={"column"}
              justifyContent="flex-start"
            >
              <Typography
                component="h4"
                align="left"
                color="primary.main"
                gutterBottom
                sx={{
                  pt: 0,
                  fontWeight: 500,
                }}
              >
                Generate Reports
              </Typography>
              <ExportButton
                type={"CSV"}
                table={table}
                filter={filter}
                sx={{
                  m: 0,
                  mr: 5,
                  mb: 1,
                  mt: 2,
                  flexGrow: 0,
                  alignSelf: "strech",
                  fontWeight: 600,
                }}
              ></ExportButton>
              <ExportButton
                type={"PDF"}
                table={table}
                filter={filter}
                sx={{
                  m: 0,
                  mr: 5,
                  mb: 1,
                  flexGrow: 0,
                  alignSelf: "strech",
                  fontWeight: 600,
                }}
              ></ExportButton>
            </Grid>
          </Paper>
        </Stack>
      </Box>
      <Box m={1} ml={4} mb={3} sx={{ flexGrow: 1 }}>
        <Link
          component="button"
          variant="body2"
          color="secondary.main"
          onClick={() => {
            setFilter({
              complianceStandard: "",
              destructive: "",
              compliant: "",
            });
          }}
        >
          Clear Selection
        </Link>
      </Box>
    </Grid>
  );
};

export default Filter;
