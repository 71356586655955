import { useCreateMilestoneMutation } from "../generated";

interface CreateMilestoneInput {
    arn: string;
}

const useCreateMilestone = ({
    arn
}: CreateMilestoneInput) => {

    const [createMilestoneMutation] = useCreateMilestoneMutation();

    const createMilestone = async (workloadid: string, milestonename: string) => {
        const milestonecreate = await createMilestoneMutation({
            variables: {
              arn: arn,
              workloadid: workloadid,
              milestonename: milestonename,
            },
            context: {
                apiName: "well_architected",
            },
            notifyOnNetworkStatusChange: true,
        });
        return milestonecreate;
    };
    return {createMilestone};
}

export { useCreateMilestone };