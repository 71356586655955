import StandardGraph from "./StandardGraph";
import React from "react";
import { Stack } from "@mui/material";

interface StandardGraphProps {
  standard: string;
  standardDisplayName: string;
  standardFullName: string;
  dashboardType: string;
}

// const hiddenGraphs: StandardGraphProps[] = [
//     {
//         standard: "controltower",
//         standardDisplayName: "AWS Control Tower Guardrails",
//         standardFullName: "AWS Control Tower Guardrails",
//         dashboardType: "compliance,all"
//     },
//     {
//         standard: "genai",
//         standardDisplayName: "AWS Generative AI Best Practices Framework",
//         standardFullName: "AWS Generative AI Best Practices Framework v1",
//         dashboardType: "compliance,all"
//     },
//     {
//         standard: "cccc",
//         standardDisplayName: "Canadian Centre for Cyber Security",
//         standardFullName: "Canadian Centre for Cyber Security - Medium",
//         dashboardType: "compliance,all"
//     },
//     {
//         standard: "cis71",
//         standardDisplayName: "CIS Controls v7.1",
//         standardFullName: "CIS Controls v7.1 IG1",
//         dashboardType: "compliance,all"
//     },
//     {
//         standard: "cis8",
//         standardDisplayName: "CIS Controls v8",
//         standardFullName: "CIS Controls v8 IG1",
//         dashboardType: "compliance,all"
//     },
//     {
//         standard: "glba",
//         standardDisplayName: "Gramm-Leach-Bliley Act",
//         standardFullName: "Gramm-Leach-Bliley Act (GLBA)",
//         dashboardType: "compliance,all"
//     },
//     {
//         standard: "gxp21",
//         standardDisplayName: "GxP 21 CFR Part 11",
//         standardFullName: "GxP 21 CFR Part 11",
//         dashboardType: "compliance,all"
//     },
// ]

const allGraphs: StandardGraphProps[] = [
  {
    standard: "afsbp",
    standardDisplayName: "AWS Foundational Best Practice",
    standardFullName: "AWS Foundational Security Best Practices v1.0.0",
    dashboardType: "all",
  },
  // {
  //     standard: "so",
  //     standardDisplayName: "AWS Security On-Ramp",
  //     standardFullName: "AWS Security on Ramp",
  //     dashboardType: "all"
  // },
  {
    standard: "cis120",
    standardDisplayName: "CIS AWS v1.2.0",
    standardFullName: "CIS AWS Foundations Benchmark v1.2.0",
    dashboardType: "compliance,all",
  },
  {
    standard: "cis140",
    standardDisplayName: "CIS AWS v1.4.0",
    standardFullName:
      "CIS Benchmark for CIS Amazon Web Services Foundations Benchmark, v1.4.0, Level 1 and 2",
    dashboardType: "compliance,all",
  },
  {
    standard: "soc2",
    standardDisplayName: "SOC 2",
    standardFullName: "SOC 2",
    dashboardType: "compliance,all",
  },
  {
    standard: "nistcsf",
    standardDisplayName: "NIST CSF",
    standardFullName: "NIST Cybersecurity Framework version 1.1",
    dashboardType: "compliance,all,ransomware",
  },
  {
    standard: "cdr",
    standardDisplayName: "Consumer Data Right",
    standardFullName: "CDR",
    dashboardType: "compliance,all",
  },
  {
    standard: "slza",
    standardDisplayName: "AWS Secure Landing Zone Assessment",
    standardFullName: "Secure Landing Zone Assessment",
    dashboardType: "compliance,all",
  },
  {
    standard: "nzism",
    standardDisplayName: "NZISM",
    standardFullName: "NZISM",
    dashboardType: "compliance,all",
  },
  {
    standard: "iso",
    standardDisplayName: "ISO 27001:2013",
    standardFullName: "ISO-IEC 27001:2013 Annex A",
    dashboardType: "compliance,all",
  },
  {
    standard: "iso2022",
    standardDisplayName: "ISO 27001:2022",
    standardFullName: "ISO-IEC 27001:2022 Annex A",
    dashboardType: "compliance,all",
  },
  {
    standard: "e8",
    standardDisplayName: "Essential Eight",
    standardFullName: "Essential Eight",
    dashboardType: "compliance,all",
  },
  {
    standard: "nist",
    standardDisplayName: "NIST 800-53 Rev5",
    standardFullName: "NIST 800-53 (Rev. 5) Low-Moderate-High",
    dashboardType: "compliance,all,ransomware",
  },
  {
    standard: "fedramp",
    standardDisplayName: "FedRAMP Moderate Baseline",
    standardFullName: "FedRAMP Moderate Baseline",
    dashboardType: "compliance,all",
  },
  {
    standard: "pci40",
    standardDisplayName: "PCI DSS v4.0",
    standardFullName: "PCI DSS v4.0",
    dashboardType: "compliance,all",
  },
  {
    standard: "acsc",
    standardDisplayName: "ACSC Information Security Manual",
    standardFullName: "ACSC Information Security Manual",
    dashboardType: "compliance,all",
  },
  {
    standard: "gxpeu",
    standardDisplayName: "GxP EU Annex 11",
    standardFullName: "GxP EU Annex 11",
    dashboardType: "compliance,all",
  },
  {
    standard: "watf",
    standardDisplayName: "AWS Well Architected Framework",
    standardFullName: "AWS Well Architected Framework",
    dashboardType: "compliance,all",
  },
  {
    standard: "nistsp",
    standardDisplayName: "NIST SP 800-171",
    standardFullName: "NIST SP 800-171 Rev. 2",
    dashboardType: "compliance,all",
  },
  {
    standard: "hippa",
    standardDisplayName: "HIPAA Final Omnibus Security Rule 2013",
    standardFullName: "HIPAA Final Omnibus Security Rule 2013",
    dashboardType: "compliance,all",
  },
  {
    standard: "cis130",
    standardDisplayName: "CIS Benchmark V1.3.0",
    standardFullName:
      "CIS Benchmark for CIS Amazon Web Services Foundations Benchmark, v1.3.0, Level 1 and 2",
    dashboardType: "compliance,all",
  },
];

const getDashboardGraphs = (dashboardType: string): React.ReactNode[] => {
  const filteredGraphs = allGraphs
    .filter((graph) => graph.dashboardType.split(",").includes(dashboardType))
    .map((graph) => (
      <StandardGraph
        key={graph.standard}
        standard={graph.standard}
        standardDisplayName={graph.standardDisplayName}
        standardFullName={graph.standardFullName}
      />
    ));
  let graphStacks: React.ReactNode[] = [];
  for (let i = 0; i < filteredGraphs.length; i += 4) {
    // Collect up to four graphs
    const graphsForCurrentStack = [];
    for (let j = i; j < i + 4 && j < filteredGraphs.length; j++) {
      graphsForCurrentStack.push(filteredGraphs[j]);
    }
    // Create a stack for the collected graphs
    const currentStack = (
      <Stack key={i} direction="row" spacing={2}>
        {graphsForCurrentStack}
      </Stack>
    );
    graphStacks.push(currentStack);
  }
  return graphStacks;
};

export default getDashboardGraphs;
