import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";

interface MenuItem {
  path: string;
  menuName: string;
}
const FadeMenu = ({
  menuName,
  subMenuNames,
  path,
  navigate,
}: {
  menuName: string;
  path?: string;
  subMenuNames: MenuItem[];
  navigate: any;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {subMenuNames.length > 0 ? (
        <Button
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon color="secondary"></ArrowDropDownIcon>}
          sx={{
            textTransform: "capitalize",
            fontSize: "18px",
            fontWeight: 500,
            paddingRight: 2,
          }}
        >
          {menuName}
        </Button>
      ) : (
        <Link
          to={path || ""}
          style={{ textDecoration: "none", color: "text.primary" }}
        >
          <Button
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{
              textTransform: "capitalize",
              fontSize: "18px",
              fontWeight: 500,
              paddingRight: 2,
            }}
          >
            {menuName}
          </Button>
        </Link>
      )}
      <Menu
        id={menuName}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {subMenuNames.map((menu) => {
          return (
            <Link
              key={menu.path}
              to={menu.path}
              onClick={handleClose}
              style={{ textDecoration: "none", color: "text.primary" }}
            >
              <MenuItem
                sx={{
                  color: "primary.main",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {menu.menuName}
              </MenuItem>
            </Link>
          );
        })}
      </Menu>
    </div>
  );
};

export default FadeMenu;
