import { useState } from "react";
import { FormControl, MenuItem, SelectChangeEvent } from "@mui/material";
import StyledSelect from "./StyledSelect";
import MultiAutomatedRemediationDialog from "./MultiAutomatedRemediationDialog";
import useSnackBars from "../../hooks/useSnackbar";
import useAccount from "../../hooks/useAccount";

const MultiRemediateSelect = ({
  rows,
  selectedRows,
  setRowSelection,
  resetRowSelection,
  updateData,
}: any) => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("action");
  const { setAlerts } = useSnackBars();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAction("action");
  };

  const { accounttype } = useAccount();

  const handleConfirm = (status: boolean, controls: string[], msg: string) => {
    if (status) {
      let newRemediatestatus = action === "Enable" ? "ENABLED" : "DISABLED";
      updateData(controls, "remediatestatus", newRemediatestatus);
      resetRowSelection();
      setAlerts([
        {
          severity: "success",
          msg: "Multi Continuous Compliance successfully toggled.",
        },
      ]);
    } else if (msg !== "") {
      setAlerts([
        {
          severity: "error",
          msg: msg,
        },
      ]);
    } else {
      setAlerts([
        {
          severity: "error",
          msg: `Multi Continuous Compliance toggling failed for following controls ${controls.join(
            ", "
          )}`,
        },
      ]);
    }
    setOpen(false);
    setAction("action");
  };
  
  return (
    <FormControl sx={{ m: 1, minWidth: 260 }} size="small">
      <StyledSelect
        size="small"
        labelId="action-select-label"
        id="action-select"
        value={action}
        disabled={accounttype === "READ_ONLY"}
        onChange={(e: SelectChangeEvent<unknown>) => {
          if (Object.keys(selectedRows).length > 0) {
            setAction(String(e.target.value));
            handleClickOpen();
          }
        }}
      >
        <MenuItem value={"action"} disabled>
          Action
        </MenuItem>
        <MenuItem value={"Enable"}>Enable Continuous Compliance</MenuItem>
        <MenuItem value={"Disable"}>Disable Continuous Compliance</MenuItem>
      </StyledSelect>
      <MultiAutomatedRemediationDialog
        open={open}
        action={action}
        rows={rows}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      ></MultiAutomatedRemediationDialog>
    </FormControl>
  );
};

export default MultiRemediateSelect;
