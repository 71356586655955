import {
  Container,
  Typography,
  Paper,
  Grid,
  Link,
  CardMedia,
  Button,
  List,
  ListItem,
  Modal,
  Box,
  Stack,
  CircularProgress,
} from "@mui/material";
import ftrimage from "./../../../assets/ftr_automation_new_color.png";
import DownloadSelfAssessment from "../DownloadSelfAssessment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useLazyQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { GetUrlDocument } from "../../../generated";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface FTRSelfAssessmentProps {
  arn: string;
}
export default function FTRSelfAssessment({ arn }: FTRSelfAssessmentProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [disabledIcon, setDisabledIcon] = useState<boolean[]>(
    new Array(2).fill(false)
  );
  const [activeSpinner, setActiveSpinner] = useState<boolean[]>(
    new Array(2).fill(false)
  );
  const [processDocName,setProcessDocName] = useState("");
  const handleClose = async () => {
    setOpen(false);
  };
  const handleRedirect = async () => {
    setOpen(false);
    window.location.href = "/rules?cs=CIS140";
  };

  const onDownloadClick = (url:string, filename:string) => {
    const element = document.createElement("a");
    element.href = url;
    element.download = filename;
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    const newDisabled = [...disabledIcon];
    if(filename === "AWS-Incident-Management-Process-20240410.docx"){
      newDisabled[0] = false;
    } else if (filename === "AWS-Support-Escalation-Process-20240427.docx") {
      newDisabled[1] = false;
    } else if (filename === "AWS-Policy-for-Managing-AWS-Credentials-and-Access-for-Departing-or-Role-Changing-Users-20240624.docx"){
      newDisabled[2] = false;
    } else {
      newDisabled[3] = false;
    }
    setDisabledIcon(newDisabled);
  }

  const [ getRoleTemplateURL ] = useLazyQuery(GetUrlDocument,{
    onCompleted: (RoleTemplateURLData) => {
      onDownloadClick(RoleTemplateURLData?.getTemplateS3URL, processDocName);
      const newSpinner = [...activeSpinner];
      if(processDocName === "AWS-Incident-Management-Process-20240410.docx"){
        newSpinner[0] = false;
      } else if (processDocName === "AWS-Support-Escalation-Process-20240427.docx") {
        newSpinner[1] = false;
      } else if (processDocName === "AWS-Policy-for-Managing-AWS-Credentials-and-Access-for-Departing-or-Role-Changing-Users-20240624.docx") {
        newSpinner[2] = false;
      } else {
        newSpinner[3] = false;
      }
      setActiveSpinner(newSpinner);
    }
  });

  const download = (index: number) => {
    const newSpinner = [...activeSpinner];
    newSpinner[index] = true;
    setActiveSpinner(newSpinner);
    const newDisabled = [...disabledIcon];
    newDisabled[index] = true;
    setDisabledIcon(newDisabled);

    let templateType = "";
    if (index === 0) {
      templateType = "process-documents/AWS-Incident-Management-Process-20240410.docx";
      setProcessDocName("AWS-Incident-Management-Process-20240410.docx");
    } else if (index === 1) {
      templateType = "process-documents/AWS-Support-Escalation-Process-20240427.docx";
      setProcessDocName("AWS-Support-Escalation-Process-20240427.docx");
    } else if (index === 2) {
      templateType = "process-documents/AWS-Policy-for-Managing-AWS-Credentials-and-Access-for-Departing-or-Role-Changing-Users-20240624.docx"
      setProcessDocName("AWS-Policy-for-Managing-AWS-Credentials-and-Access-for-Departing-or-Role-Changing-Users-20240624.docx")
    } else if (index === 3) {
      templateType = "process-documents/AWS-Standard-Operating-Procedure-for-Revoking-AWS-Credentials-Due-to-Misuse-20240624.docx"
      setProcessDocName("AWS-Standard-Operating-Procedure-for-Revoking-AWS-Credentials-Due-to-Misuse-20240624.docx")
    }
    getRoleTemplateURL({
      variables:{
          template: templateType, // value for 'template'
      }
    });
  };

  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ pb: 2, pt: 2 }} alignItems="center">
        <Grid item md={12} sx={{ pt: 2 }}>
          <Typography variant="h4" color="primary.main" gutterBottom>
            Foundational Technical Review Automation
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ mb: 1 }}>
          <Typography variant="body2">
            AUTOMATE+ is integrated with AWS native services to allow automated
            generation and updating of the assets required by AWS for evaluation
            of the Foundational Technical Review.
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body2">This includes generation of:</Typography>
        </Grid>
        <Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
            CIS compliance Report
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
            <Button
              sx={{
                width: 257,
                height: 40,
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              endIcon={<CloudDownloadIcon />}
              onClick={handleOpen}
              type="submit"
              variant="contained"
            >
              UPDATE & DOWNLOAD
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid container>
                  <Grid item xs md={10}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Download report
                    </Typography>
                  </Grid>
                  <Grid item xs md={2}>
                    <Button onClick={() => handleClose()}>
                      <CloseIcon />
                    </Button>
                  </Grid>
                  <Grid item xs md={12}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      You will be redirected to the Review and Fix Misconfigurations
                      page, please click CSV in the report section to generate
                      your CIS compliance report.
                    </Typography>
                  </Grid>
                  <Grid item xs md={4} sx={{ mt: 2 }}></Grid>
                  <Grid item xs md={8} sx={{ mt: 2 }}>
                    <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
                      <Button
                        onClick={() => handleClose()}
                        type="submit"
                        variant="outlined"
                        sx={{
                          width: 157,
                          height: 40,
                          "&:hover": {
                            color: "primary.main",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => handleRedirect()}
                        type="submit"
                        variant="contained"
                        sx={{
                          width: 157,
                          height: 40,
                          bgcolor: "secondary.main",
                          "&:hover": {
                            bgcolor: "secondary.main",
                            color: "secondary.contrastText",
                          },
                        }}
                      >
                        OK
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </Stack>
        </Grid>
        <Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
            FTR Self-Assessment Questionnaire Checklist
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <DownloadSelfAssessment arn={arn} />
        </Grid>
        <Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
            AWS Incident Management Plan
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
            <Button
              sx={{
                width: 257,
                height: 40,
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              endIcon={activeSpinner[0] ? <CircularProgress /> : <CloudDownloadIcon />}
              onClick={() => download(0)}
              disabled={disabledIcon[0]}
              type="submit"
              variant="contained"
            >
              DOWNLOAD
            </Button>
          </Stack>
        </Grid>
        <Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
            AWS Support Escalation Process
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
            <Button
              sx={{
                width: 257,
                height: 40,
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              endIcon={activeSpinner[1] ? <CircularProgress /> : <CloudDownloadIcon />}
              onClick={() => download(1)}
              disabled={disabledIcon[1]}
              type="submit"
              variant="contained"
            >
              DOWNLOAD
            </Button>
          </Stack>
        </Grid>
        <Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
            Managing AWS Credentials and Access for Departing or Role Changing Users
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
            <Button
              sx={{
                width: 257,
                height: 40,
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              endIcon={activeSpinner[2] ? <CircularProgress /> : <CloudDownloadIcon />}
              onClick={() => download(2)}
              disabled={disabledIcon[2]}
              type="submit"
              variant="contained"
            >
              DOWNLOAD
            </Button>
          </Stack>
        </Grid>
        <Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
            AWS Standard Operating Procedure (SOP) for Revoking AWS Credentials Due to Misuse
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
            <Button
              sx={{
                width: 257,
                height: 40,
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              endIcon={activeSpinner[3] ? <CircularProgress /> : <CloudDownloadIcon />}
              onClick={() => download(3)}
              disabled={disabledIcon[3]}
              type="submit"
              variant="contained"
            >
              DOWNLOAD
            </Button>
          </Stack>
        </Grid>
        <Grid item md={12} sx={{ pt: 2 }}>
          <Typography variant="body2">
            You will also need to generate an application architecture diagram
            that complies with AWS diagram best-practices which can be found
            <Link
              href="https://aws.amazon.com/architecture/reference-architecture-diagrams/?solutions-all.sort-by=item.additionalFields.sortDate&solutions-all.sort-order=desc&whitepapers-main.sort-by=item.additionalFields.sortDate&whitepapers-main.sort-order=desc&awsf.whitepapers-tech-category=*all&awsf.whitepapers-industries=*all"
              target="_blank"
            >
              {" "}
              here.
            </Link>
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ pt: 2 }}>
          <Typography variant="h4" color="primary.main" gutterBottom>
            How to use AUTOMATE+ to accelerate your FTR
          </Typography>
        </Grid>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <CardMedia
            component="img"
            sx={{ pt: 2, pb: 2, width: "75%" }}
            image={ftrimage}
            alt="6pillars FTR Automation"
          />
        </Grid>
        <Grid item md={2}></Grid>
        <Grid item md={12}>
          <Typography variant="h4" color="primary.main" gutterBottom>
            Instructions for requesting an FTR
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ mb: 2 }}>
          <Typography variant="body2">
            To find an offering for which review is being requested: Go to
            Partner Central. Scroll down to <b>AWS Partner Path</b>. Click on <b>Solutions Submitted for FTR</b>. 
            Click on <b>Create Solution</b> and complete all required fields for the new listing. 
            Select the solution once created. Click <b>Request Validation</b>
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ ml: 4, mb: 2 }}>
          <List dense={true}>
            <ListItem>
              <FiberManualRecordIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 2 }}>
                In the <b>Validations</b> section of the offering, upload your
              </Typography>
            </ListItem>
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 4 }}>
                <Typography variant="body2" sx={{ ml: 2 }}>
                  a. completed self-assessment under <b>FTR Checklist</b>
                </Typography>
              </ListItem>
              <ListItem sx={{ pl: 4 }}>
                <Typography variant="body2" sx={{ ml: 2 }}>
                  b. Architecture diagram
                </Typography>
              </ListItem>
              <ListItem sx={{ pl: 4 }}>
                <Typography variant="body2" sx={{ ml: 2 }}>
                  c. AWS CIS Foundations Benchmark report under <b>Security Tool Report</b>
                </Typography>
              </ListItem>
            </List>
            <ListItem>
              <FiberManualRecordIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 2 }}>
                You may upload multiple files in order to cover all accounts and 
                AWS Regions where you process customer data.
              </Typography>
            </ListItem>
            <ListItem>
              <FiberManualRecordIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 2 }}>
                AWS will review your submitted documents and provide confirmation 
                upon FTR approval or email instructions for any remediations required.
              </Typography>
            </ListItem>
            <ListItem>
              <FiberManualRecordIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 2 }}>
                Upon successfully completing an FTR, 
                you gain immediate access to AWS Partner benefits including ‘qualified software’ badge, 
                funding, marketing resources, and more.
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body2" sx={{ pt: 2 }}>
            <b>NOTE: </b>The Request Foundational Technical Review button will
            be disabled until you have uploaded files.
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ pt: 2 }}>
          <Link
            href="https://partnercentral.awspartner.com/APNLogin"
            target="_blank"
          >
            Log in to AWS Partner Central »
          </Link>
        </Grid>
        <Grid item md={12} sx={{ pt: 2 }}>
          <Typography variant="body2">
            For additional detail on how to complete your FTR, please see the
            official AWS FTR Guide
            <Link
              href="https://partnercentral.awspartner.com/ContentFolderPartner?id=0690h00000BGRJhAAP"
              target="_blank"
            >
              {" "}
              here.
            </Link>
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ pt: 2, pb: 2 }}>
          <Typography variant="body2">
            For additional support or assistance with your FTR, please contact
            6pillars’ (
            <Link href="https://www.6pillars.ai/contact" target="_blank">
              {" "}
              FTR Support{" "}
            </Link>
            ) and the team will respond within 1 business day.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
