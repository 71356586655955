import { FC } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Link,
  Alert,
  LinearProgress,
} from "@mui/material";
import * as yup from "yup";
import useSnackBars from "../../hooks/useSnackbar";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});

const ForceChangePassword: FC<any> = ({
  userAttributes,
  session,
  cognitoUser,
  loading,
  setLoading,
  error,
  setError,
}: any) => {
  const navigate = useNavigate();
  const { setAlerts } = useSnackBars();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setError({ status: false, msg: "" });
      cognitoUser.completeNewPasswordChallenge(
        values.password,
        {},
        {
          onSuccess: () => {
            setLoading(false);
            setAlerts([
              {
                severity: "success",
                msg: "Password successfully changed. Please login again.",
              },
            ]);
            navigate(0);
          },
          onFailure: (err: any) => {
            setLoading(false);
            setError({ status: true, msg: err.message || JSON.stringify(err) });
          },
        }
      );
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant="h3"
            fontSize={"22px"}
            fontWeight={"500"}
            color={"secondary"}
            sx={{ pt: 0, pb: 4 }}
          >
            Please change your password now.
          </Typography>
          {/* <TextField
            fullWidth
            id="oldPassword"
            name="oldPassword"
            label="Old Password"
            type="password"
            value={formik.values.oldPassword}
            sx={{ maxWidth: "350px", pb: 2 }}
            onChange={formik.handleChange}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          /> */}
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Create Password"
            type="password"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.password && Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Link
              onClick={() => {
                navigate(0);
              }}
              sx={{
                display: "flex",
                mt: 2,
                mb: 2,
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="caption" fontSize={"16px"} color="secondary">
                Back to Login
              </Typography>
            </Link>
          </Box>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
            sx={{ mb: 2, width: "90px", backgroundColor: "secondary.main" }}
          >
            Change
          </Button>
          {error.status && <Alert severity="error">{error.msg}</Alert>}
          <Divider />
          {loading && <LinearProgress color="secondary" />}
        </Box>
      </form>
    </div>
  );
};

export default ForceChangePassword;
