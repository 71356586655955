import React, { useState } from "react";
import AutoCompleteData from "./AutoComplete";
import { styled } from "@mui/material/styles";
import {
  Button,
  Grid,
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  CircularProgress,
  Container,
} from "@mui/material";

import useAutomationSubscription from "../../hooks/useGetAutomationResponse";
import useAccount from "./../../hooks/useAccount";
import Heading from "../common/Heading";

const ClearButton = styled(Button)({
  border: "1px solid #0E1956",
  backgroundColor: "#FFFFFF",
  borderRadius: "4px",
  fontWeight: 600,
  textTransform: "none",
  fontSize: 16,
  lineHeight: "24px",
  marginRight: "20px",
  width: "139px",
  height: "56px",
  color: "#0E1956",
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
});

const CopyButton = styled(Button)({
  width: "139px",
  height: "56px",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "",
  borderRadius: "4px",
  textTransform: "none",
});

interface ScrollableTextFieldProps {
  width: number;
  height: number;
  [key: string]: any;
}

const ScrollableTextField = ({ width, height, ...props }: ScrollableTextFieldProps) => {
  return (
      <TextField
        multiline
        rows={7}
        style={{
          paddingRight: "1em",
          width,
          height,
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
        inputProps={{
          /* hide scrollbar for Webkit browsers */
          className: 'hide-scrollbar',
        }}
        {...props} 
      />
  );
};


export default function InputPage() {
  const [sampleInstructionText, setSampleInstructionText] = useState("");
  const [instructionText, setInstructionText] = useState("");
  const [lambdaCode, setLambdaCode] = useState("");
  const [loading, setLoading] = useState(false);
  const { arn } = useAccount();
  const { getLambdaCreationResult } = useAutomationSubscription({
    arn: arn,
    prompt: instructionText,
    setLambdaCode,
    setLoading,
  });

  const handleOnSampleChange = (event: any, newValue: any) => {
    setSampleInstructionText(newValue);
    setInstructionText(newValue);
  };

  const handleOnActualChange = (event: any) => {
    setInstructionText(event.target.value);
  };

  const submitOnClick = () => {
    if (instructionText === "") {
      alert("Could not submit, empty instructions!");
      return;
    }
    setLoading(true);
    getLambdaCreationResult({
      variables: {
        arn: arn,
        prompt: instructionText,
      }
    })
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: "32px" }}>
        <Heading heading={"AI Automation Generator"} />
        <Box sx={{ width: "100%" }}>
          <Stack spacing={4}>
            <Paper sx={{ width: "1216px", height: "232px" }}>
              <Grid
                container
                direction="column"
                justifyContent="left"
                alignItems="left"
                paddingLeft={8}
                spacing={2}
              >
                <Grid item xs={4}>
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                    align="left"
                    color="text.primary"
                    gutterBottom
                  >
                    Generate Code
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    align="left"
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    color="text.primary"
                    gutterBottom
                  >
                    Create task you want to generate code for. Tips: Use simple
                    natural language so its easily identified.
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="standard-edit"
                    label="Task Description"
                    helperText="E.g. Download a file from AWS Security"
                    variant="outlined"
                    value={instructionText}
                    sx={{ width: "592px", height: "76px" }}
                    multiline={true}
                    onChange={handleOnActualChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault() 
                        submitOnClick()
                      }
                    }}
                  />
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      onClick={submitOnClick}
                      sx={{
                        width: "139px",
                        height: "56px",
                        marginLeft: "32px",
                      }}
                    >
                      Generate
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
            <Paper sx={{ width: "1216px", height: "526px" }}>
              <Grid
                container
                direction="column"
                justifyContent="left"
                alignItems="left"
                paddingLeft={8}
                spacing={2}
              >
                <Grid item xs={4}>
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{ fontWeight: "bold", marginTop: "10px" }}
                    align="left"
                    color="text.primary"
                    gutterBottom
                  >
                    Code
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    align="left"
                    sx={{ fontWeight: "bold" }}
                    color="text.primary"
                    gutterBottom
                  >
                    Example Descriptions
                  </Typography>
                  <Autocomplete
                    id="combo-box"
                    options={AutoCompleteData}
                    sx={{ width: "600px" }}
                    onChange={handleOnSampleChange}
                    renderInput={(params) => (
                      <TextField
                        multiline={true}
                        {...params}
                        label="Select one"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    align="left"
                    sx={{ fontWeight: "bold" }}
                    color="text.primary"
                  >
                    Code Generated
                  </Typography>
                  <br />
                  <ScrollableTextField
                    rows={7}
                    value={lambdaCode}
                    width={1122}
                    height={226}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ClearButton
                    disableRipple
                    variant="contained"
                    size="large"
                    onClick={() => {
                      setLambdaCode("");
                    }}
                  >
                    Clear
                  </ClearButton>
                  <CopyButton
                    variant="contained"
                    size="large"
                    onClick={() => {
                      navigator.clipboard.writeText(lambdaCode);
                    }}
                  >
                    Copy
                  </CopyButton>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
