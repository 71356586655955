import { FC, useEffect, useState } from "react";
import { Typography, Paper, Grid, Stack } from "@mui/material";
import { changeLocation } from "./PageChanges";
import CircleSVG from "./CircleSVG";
import Chart from "./Chart";
import useAccount from "../../hooks/useAccount";
import { useGetDashboardControlsQuery } from "../../generated";

interface StandardGraphProps {
  standard: string;
  standardDisplayName: string;
  standardFullName: string;
}

// Generate Compliance Chart Data
function createData(date: number, amount?: number) {
  return { date, amount };
}

const StandardGraph: FC<StandardGraphProps> = ({
  standard,
  standardDisplayName,
  standardFullName,
}: StandardGraphProps) => {

  const [complianceResults, setComplianceResults]: any = useState(null);

  const { arn } = useAccount();

  const { data, loading } = useGetDashboardControlsQuery({
    variables: {
       arn: arn,// value for 'arn'
       standard: standardFullName// value for 'standard'
    },
  });
  
  const results = []

  useEffect(() => {
    if(!loading){
      let percentage = 0;
      let passed = 0;
      let failed = 0;
      let day = 0
      data?.getDashboardControls?.dashboardResults?.map((item: any) => {
        if(item.DAY > day){
          day = item.DAY;
          percentage = item.PERCENT;
          passed = item.PASSED;
          failed = item.FAILED    
        }
        return (<></>);
      });
      const complianceResults = {
        day,
        percentage,
        passed,
        failed
      };
      setComplianceResults(complianceResults);
    }    
  }, [data, loading])
  
  if(!loading){
    results.push(createData(0, 0));
    data?.getDashboardControls?.dashboardResults?.map((item: any) => {
      return results.push(createData(item.DAY, item.PERCENT));
    });
  }
  
  return (
    <Stack spacing={1}>
      <Paper
        onClick={() => changeLocation(standard)}
        sx={{
          cursor: "pointer",
          width: "276px",
          height: "95px",
          background: "#F7F7F7",
        }}
      >
        <Grid
          container
          alignItems="center"
          display={"flex"}
          direction={"row"}
          justifyContent="space-between"
          padding="21px"
        >
          <Typography
            variant="body1"
            align="left"
            fontWeight="bold"
            width={standard === "afsbp" || "slza" ? "150px" : "95"}
          >
            {standardDisplayName}
          </Typography>
          <CircleSVG
            sx={{
              width: "47px",
              height: "47px",
            }}
            viewBox="0 0 47 47"
            text={
              complianceResults !== null
                ? `${complianceResults.percentage}%`
                : ""
            }
            percent={complianceResults !== null ? complianceResults.percentage : 0}
            xmlns="http://www.w3.org/2000/svg"
          />
        </Grid>
      </Paper>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: "200px",
          background: "#F7F7F7",
        }}
      >
        <Chart results={results}/>
      </Paper>
      <Paper
        sx={{
          width: "276px",
          height: "48px",
          background: "#F7F7F7",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          padding="20px"
        >
          <Grid item>
            <Typography variant="body1" align="left" fontWeight="bold">
              {complianceResults !== null ? complianceResults.passed : ""} Passed
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              align="left"
              fontWeight="bold"
              color="text.secondary"
            >
              {complianceResults !== null ? complianceResults.failed : ""} Failed
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};
export default StandardGraph;
