import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  SvgIcon,
  TableBody,
  TableCell,
  CircularProgress,
  Checkbox,
  Pagination,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import StyledTableCell from "../../common/StyledTableCell";
import { useEffect, useState } from "react";
import useGetScannnerResults from "../../../hooks/useGetScannnerResults";
import React from "react";
import { LoadingButton } from "@mui/lab";
import StyledSelect from "../../common/StyledSelect";
import { RefreshOutlined, SearchOutlined } from "@mui/icons-material";

interface DisplayTemplateScannerProps {
  tscanSummaryIds: number[];
  setTscanSummaryIds: React.Dispatch<React.SetStateAction<number[]>>;
  disabled: boolean;
  setDisabled: (index: boolean) => void;
  activeSpinner: boolean;
  setActiveSpinner: (index: boolean) => void;
  updateIacAnswer: () => void;
}

interface ScanResult {
  filename?: string;
  alias?: string;
  recordCount?: Number;
  passed?: Number;
  failed?: Number;
  created_at?: string;
}

const NoSortSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const AscSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const descSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const ControlledCheckbox: React.FC<{
  summaryId: string;
  checked: boolean;
  onCheckboxChange: any;
}> = ({ summaryId, checked, onCheckboxChange }) => {
  return (
    <Checkbox
      checked={checked}
      onChange={() => onCheckboxChange(summaryId, !checked)}
      inputProps={{ "aria-label": "Delete Template Checkbox" }}
    />
  );
};

const timezone = (date: any) => {
  const utcDate = date.includes("Z") ? date : date.replace(" ", "T") + "Z";
  const localtime = new Date(utcDate).toLocaleString();
  return localtime;
};

let hasFiltered: boolean = false;

const DisplayTemplateScanner = ({
  tscanSummaryIds,
  setTscanSummaryIds,
  disabled,
  setDisabled,
  activeSpinner,
  setActiveSpinner,
  updateIacAnswer,
}: DisplayTemplateScannerProps) => {
  const { summaryItems, loading, refetch } = useGetScannnerResults();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sortField, setSortField] = useState<keyof ScanResult | null>(
    "created_at"
  );
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [uncheckAll, setUncheckAll] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [checkboxStates, setCheckboxStates]: any = useState({});

  useEffect(() => {
    if (!hasFiltered && summaryItems.length > 0) {
      setFilteredData(summaryItems);
    }
  }, [summaryItems]);

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set filtered flag if not already set
    if (!hasFiltered) hasFiltered = true;

    const { value: searchValue } = event.target;
    const search = searchValue.toLowerCase();

    const found = summaryItems.filter((item: ScanResult) => {
      const filename = item.filename?.toLowerCase() ?? "";
      const alias = item.alias?.toLowerCase() ?? "";
      const createdAt = item.created_at?.toLowerCase() ?? "";

      return (
        filename.includes(search) ||
        alias.includes(search) ||
        createdAt.includes(search)
      );
    });
    setFilteredData(found);
  };

  const getSortedData = () => {
    if (!sortField) return filteredData;

    const sorted = [...filteredData].sort((a: any, b: any) => {
      const aValue = a[sortField] || "";
      const bValue = b[sortField] || "";

      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    return sorted;
  };

  const sortIcon = (sort: boolean) => {
    if (sort && sortOrder === "asc") {
      return AscSvg;
    }
    if (sort && sortOrder === "desc") {
      return descSvg;
    }
    return NoSortSvg;
  };

  const addLineBreaks = (string: string) => {
    if (typeof string === "string" && string) {
      const result = [];
      while (string.length) {
        result.push(string.substring(0, 25));
        string = string.substring(25);
      }
      return result;
    }
    return null;
  };

  const addTemplateToUpload = (summaryId: string) => {
    if (!tscanSummaryIds.includes(Number(summaryId))) {
      setTscanSummaryIds((prev) => [...prev, Number(summaryId)]);
    }
  };

  useEffect(() => {
    if (uncheckAll) {
      // Reset all checkboxes
      const newStates: any = summaryItems.reduce(
        (acc: any, item: any) => ({
          ...acc,
          [item.summaryId]: false,
        }),
        {}
      );
      setCheckboxStates(newStates);
      // Reset the uncheckAll flag
      setUncheckAll(false);
    }
  }, [uncheckAll]);

  const refresh = () => {
    refetch();
    setUncheckAll(true);
    setFilteredData([]);
    setTscanSummaryIds([]);
    hasFiltered = false;
    setPage(0);
  };

  const handleTableSort = (fieldName: keyof ScanResult | null) => {
    if (sortField === fieldName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };

  const handleCheckboxChange = (summaryId: string, checked: boolean) => {
    // If checked, add the summaryId to selectedSummaryIds and tscanSummaryIds
    if (checked) {
      addTemplateToUpload(summaryId);
    } else {
      // If unchecked, remove the summaryId from tscanSummaryIds
      const filteredTscanSummaryIds = tscanSummaryIds.filter(
        (id) => id !== Number(summaryId)
      );
      setTscanSummaryIds(filteredTscanSummaryIds);
    }
    // Update checkbox states
    setCheckboxStates({ ...checkboxStates, [summaryId]: checked });
  };

  const handleFileUpload = async () => {
    if (tscanSummaryIds.length === 0) {
      setActiveSpinner(false);
      return;
    }

    setActiveSpinner(true);
    setDisabled(true);
    updateIacAnswer();
  };

  return (
    <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
      <Grid container flexDirection="row">
        <Paper
          component="form"
          elevation={0}
          sx={{
            p: "2px 4px",
            mb: 2,
            display: "flex",
            border: 0,
            boxShadow: 0,
            flexGrow: 1,
          }}
        >
          <TextField
            id="SearchTextField"
            label="Search"
            sx={{
              width: "305px",
              height: "40px",
              marginLeft: "32px",
              alignSelf: "center",
            }}
            onInput={requestSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchOutlined color="secondary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Paper>
        <Paper
          component="form"
          elevation={0}
          sx={{
            p: "2px 4px",
            mb: 2,
            mt: "32px",
            display: "flex",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}
        >
          <InputLabel
            id="page-size"
            sx={{
              alignSelf: "center",
              p: 1,
              color: "#000000",
              fw: "400",
            }}
          >
            Show
          </InputLabel>
          <FormControl
            sx={{ m: 1, marginRight: "32px", minWidth: 119 }}
            size="small"
          >
            <StyledSelect
              labelId="page-size-select-label"
              id="page-size-select"
              defaultValue={20}
              onChange={(event: any) => {
                setRowsPerPage(event.target.value);
              }}
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </StyledSelect>
          </FormControl>
          <LoadingButton
            id="refresh-button"
            variant="outlined"
            sx={{ alignSelf: "center", p: 1 }}
            disabled={loading}
            loading={loading}
            onClick={refresh}
            endIcon={<RefreshOutlined />}
          >
            Refresh
          </LoadingButton>
        </Paper>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <LoadingButton
          id="wat-setup-btn"
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleFileUpload}
          disabled={disabled}
          loading={activeSpinner}
          sx={{
            width: 157,
            height: 40,
            bgcolor: "secondary.main",
            "&:hover": {
              bgcolor: "secondary.main",
              color: "secondary.contrastText",
            },
          }}
          loadingIndicator={<CircularProgress color={"secondary"} size={22} />}
        >
          START SCANNING
        </LoadingButton>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Select</StyledTableCell>
              <StyledTableCell onClick={() => handleTableSort("filename")}>
                File Name {sortIcon(sortField === "filename")}
              </StyledTableCell>
              <StyledTableCell onClick={() => handleTableSort("alias")}>
                Alias Name {sortIcon(sortField === "alias")}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => handleTableSort("passed")}
                sx={{ minWidth: "100px", p: 0 }}
              >
                Passed {sortIcon(sortField === "passed")}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => handleTableSort("failed")}
                sx={{ minWidth: "100px", p: 0 }}
              >
                Failed {sortIcon(sortField === "failed")}
              </StyledTableCell>
              <StyledTableCell
                onClick={() => handleTableSort("created_at")}
                sx={{ minWidth: "100px", p: 0 }}
              >
                Timestamp {sortIcon(sortField === "created_at")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && summaryItems.length === 0 && (
              <TableRow>
                <StyledTableCell colSpan={7} align={"center"}>
                  <CircularProgress color="secondary" />
                </StyledTableCell>
              </TableRow>
            )}
            {!loading && summaryItems.length === 0 && (
              <TableRow>
                <StyledTableCell colSpan={7} align={"center"}>
                  No Results
                </StyledTableCell>
              </TableRow>
            )}
            {summaryItems.length > 0 &&
              getSortedData()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((summaryItem: any, index: number) => {
                  let processedFilename = "";
                  if (summaryItem.filename) {
                    if (summaryItem.filename.length) {
                      const lineBreaks = addLineBreaks(summaryItem.filename);
                      processedFilename = lineBreaks
                        ? lineBreaks.map((line, index) => (
                            <React.Fragment
                              key={`${summaryItem.filename}-${index}`}
                            >
                              {line}
                              <br />
                            </React.Fragment>
                          ))
                        : summaryItem.filename;
                    }
                  }
                  return (
                    <TableRow key={`${summaryItem.summaryId}-${index}`}>
                      <StyledTableCell>
                        <ControlledCheckbox
                          summaryId={summaryItem.summaryId}
                          checked={
                            checkboxStates[summaryItem.summaryId] || false
                          }
                          onCheckboxChange={handleCheckboxChange}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{processedFilename}</StyledTableCell>
                      <StyledTableCell>{summaryItem.alias}</StyledTableCell>
                      <StyledTableCell>{summaryItem.passed}</StyledTableCell>
                      <StyledTableCell>{summaryItem.failed}</StyledTableCell>
                      <StyledTableCell>
                        {timezone(summaryItem.created_at)}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        variant="outlined"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: "10px",
          color: "primary.main",
          borderColor: "primary.main",
        }}
        shape="rounded"
        count={Math.ceil(getSortedData().length / rowsPerPage)}
        page={page + 1}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
          setPage(value - 1);
        }}
      />
    </Stack>
  );
};

export default DisplayTemplateScanner;
