enum STANDARD {
  WAT = "AWS Well Architected Framework",
  FTR = "Foundational Technical Review",
  S0 = "AWS Security on Ramp",
  NIST = "NIST 800-53 (Rev. 5) Low-Moderate-High",
  NISTCSF = "NIST Cybersecurity Framework version 1.1",
  AFSBP = "AWS Foundational Security Best Practices v1.0.0",
  CDR = "CDR",
  CIS120 = "CIS AWS Foundations Benchmark v1.2.0",
  CIS140 = "CIS Benchmark for CIS Amazon Web Services Foundations Benchmark, v1.4.0, Level 1 and 2",
  SOC2 = "SOC 2",
  PCIDSS = "PCI DSS V3.2.1",
  ISO = "ISO",
  ISO2022 = "ISO-IEC 27001:2022 Annex A",
  SLZA = "Secure Landing Zone Assessment",
  E8 = "Essential Eight",
  NZISM = "NZISM",
  GENAI = "AWS Generative AI Best Practices Framework v1",
  CCCC = "Canadian Centre for Cyber Security - Medium",
  FEDRAMP = "FedRAMP Moderate Baseline",
  GLBA = "Gramm-Leach-Bliley Act (GLBA)",
  GXP = "GxP 21 CFR Part 11",
  HIPAA2013 = "HIPAA Final Omnibus Security Rule 2013",
  HIPPA2003 = "HIPAA Security Rule 2003",
  PCIDSS40 = "PCI DSS v4.0",
  ACSC = "ACSC Information Security Manual",
  CIS8 = "CIS Controls v8 IG1",
  CIS71 = "CIS Controls v7.1 IG1",
  NISTSP = "NIST SP 800-171 Rev. 2",
  GXPEU = "GxP EU Annex 11",
  CIS130 = "CIS Benchmark for CIS Amazon Web Services Foundations Benchmark, v1.3.0, Level 1 and 2",
  CT = "AWS Control Tower Guardrails",
  NONE = "",
}

export default STANDARD;
