import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { createAuthLink } from "aws-appsync-auth-link";
import { setContext } from "@apollo/client/link/context";
import {
  CognitoRefreshToken,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { MultiAPILink } from "@habx/apollo-multi-endpoint-link";

const url = process.env.REACT_APP_GRAPHQL_API_URL;
const templateScannerUrl =
  process.env.REACT_APP_TEMPLATE_SCANNER_GRAPHQL_API_URL || "";
const userDeployProcessUrl =
  process.env.REACT_APP_USER_DEPLOY_PROCESS_GRAPHQL_API_URL || "";
const wellArchitectedUrl =
  process.env.REACT_APP_WELL_ARCHITECTED_GRAPHQL_API_URL || "";
const automationApprovalUrl = 
  process.env.REACT_APP_AUTOMATION_APPROVAL_GRAPHQL_API_URL || "";
const region = process.env.REACT_APP_REGION || "";

const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID || "";
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID || "";

const poolData = {
  UserPoolId: COGNITO_USER_POOL_ID, // Your user pool id here
  ClientId: COGNITO_CLIENT_ID, // Your client id here
};

const auth: any = {
  type: "AMAZON_COGNITO_USER_POOLS",
  // apiKey: appSyncConfig.aws_appsync_apiKey,
  jwtToken: async () => {
    const userStr = localStorage.getItem("user");
    const userPool = new CognitoUserPool(poolData);
    let token: string | null = "";
    if (userStr !== null) {
      const user = JSON.parse(userStr);
      if (user.exp < Date.now() / 1000) {
        const refreshTokeStr = localStorage.getItem("refreshToken") || "";
        try {
          token = await new Promise((resolve, reject) => {
            try {
              userPool
                .getCurrentUser()
                ?.refreshSession(
                  new CognitoRefreshToken({ RefreshToken: refreshTokeStr }),
                  (err, session) => {
                    if (err) {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("idToken");
                      localStorage.removeItem("refreshToken");
                      localStorage.removeItem("user");
                      reject(false);
                    }
                    const accessToken = session.getAccessToken().getJwtToken();
                    localStorage.setItem("accessToken", accessToken);
                    const idToken = session.getIdToken();
                    localStorage.setItem("idToken", idToken.getJwtToken());
                    const refreshToken = session.getRefreshToken();
                    localStorage.setItem(
                      "refreshToken",
                      refreshToken.getToken()
                    );
                    const user = idToken.decodePayload();
                    localStorage.setItem("user", JSON.stringify(user));
                    resolve(idToken.getJwtToken());
                  }
                );
            } catch (error) {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("idToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("user");
              reject(false);
            }
          });
          if (!token) {
            window.location.href = "/login";
          }
        } catch (error) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("user");
          window.location.href = "/login";
        }
        return token;
      } else {
        token = localStorage.getItem("idToken");
        return token;
      }
    } else {
      token = localStorage.getItem("idToken");
      return token;
    }
  }, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  // credentials: async () => credentials, // Required when you use IAM-based auth.
};

const wakeUpCall = async (headers: any) => {
  //const headers = new Headers();
  //headers.append("Authorization", "");
  const graphql = JSON.stringify({
    query: "query {\n  wakeUpDatabase{\n      status\n  }\n}",
    variables: {},
  });
  const requestOptions: any = {
    method: "POST",
    headers: headers,
    body: graphql,
    redirect: "follow",
  };
  await fetch(url, requestOptions);
};

const wakeUpDatabaseLink = setContext(async (operation, { headers }) => {
  let lastApiCall = localStorage.getItem("lastApiCall");
  // console.log("1", lastApiCall);
  if (lastApiCall == null) {
    // console.log("2", lastApiCall);
    await wakeUpCall(headers);
    localStorage.setItem("lastApiCall", Date.now().toString());
  } else {
    let lastApiCallTime = parseInt(lastApiCall);
    // console.log("3", Date.now() - lastApiCallTime, 4 * 60 * 1000);
    if (Date.now() - lastApiCallTime > 4 * 60 * 1000) {
      // console.log("4");
      await wakeUpCall(headers);
      localStorage.setItem("lastApiCall", Date.now().toString());
    }
  }
});

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  wakeUpDatabaseLink,
  new MultiAPILink({
    endpoints: {
      api: url,
      template_scanner: templateScannerUrl,
      user_deploy_process: userDeployProcessUrl,
      well_architected: wellArchitectedUrl,
      automation_approval: automationApprovalUrl,
    },
    createHttpLink: () => new HttpLink(),
    createWsLink: (url) =>
      createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
    httpSuffix: "",
    wsSuffix: "",
    defaultEndpoint: "api",
  }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
