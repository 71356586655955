import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import useContolStatuesCSV from "../../hooks/useContolStatuesCSV";

interface ControlStatusDownloadBtnProps {
  arn: string;
}

export default function ControlStatusesDownloadBtn({
  arn,
}: ControlStatusDownloadBtnProps) {
  const { loading, initiateDownload } = useContolStatuesCSV({ arn });
  return (
    <LoadingButton
      onClick={initiateDownload}
      disabled={loading}
      id="automate-setup-btn"
      type="submit"
      variant="contained"
      loading={loading}
      loadingIndicator={<CircularProgress color={"secondary"} size={22} />}
      sx={{
        width: 250,
        height: 40,
        bgcolor: "secondary.main",
        "&:hover": {
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
        },
      }}
    >
      Download Data
    </LoadingButton>
  );
}
