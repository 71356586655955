import { FC, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Link,
  Alert,
  LinearProgress,
} from "@mui/material";
import * as yup from "yup";
import {
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import useSnackBars from "../../hooks/useSnackbar";
import useCognito from "../../hooks/useCognito";
import useAccount from "../../hooks/useAccount";
import Mfa from "./mfa";
import ForceChangePassword from "./forcechangepassword";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const Login: FC = () => {
  const navigate = useNavigate();
  const { userPool } = useCognito();
  const { refreshAccounts } = useAccount();
  const { setAlerts } = useSnackBars();
  const [mfaStep, setMfaStep] = useState<{
    mfa: boolean;
    email: string;
    session: any;
    cognitoUser: any;
  }>({
    mfa: false,
    email: "",
    session: null,
    cognitoUser: null,
  });
  const [forceChangePasswordStep, setForceChangePasswordStep] = useState<{
    forceChangePassword: boolean;
    userAttributes: any;
    session: any;
    cognitoUser: any;
  }>({
    forceChangePassword: false,
    userAttributes: {},
    session: null,
    cognitoUser: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, msg: "" });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setError({ status: false, msg: "" });
      const authenticationData = {
        Username: values.email,
        Password: values.password,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      const userData = {
        Username: values.email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result, userConfirmationNecessary) {
          setLoading(false);
          const accessToken = result.getAccessToken().getJwtToken();
          localStorage.setItem("accessToken", accessToken);
          const idToken = result.getIdToken();
          localStorage.setItem("idToken", idToken.getJwtToken());
          const refreshToken = result.getRefreshToken();
          localStorage.setItem("refreshToken", refreshToken.getToken());
          const user = idToken.decodePayload();
          localStorage.setItem("user", JSON.stringify(user));
          refreshAccounts(result);
          navigate("/");
        },
        mfaRequired: function (challengeName, challengeParameters) {
          // MFA is required to complete user authentication.
          // Get the code from user and call
          setLoading(false);
          console.log(challengeName);
          setMfaStep({
            mfa: true,
            email: values.email,
            session: cognitoUser.getSignInUserSession(),
            cognitoUser,
          });
        },
        newPasswordRequired: function (
          userAttributes: any,
          requiredAttributes
        ) {
          setLoading(false);
          setForceChangePasswordStep({
            forceChangePassword: true,
            userAttributes: userAttributes,
            session: cognitoUser.getSignInUserSession(),
            cognitoUser,
          });
        },
        totpRequired: function (secretCode) {
          setLoading(false);
          setAlerts([
            {
              severity: "success",
              msg: "Redirecting user to enter OTP details.",
            },
          ]);
          setMfaStep({
            mfa: true,
            email: values.email,
            session: this,
            cognitoUser,
          });
        },
        onFailure: function (err) {
          console.log(err);
          setError({ status: true, msg: err?.message || JSON.stringify(err) });
          setLoading(false);
        },
      });
    },
  });

  return (
    <div>
      {!mfaStep.mfa && !forceChangePasswordStep.forceChangePassword && (
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              sx={{ maxWidth: "350px", pb: 2 }}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              sx={{ maxWidth: "350px" }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Link
                onClick={() => {
                  navigate("/forgot-password");
                }}
                sx={{
                  mt: 2,
                  mb: 2,
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="caption"
                  fontSize={"16px"}
                  color="secondary"
                >
                  Forgot password ?
                </Typography>
              </Link>
            </Box>
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              type="submit"
              sx={{ mb: 2, width: "90px", backgroundColor: "secondary.main" }}
            >
              Login
            </Button>
            {error.status && <Alert severity="error">{error.msg}</Alert>}
            <Divider />
            <Link
              sx={{ pt: 4, pb: 2, textDecoration: "none", cursor: "pointer" }}
              onClick={() => {
                navigate("/signup");
              }}
            >
              <Typography variant="caption" fontSize={"16px"} color="secondary">
                Don't have an account? Sign up for FREE here.
              </Typography>
            </Link>
            {loading && <LinearProgress color="secondary" />}
          </Box>
        </form>
      )}
      {mfaStep.mfa && (
        <Mfa
          email={mfaStep.email}
          session={mfaStep.session}
          cognitoUser={mfaStep.cognitoUser}
          loading={loading}
          setLoading={setLoading}
          error={error}
          setError={setError}
        ></Mfa>
      )}
      {forceChangePasswordStep.forceChangePassword && (
        <ForceChangePassword
          userAttributes={forceChangePasswordStep.userAttributes}
          session={forceChangePasswordStep.session}
          cognitoUser={forceChangePasswordStep.cognitoUser}
          loading={loading}
          setLoading={setLoading}
          error={error}
          setError={setError}
        ></ForceChangePassword>
      )}
    </div>
  );
};

export default Login;
