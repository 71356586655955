import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Paper,
  InputAdornment,
  InputLabel,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Button,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  SvgIcon,
  Pagination,
  Box,
} from "@mui/material";
import Heading from "../common/Heading";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

import StyledTableCell from "../common/StyledTableCell";
import StyledSelect from "../common/StyledSelect";
import {
  useListRemediationRequestsQuery,
  ListRemediationRequestsQuery,
} from "../../generated/index";
import Loader from "../common/Loader";
import { useNavigate } from "react-router-dom";
import useAccount from "./../../hooks/useAccount";

let hasFiltered: boolean = false;

const RefreshButton: any = styled(Button)({
  textTransform: "none",
  width: "115px",
  height: "40px",
  color: "primary",
  border: "1px solid #6F6F6F",
  fontWeight: "bold",
});

const StyledButton = styled(Button)({
  textTransform: "none",
  width: "100px",
  height: "40px",
});

const AscSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const descIcon = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

interface RemediationRequest {
  id: string;
  organisation_id: string;
  account_id: string;
  aws_account_id: string;
  control_id: string;
  control_ref: string;
  control_finding_id: string;
  resource_ref: string;
  customer_control_state_id: string;
  status: string;
  scheduled: string;
  modified_at: string;
  created_at: string;
}

const AutomationApprovals = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [jsonData, setJsonData]: any[] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [apiPage, setApiPage] = useState(0);
  const [stopFetch, setStopFetch] = useState(false);
  const navigate = useNavigate();
  const { arn } = useAccount();
  let currentItems = hasFiltered ? filteredData : jsonData;
  const apiLimit = 20;

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set filtered flag if not already set
    if (!hasFiltered) hasFiltered = true;

    const { value: searchValue } = event.target;
    const search = searchValue.toLowerCase();

    const found = jsonData.filter((item: RemediationRequest) => {
      const status = item.status?.toLowerCase() ?? "";
      const scheduled = item.scheduled?.toLowerCase() ?? "";
      const createdAt = item.created_at?.toLowerCase() ?? "";
      const modifiedAt = item.modified_at?.toLowerCase() ?? "";
      return (
        status.includes(search) ||
        scheduled.includes(search) ||
        createdAt.includes(search) ||
        modifiedAt.includes(search)
      );
    });
    setFilteredData(found);
  };

  const AutomationApprovalCalback = (
    approvalData: ListRemediationRequestsQuery
  ) => {
    const resultsJsonString = approvalData?.listRemediationRequests?.results;
    if (stopFetch) {
      return;
    }
    if (resultsJsonString === "[]" || !resultsJsonString) {
      setApiPage(0);
      setStopFetch(true);
      return;
    }
    const resultsJson = JSON.parse(resultsJsonString);
    const nextPage = approvalData?.listRemediationRequests?.page;
    if (nextPage) {
      setApiPage(nextPage - 1);
    } else {
      setApiPage(0);
      setStopFetch(true);
    }
    resultsJson.sort((a: RemediationRequest, b: RemediationRequest) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
    setJsonData((prevItems: any) => [...prevItems, ...resultsJson]);
  };

  const { loading, refetch, fetchMore } = useListRemediationRequestsQuery({
    variables: {
      arn: arn,
      page: apiPage + 1,
      limit: apiLimit,
    },
    context: {
      apiName: "automation_approval",
    },
    onCompleted: AutomationApprovalCalback,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const refreshButtonClick = () => {
    setStopFetch(false);
    setJsonData([]);
    setApiPage(0);
    setPage(0);
    refetch({
      page: 0,
      arn: arn,
      limit: apiLimit,
    });
  };

  useEffect(() => {
    if (stopFetch) return;
    if (!loading && apiPage > 0) {
      fetchMore({
        variables: {
          page: apiPage + 1,
          limit: apiLimit,
          arn: arn,
        },
      });
    }
  }, [apiPage, loading]);

  return (
    <Container component="main">
      <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
        <Heading heading="Scheduled Continuous Compliance Remediations" />
        <Grid container flexDirection="row">
          <Paper
            component="div"
            elevation={0}
            sx={{
              p: "2px 4px",
              mb: 2,
              mt: 5,
              display: "flex",
              border: 0,
              boxShadow: 0,
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                p: "2px 4px",
                mb: 2,
                mt: 2,
                display: "flex",
                border: 0,
                boxShadow: 0,
                flexGrow: 1,
              }}
            >
              <TextField
                id="SearchTextField"
                label="Search"
                size="small"
                sx={{
                  width: "305px",
                  marginLeft: "32px",
                  alignSelf: "center",
                }}
                onInput={requestSearch}
                onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <SearchOutlinedIcon color="secondary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                p: "2px 4px",
                mb: 2,
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1,
              }}
            >
              <InputLabel
                id="page-size"
                sx={{
                  alignSelf: "center",
                  p: 1,
                  color: "#000000",
                  fw: "400",
                }}
              >
                Show
              </InputLabel>
              <FormControl
                sx={{ m: 1, marginRight: "32px", minWidth: 119 }}
                size="small"
              >
                <StyledSelect
                  labelId="page-size-select-label"
                  id="page-size-select"
                  defaultValue={25}
                  onChange={(event: any) => {
                    setRowsPerPage(event.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </StyledSelect>
              </FormControl>
              <RefreshButton
                id="refresh-button"
                variant="outlined"
                sx={{ alignSelf: "center", p: 1, marginRight: "32px" }}
                onClick={refreshButtonClick}
              >
                <RefreshOutlinedIcon
                  sx={{ width: "16px", height: "16px", margin: "8px" }}
                />
                Refresh
              </RefreshButton>
            </Box>
          </Paper>
          <TableContainer sx={{ padding: "32px" }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>AWS Account ID</StyledTableCell>
                  <StyledTableCell>Control ID</StyledTableCell>
                  <StyledTableCell>Resource</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Scheduled</StyledTableCell>
                  <StyledTableCell>Modified</StyledTableCell>
                  <StyledTableCell>Created</StyledTableCell>
                  {/* <StyledTableCell>Approve</StyledTableCell>
                  <StyledTableCell>Reject</StyledTableCell> */}
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow>
                    <StyledTableCell colSpan={8}>
                      <Loader></Loader>
                    </StyledTableCell>
                  </TableRow>
                )}
                {!loading && currentItems.length === 0 && (
                  <TableRow>
                    <StyledTableCell colSpan={8} align="center">
                      No Scheduled requests or Continuous compliance requests
                      found. (Please note all approved requests are
                      automatically archieved.)
                    </StyledTableCell>
                  </TableRow>
                )}
                {!loading &&
                  currentItems
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: RemediationRequest, index: number) => {
                      return (
                        <TableRow key={index}>
                          <StyledTableCell>
                            {item.aws_account_id}
                          </StyledTableCell>
                          <StyledTableCell>{item.control_ref}</StyledTableCell>
                          <StyledTableCell>{item.resource_ref}</StyledTableCell>
                          <StyledTableCell>{item.status}</StyledTableCell>
                          <StyledTableCell>
                            {item?.scheduled !== "None"
                              ? new Date(item.scheduled).toLocaleString()
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell>{new Date(item.modified_at).toLocaleString()}</StyledTableCell>
                          <StyledTableCell>{new Date(item.created_at).toLocaleString()}</StyledTableCell>
                          {/* <StyledTableCell>
                          <StyledButton
                            variant="contained"
                            color="secondary"
                            disabled={
                              item.status === "APPROVED" ||
                              item.status === "SCHEDULED"
                            }
                            onClick={() =>
                              handleApproveOnClick(parseInt(item.id))
                            }
                          >
                            Approve
                          </StyledButton>
                        </StyledTableCell> */}
                          {/* <StyledTableCell>
                          <StyledButton
                            variant="contained"
                            color="secondary"
                            disabled={
                              item.status === "REJECTED" ||
                              item.status === "SCHEDULED"
                            }
                            onClick={() =>
                              handleRejectOnClick(parseInt(item.id))
                            }
                          >
                            Reject
                          </StyledButton>
                        </StyledTableCell> */}
                          <StyledTableCell>
                            <StyledButton
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                navigate(
                                  `/automation-approval/result?id=${item.id}`
                                );
                              }}
                            >
                              Results
                            </StyledButton>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: "10px",
              color: "primary.main",
              borderColor: "primary.main",
            }}
            shape="rounded"
            count={Math.ceil(currentItems.length / rowsPerPage)}
            page={page + 1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setPage(value - 1);
            }}
          />
        </Grid>
      </Container>
    </Container>
  );
};

export default AutomationApprovals;
