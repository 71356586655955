import { styled, Select, selectClasses } from "@mui/material";

const StyledSelect = styled(Select)(({ theme }) => {
  return {
    [`&.${selectClasses.icon}`]: {
      color: theme.palette.secondary.main,
    },
  };
});

export default StyledSelect;
