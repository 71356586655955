import {
    useGetLambdaCreationResultLazyQuery,
    OnResultDocument,
} from "../generated";
  
import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";

const useAutomationSubscription = ({ arn, prompt, setLambdaCode, setLoading }: any) => {
  const client = useApolloClient();

  const [
    getLambdaCreationResult,
    { data, loading, called },
  ] = useGetLambdaCreationResultLazyQuery({
    variables: {
      arn: arn,
      customRemediation: false,
      prompt: prompt,
    },
  });

  useEffect(() => {
    if (data?.getLambdaCreationResult?.output) {
      console.log("Subscribing 1")
      client
        ?.subscribe({
          query: OnResultDocument,
          variables: {
            id: data?.getLambdaCreationResult.output,
          },
        })
        .subscribe(
          async ({ data }: any) => {
            console.log("subscribing 2")
            const url = data?.onResult?.result;
            console.log("Found URL", url);
            let res = await fetch(url);
            let automationJson = await res.json();
            setLambdaCode(automationJson.code);
            setLoading(false);
          },
          (error: any) => {
            console.log(error);
          },
          () => {
            console.log("completed subscription");
          }
        );
    }
  }, [data, called, getLambdaCreationResult]);

  return { loading, getLambdaCreationResult };
};

export default useAutomationSubscription;