import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses, LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { AutomateProgressStatusDocument } from "../../generated";
import { client } from '../../lib/clients/apolloClient';

interface SharrDeployProgressProps {
  progressValue: number;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 25,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.mode === theme.palette.primary.main ? theme.palette.primary.main : theme.palette.primary.main,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === theme.palette.secondary.main ? theme.palette.secondary.main : theme.palette.secondary.main,
    },
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.primary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}

export default function SharrDeployProgress({progressValue}: SharrDeployProgressProps) {
    
    //Call the automate progress api every 30 seconds to get the latest progress value
    useEffect(() => {
      const timer = setInterval(() => {
        client.refetchQueries({
          include: [AutomateProgressStatusDocument],
        });
      }, 30000);
      return () => {
        clearInterval(timer);
      };
    }, []);

    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgressWithLabel value={progressValue} />
      </Box>
    )
}