import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import FileDropzone from "../common/FileDropzone";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { useUploadTscan } from "../../../hooks/useUploadTscan";
import { useUpdateIacAnswer } from "../../../hooks/useUpdateIacAnswer";
import { useCreateIacMilestone } from "../../../hooks/useCreateIacMilestone";
import DisplayTemplateScanner from "./DisplayTemplateScanner";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface UploadIacFilesProps {
  createdWorkloads: any;
  wafrArn: string;
  setRiskCountsDict: any;
  setFirstRiskCountsDict: any;
  tscanSummaryIds: number[];
  setTscanSummaryIds: React.Dispatch<React.SetStateAction<number[]>>;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
    }>
  >;
  changeTab: (index: number) => void;
}

interface RiskCountDictionary {
  [key: string]: number;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const LinearWithValueLabel = ({ progress }: any) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Container>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UploadIacFiles({
  createdWorkloads,
  wafrArn,
  setRiskCountsDict,
  setFirstRiskCountsDict,
  tscanSummaryIds,
  setTscanSummaryIds,
  setUnlockSteps,
  changeTab,
}: UploadIacFilesProps) {
  const [value, setValue] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false); // spinner that displays while files are being analysed
  const [uploadProgress, setUploadProgress] = useState(0);
  const [filesUploading, setFilesUploading] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  // const [tscanSummaryIds, setTscanSummaryIds] = useState<number[]>([]);

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValue(newValue);
  };

  //Handle that unlocks step 3 and locks step 2
  const handleUnlockSteps = () => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step2: false, // Lock step 2
      step3: true, // Unlock step 3
    }));
  };

  const { templateScannerResponse, getTotalUploadedFiles } = useUploadTscan({
    uploadProgress,
    setUploadProgress,
    setTscanSummaryIds,
  });

  const { updateIacWorkload } = useUpdateIacAnswer();
  const { createIacMilestone } = useCreateIacMilestone();

  const addRiskKeyValuePairs = (newPairs: RiskCountDictionary) => {
    setRiskCountsDict((riskCountsDict: any) => ({
      ...riskCountsDict,
      ...newPairs,
    }));
    setFirstRiskCountsDict((firstRiskCountsDict: any) => ({
      ...firstRiskCountsDict,
      ...newPairs,
    }));
  };

  const handleFileUpload = async () => {
    if (files.length === 0) {
      setActiveSpinner(false);
      return;
    }
    setTscanSummaryIds([]);
    setFileUploadError(false);
    setActiveSpinner(true);
    setDisabled(true);
    setUploadProgress(5);
    Object.entries(createdWorkloads).map(
      async ([key, value]) => {
      await Promise.all(files.map((file) => templateScannerResponse(file, key)));
    });
  };

  const updateIacAnswer = async () => {
    const promises = Object.entries(createdWorkloads).map(
      async ([key, value]) => {
        const update_iac_answer = await Promise.all([
          updateIacWorkload(
            typeof value === "string" ? value : "",
            wafrArn,
            tscanSummaryIds
          ),
        ]);
        if (update_iac_answer?.[0]?.workloadIacupdate === true) {
          const create_iac_milestone = await Promise.all([
            createIacMilestone(
              typeof value === "string" ? value : "",
              key,
              wafrArn
            ),
          ]);
          let highriskcount =
            create_iac_milestone?.[0]?.data?.createIacMilestone?.RiskCounts
              ?.HIGH;
          addRiskKeyValuePairs({
            [typeof value === "string" ? value : ""]: highriskcount || 0,
          });
          setUploadProgress(0);
          setActiveSpinner(false);
          setDisabled(false);
          handleUnlockSteps();
          changeTab(2);
        }
      }
    );
    Promise.all(promises);
  };

  useEffect(() => {
    if (
      tscanSummaryIds.length === getTotalUploadedFiles() &&
      tscanSummaryIds.length > 0
    ) {
      updateIacAnswer();
    }
  }, [tscanSummaryIds]);

  useEffect(() => {
    if (fileUploadError === true) {
      setActiveSpinner(false);
      setDisabled(false);
    }
  }, [fileUploadError]);

  return (
    <Container maxWidth="lg" component={Paper}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
          >
            <Tab label="Upload Files" {...a11yProps(0)} />
            <Tab label="Select Files" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} key={0}>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12} justifyContent="center" alignItems="center">
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body1">
                  Please select how do you like to upload your templates ?​
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  href="https://bitbucket.org/5pillars/compliance-copilot/src/main/"
                  target="_blank"
                  rel="noopener noreferrer"
                  type="submit"
                  variant="outlined"
                  sx={{
                    width: 250,
                    height: 40,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Bitbucket
                </Button>
                <Button
                  href="https://github.com/marketplace/actions/6pillars-compliance-copilot-action"
                  target="_blank"
                  rel="noopener noreferrer"
                  type="submit"
                  variant="contained"
                  sx={{
                    width: 250,
                    height: 40,
                    bgcolor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    },
                  }}
                >
                  Github
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} justifyContent="center" alignItems="center">
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body1">
                  Upload the required CloudFormation or Terraform templates you
                  wish to scan.
                </Typography>
              </Stack>
            </Grid>
            <Grid container sx={{ p: 1 }}>
              <Grid item xs md={12} sx={{ pt: 2, pb: 2 }}>
                <FileDropzone
                  files={files}
                  setFiles={setFiles}
                  disabled={activeSpinner}
                />
              </Grid>
              <Grid item md={12}>
                {activeSpinner && uploadProgress > 0 ? (
                  <LinearWithValueLabel progress={uploadProgress} />
                ) : null}
              </Grid>
              <Grid item md={10}></Grid>
              <Grid item md={2}>
                <LoadingButton
                  id="wat-setup-btn"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={handleFileUpload}
                  disabled={disabled}
                  loading={activeSpinner}
                  sx={{
                    width: 157,
                    height: 40,
                    bgcolor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    },
                  }}
                  loadingIndicator={
                    <CircularProgress color={"secondary"} size={22} />
                  }
                >
                  Upload
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DisplayTemplateScanner
            tscanSummaryIds={tscanSummaryIds}
            setTscanSummaryIds={setTscanSummaryIds}
            disabled={disabled}
            setDisabled={setDisabled}
            activeSpinner={activeSpinner}
            setActiveSpinner={setActiveSpinner}
            updateIacAnswer={updateIacAnswer}
          />
        </TabPanel>
      </Box>
    </Container>
  );
}
