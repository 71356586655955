import { FC, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Link,
  Alert,
  LinearProgress,
} from "@mui/material";
import * as yup from "yup";
import {
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import useSnackBars from "../../hooks/useSnackbar";
import useCognito from "../../hooks/useCognito";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPasword: FC = () => {
  const navigate = useNavigate();
  const { userPool } = useCognito();
  const { setAlerts } = useSnackBars();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, msg: "" });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setError({ status: false, msg: "" });
      const authenticationData = {
        Username: values.email,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      const userData = {
        Username: values.email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: () => {
          setLoading(false);
        },
        onFailure: (err) => {
          setLoading(false);
          setError({ status: true, msg: err.message || JSON.stringify(err) });
        },
        inputVerificationCode: (data) => {
          setLoading(false);
          setAlerts([
            {
              severity: "success",
              msg: `${data?.CodeDeliveryDetails?.AttributeName} sent to ${data?.CodeDeliveryDetails?.Destination}`,
            },
          ]);
          navigate(`/confirm-password/${values.email}`);
        },
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant="h3"
            fontSize={"22px"}
            fontWeight={"500"}
            color={"secondary"}
            sx={{ pt: 0, pb: 4 }}
          >
            Forgot your password
          </Typography>
          <Typography
            variant="h5"
            fontSize={"16px"}
            fontWeight={"500"}
            color={"black"}
            sx={{ pt: 0, pb: 4 }}
          >
            You will receive a password reset code to your email.
          </Typography>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            sx={{ maxWidth: "350px", pb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Link
              onClick={() => {
                navigate("/login");
              }}
              sx={{
                mt: 2,
                mb: 2,
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="caption" fontSize={"16px"} color="secondary">
                Back to Login
              </Typography>
            </Link>
          </Box>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
            sx={{ mb: 2, width: "90px", backgroundColor: "secondary.main" }}
          >
            Request
          </Button>
          {error.status && <Alert severity="error">{error.msg}</Alert>}
          <Divider />
          <Link
            sx={{ pt: 4, pb: 2, textDecoration: "none", cursor: "pointer" }}
            onClick={() => {
              navigate("/signup");
            }}
          >
            <Typography variant="caption" fontSize={"16px"} color="secondary">
              Don't have an account? Sign up for FREE here.
            </Typography>
          </Link>
          {loading && <LinearProgress color="secondary" />}
        </Box>
      </form>
    </div>
  );
};

export default ForgotPasword;
