import Stack from "@mui/material/Stack";
import { GetUrlDocument } from "../../generated";
import Button from "@mui/material/Button";
import { useLazyQuery } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { CircularProgress, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { OpenInNew } from "@mui/icons-material";

interface RollbackAccountProps {
  url: string;
  loading: boolean;
}

export default function RollbackAccount({
  url,
  loading,
}: RollbackAccountProps) {
  return (
    <Stack direction="row">
      <FormControl fullWidth>
        <LoadingButton
          disabled={loading}
          loading={loading}
          href={url}
          target="_blank"
          id="automate-setup-btn"
          variant="contained"
          endIcon={<OpenInNew />}
          loadingPosition="end"
          sx={{
            bgcolor: "secondary.main",
            "&:hover": {
              bgcolor: "secondary.main",
              color: "secondary.contrastText",
            },
          }}
        >
          Deploy Rollback Template
        </LoadingButton>
      </FormControl>
    </Stack>
  );
}
