import React, { FC, useState, useMemo, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useListSecurityControlsQuery } from "../../hooks/useListSecurityControlsQuery";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Pagination,
  SvgIcon,
  SelectChangeEvent,
  IconButton,
  Container,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getExpandedRowModel,
  FilterFn,
  SortingFn,
  sortingFns,
  Row,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import Findings from "./../common/Findings";
import Filter from "./Filter";
import Control from "../../models/Control";
import StyledTableCell from "./../common/StyledTableCell";
import {
  IndeterminateCheckbox,
  IndeterminateAllCheckbox,
} from "./../common/Checkboxes";
import { AlertSwitch, AutomateSwitch } from "./../common/Switches";
import StyledSelect from "./../common/StyledSelect";
import MultiRemediateSelect from "./../common/MultiRemediateSelect";
import Search from "./../common/Search";
import useAccount from "../../hooks/useAccount";
import Loader from "./../common/Loader";
import Heading from "../common/Heading";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const ListSecuirtyControls: FC = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const { arn, featureAllowed } = useAccount();
  const [rowSelection, setRowSelection] = useState({});
  const [rowsUpdated, setRowsUpdated] = useState<{
    updated: boolean;
    controls: string[];
    column: string;
    value: string;
  }>({
    updated: false,
    controls: [],
    column: "",
    value: "",
  });
  const [filter, setFilter] = useState({
    complianceStandard: "",
    destructive: "",
    compliant: "",
  });
  const updateData = (controls: string[], column: string, value: string) => {
    setRowsUpdated({
      updated: true,
      controls: controls,
      column: column,
      value: value,
    });
  };
  const columns = useMemo<ColumnDef<Control>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateAllCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }: { row: Row<Control> }) =>
          row.original.remediatestatus !== "N/A" ? (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ) : (
            ""
          ),
      },
      {
        id: "controlid",
        header: ({ table }) => {
          return (
            <Box sx={{ width: 140 }}>
              <Typography fontSize={"14px"}>{"Control ID"}</Typography>
            </Box>
          );
        },
        accessorKey: "controlname", //using accessorKey dot notation to access nested data
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorKey: "controldescription", //using accessorKey dot notation to access nested data
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        header: ({ table }) => {
          return (
            <Box sx={{ width: 270 }}>
              <Typography fontSize={"14px"}>{"Description"}</Typography>
            </Box>
          );
        },
        cell: ({ row, getValue }: any) => {
          return (
            <Box sx={{ width: 290 }}>
              <Typography
                fontSize={"14px"}
                dangerouslySetInnerHTML={{ __html: getValue() }}
              ></Typography>
            </Box>
          );
        },
      },
      {
        header: "Compliance",
        accessorKey: "compliancestatus",
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        cell: ({ row, getValue }: any) => {
          let value = getValue();
          let color = "#383737";
          let icon = <></>;
          if (value === "PASSED") {
            color = "success.main";
            icon = (
              <CheckCircleOutlineRoundedIcon
                sx={{ color }}
              ></CheckCircleOutlineRoundedIcon>
            );
          } else if (value === "FAILED") {
            color = "error.main";
            icon = <CancelOutlinedIcon sx={{ color }}></CancelOutlinedIcon>;
          }
          return (
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              {icon}
              <Typography fontSize={"14px"} color={color} sx={{ ml: 0.5 }}>
                {value}
              </Typography>
            </Box>
          );
        },
      },
      {
        header: "Severity",
        accessorKey: "severity",
        filterFn: "fuzzy",
        sortingFn: severityFuzzySort,
        cell: ({ row, getValue }: any) => {
          let value = getValue();
          let color = "text";
          if (value === "MEDIUM") {
            color = "secondary.main";
          } else if (value === "LOW") {
            color = "success.main";
          } else if (value === "HIGH" || value === "CRITICAL") {
            color = "error.main";
          }
          return (
            <Box
              key={row.id}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "95px",
              }}
            >
              {value !== "UNKNOWN" ? (
                <WarningAmberIcon sx={{ color, mr: 0.5 }}></WarningAmberIcon>
              ) : (
                <></>
              )}
              <Typography fontSize={"14px"} color={color}>
                {value}
              </Typography>
            </Box>
          );
        },
      },
      {
        header: "Control",
        accessorKey: "alertstatus",
        enableSorting: false,
        cell: ({ row, getValue }: any) => (
          <AlertSwitch
            row={row}
            getValue={getValue}
            updateData={updateData}
          ></AlertSwitch>
        ),
      },
      {
        header: "Continuous Compliance",
        accessorKey: "remediatestatus",
        enableSorting: false,
        cell: ({ row, getValue }: any) => {
          return featureAllowed("continuous compliance") ? (
            <AutomateSwitch
              row={row}
              getValue={getValue}
              updateData={updateData}
            ></AutomateSwitch>
          ) : (
            <>N/A</>
          );
        },
      },
      {
        id: "expander",
        header: () => null,
        cell: ({ row }: { row: Row<Control> }) => {
          return row.getCanExpand() ? (
            <IconButton
              key={row.id}
              onClick={row.getToggleExpandedHandler()}
              sx={{
                cursor: "pointer",
              }}
              aria-label="delete"
              size="small"
            >
              {row.getIsExpanded() ? (
                <ExpandLessOutlinedIcon fontSize="inherit" color="secondary" />
              ) : (
                <ExpandMoreOutlinedIcon fontSize="inherit" color="secondary" />
              )}
            </IconButton>
          ) : (
            ""
          );
        },
      },
    ],
    []
  );

  const { controls, loading, error } = useListSecurityControlsQuery({
    arn,
    connectedAccounts: [],
  });

  const [tableRows, setTableRows] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    let data: any = controls.filter((control: any) => {
      if (filter.destructive !== "" || filter.compliant !== "") {
        let criteriaOne = true;
        let criteriaTwo = true;
        let criteriaThree = true;

        if (filter.destructive !== "") {
          criteriaTwo = false;
        }

        if (filter.compliant !== "") {
          criteriaThree = false;
        }

        if (
          Array.isArray(control?.customStandard) &&
          control?.customStandard.length > 0 &&
          control?.customStandard.some((standard: string) => {
            return standard.includes("NIST");
          })
        ) {
          criteriaOne = true;
        } else {
          criteriaOne = false;
        }

        if (
          filter.destructive !== "" &&
          control?.intrusive === "NO" &&
          control?.playbooks === "YES" &&
          filter.destructive === "NO"
        ) {
          criteriaTwo = true;
        }

        if (
          filter.destructive !== "" &&
          control?.intrusive === "YES" &&
          control?.playbooks === "YES" &&
          filter.destructive === "YES"
        ) {
          criteriaTwo = true;
        }

        if (
          filter.compliant !== "" &&
          control?.compliancestatus === filter.compliant
        ) {
          criteriaThree = true;
        }
        return criteriaOne && criteriaTwo && criteriaThree;
      } else {
        if (
          Array.isArray(control?.customStandard) &&
          control?.customStandard.length > 0 &&
          control?.customStandard.some((standard: string) => {
            return standard.includes("NIST");
          })
        ) {
          return true;
        } else {
          return false;
        }
      }
    });

    if (globalFilter !== null) {
      const search = globalFilter.toLowerCase();
      data = data.filter((control: any) => {
        if (control?.controlname.toLowerCase().includes(search)) return true;
        else if (control?.controldescription.toLowerCase().includes(search))
          return true;
        else if (control?.compliancestatus.toLowerCase().includes(search))
          return true;
        else if (control?.severity.toLowerCase().includes(search)) return true;
        else if (control?.alertstatus.toLowerCase().includes(search))
          return true;
        else if (control?.remediatestatus.toLowerCase().includes(search))
          return true;
        else return false;
      });
    }
    if (rowsUpdated.updated) {
      data = data.map((control: any) => {
        if (rowsUpdated.controls.includes(control?.controlname)) {
          control[`${rowsUpdated.column}`] = rowsUpdated.value;
        }
        return control;
      }) as never[];
    }
    setRowSelection({});
    setTableRows(data);
  }, [filter, globalFilter, controls]);

  useEffect(() => {
    setPageIndex(0);
  }, [filter, globalFilter]);

  const table = useReactTable({
    data: tableRows,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    defaultColumn: {
      size: 1,
      minSize: 1,
      maxSize: 2,
    },
    initialState: {
      sorting: [
        {
          id: "compliancestatus",
          desc: false,
        },
      ],
      pagination: {
        pageSize: 20,
        pageIndex: 0,
      },
    },
    state: {
      rowSelection,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    getRowCanExpand: (row: Row<Control>) => {
      if (
        row.original.controlname.startsWith("AFSBP") ||
        row.original.controlname.startsWith("CIS") ||
        row.original.controlname.startsWith("PCI") ||
        row.original.controlname.startsWith("6PL")
      ) {
        return true;
      } else {
        return false;
      }
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  if (error) {
    return (
      <Typography
        component={"span"}
        variant="h6"
        color="text.primary"
        paragraph
      >
        loading...
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <Container component="main" maxWidth="lg">
        <Heading heading={"Protect"} />
        <Box sx={{ width: "100%" }}>
          <Paper elevation={0} sx={{ mb: 2 }}>
            <Filter
              filter={filter}
              setFilter={setFilter}
              table={table}
            ></Filter>
          </Paper>
          <TableContainer component={Paper} sx={{ p: 4 }}>
            <Paper elevation={0} sx={{ p: 0 }}>
              <Grid container flexDirection="row">
                <Paper
                  component="div"
                  sx={{
                    p: "0px 0px",
                    mb: 0,
                    display: "flex",
                    border: 0,
                    boxShadow: 0,
                  }}
                >
                  <Search
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  ></Search>
                </Paper>
                <Paper
                  component="div"
                  elevation={0}
                  sx={{
                    p: "2px 4px",
                    mb: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    flexGrow: 1,
                  }}
                >
                  <InputLabel id="page-size" sx={{ alignSelf: "center", p: 1 }}>
                    Show
                  </InputLabel>
                  <FormControl sx={{ m: 1, minWidth: 119 }} size="small">
                    <StyledSelect
                      labelId="page-size-select-label"
                      id="page-size-select"
                      value={table.getState().pagination.pageSize}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </StyledSelect>
                  </FormControl>
                </Paper>
                <Paper
                  component="div"
                  elevation={0}
                  sx={{
                    p: "2px 4px",
                    mb: 2,
                    display: "flex",
                    alignItems: "right",
                    width: 205,
                    justifyContent: "center",
                  }}
                >
                  <MultiRemediateSelect
                    selectedRows={rowSelection}
                    rows={table.getSelectedRowModel().flatRows}
                    resetRowSelection={table.resetRowSelection}
                    updateData={updateData}
                  ></MultiRemediateSelect>
                </Paper>
              </Grid>
              <Grid container flexDirection="row">
                <Typography
                  component={"span"}
                  variant="h6"
                  color="text.primary"
                  fontWeight={400}
                  fontSize={"16px"}
                  sx={{
                    m: 1,
                    ml: 0,
                    mt: 0,
                    mb: 3,
                  }}
                >
                  {`Showing ${
                    table.getState().pagination.pageIndex *
                      table.getState().pagination.pageSize +
                    1
                  }-${
                    (table.getState().pagination.pageIndex + 1) *
                      table.getState().pagination.pageSize <
                    tableRows.length
                      ? (table.getState().pagination.pageIndex + 1) *
                        table.getState().pagination.pageSize
                      : tableRows.length
                  } of ${tableRows.length} entries`}
                </Typography>
              </Grid>
            </Paper>
            <Grid container flexDirection="row">
              <Table
                sx={{
                  width: table.getCenterTotalSize(),
                }}
              >
                <TableHead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        let canSort = header.column.getCanSort();
                        return (
                          <StyledTableCell
                            key={header.id}
                            colSpan={header.colSpan}
                            sx={{
                              width: header.getSize(),
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <Box
                                onClick={
                                  canSort
                                    ? header.column.getToggleSortingHandler()
                                    : undefined
                                }
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <span>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </span>
                                {canSort
                                  ? {
                                      asc: (
                                        <SvgIcon
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6 8V16.5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M8 14.5L6 16.5L4 14.5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M10 10V1.5"
                                            stroke="#FF6700"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M8 3.5L10 1.5L12 3.5"
                                            stroke="#FF6700"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </SvgIcon>
                                      ),
                                      desc: (
                                        <SvgIcon
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6 8V16.5"
                                            stroke="#FF6700"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M8 14.5L6 16.5L4 14.5"
                                            stroke="#FF6700"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M10 10V1.5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M8 3.5L10 1.5L12 3.5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </SvgIcon>
                                      ),
                                    }[
                                      header.column.getIsSorted() as string
                                    ] ?? (
                                      <>
                                        <SvgIcon
                                          width="16"
                                          height="18"
                                          viewBox="0 0 16 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6 8V16.5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M8 14.5L6 16.5L4 14.5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M10 10V1.5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M8 3.5L10 1.5L12 3.5"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </SvgIcon>
                                      </>
                                    )
                                  : null}
                              </Box>
                            )}
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <StyledTableCell colSpan={table.getTotalSize()}>
                        <Loader></Loader>
                      </StyledTableCell>
                    </TableRow>
                  ) : (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <>
                          <TableRow key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <StyledTableCell
                                  key={cell.id}
                                  sx={{
                                    width: cell.column.getSize(),
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </StyledTableCell>
                              );
                            })}
                          </TableRow>
                          {row.getIsExpanded() && (
                            <Findings
                              row={row}
                              arn={arn}
                              updateData={updateData}
                            ></Findings>
                          )}
                        </>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </Grid>
            <Grid
              container
              flexDirection="row"
              justifyContent={"flex-end"}
              sx={{ p: 0, pt: 3 }}
            >
              <Pagination
                count={table.getPageCount()}
                variant="outlined"
                sx={{
                  color: "primary.main",
                  borderColor: "primary.main",
                }}
                shape="rounded"
                page={table.getState().pagination.pageIndex + 1}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  value: number
                ) => {
                  setPageIndex(value - 1);
                }}
              />
            </Grid>
          </TableContainer>
        </Box>
      </Container>
    </Box>
  );
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const severityFuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  if (rowA.original[columnId] === rowB.original[columnId]) {
    return 0;
  }

  return Severity[rowA.original[columnId]] > Severity[rowB.original[columnId]]
    ? 1
    : -1;
};

enum Severity {
  CRITICAL,
  HIGH,
  MEDIUM,
  LOW,
  UNKNOWN,
}

export default ListSecuirtyControls;
