import { useGetBestPracticeLazyQuery, OnResultDocument } from "../generated";

import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";

const useBestPracticeSubscription = ({
  checkType,
  checkId,
  resource,
  description,
  fileFormat,
  setLoading,
  setBestPracticeCode,
}: any) => {
  const client = useApolloClient();

  const [getBestPractice, { data, loading, error, called, refetch }] =
    useGetBestPracticeLazyQuery({
      variables: {
        checkType,
        checkId,
        resource,
        description,
        fileFormat,
      },
      context: {
        apiName: "template_scanner",
      },
    });

  useEffect(() => {
    if (error) {
      console.log("Error is ", error);
    }
    if (data?.getBestPractice?.uuid) {
      console.log("Subscribing 1");
      client
        ?.subscribe({
          query: OnResultDocument,
          variables: {
            id: data?.getBestPractice?.uuid,
          },
        })
        .subscribe(
          async ({ data }: any) => {
            console.log("subscribing 2");
            const url = data?.onResult?.result;
            console.log("Found URL", url);
            let res = await fetch(url);
            let bestPractice = await res.text();
            setBestPracticeCode(bestPractice);
            setLoading(false);
          },
          (error: any) => {
            console.log("Error Detected");
            console.log(error);
          },
          () => {
            console.log("completed subscription");
          }
        );
    }
  }, [data, called, getBestPractice, error]);

  return { loading, getBestPractice, refetch };
};

export default useBestPracticeSubscription;
