import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { Helmet } from "react-helmet";

try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_RUM_GROUP_ROLE_ARN,
    identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
    endpoint: process.env.REACT_APP_RUM_END_POINT,
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  };

  const APPLICATION_ID = process.env.REACT_APP_RUM_APPLICATION_ID || "";
  const APPLICATION_VERSION =
    process.env.REACT_APP_RUM_APPLICATION_VERSION || "";
  const APPLICATION_REGION = process.env.REACT_APP_RUM_APPLICATION_REGION || "";

  const awsRum: AwsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Helmet>
      <script
        src={`static/js/newrelic-${process.env.REACT_APP_ENV}.js`}
        crossOrigin="anonymous"
        type="text/javascript"
      />
    </Helmet>
    <App />
  </React.StrictMode>
);
