import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from '@mui/material/CircularProgress';
import { DownloadIacReportsDocument, useListIacMilestonesQuery } from "../../../generated";
import { useLazyQuery } from "@apollo/client";
import Alert from '@mui/material/Alert';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import OnResult from "../../subscriptions/OnResult";

interface DownloadReportsProps {
  wafrArn: string;
  workloadname: string;
  workloadid: string;
  workloadlens: string;
}

export default function DownloadIacReports({wafrArn, workloadname, workloadid, workloadlens}: DownloadReportsProps){
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [stateMachineId, setStateMachineId] = useState(null);
  const [subscriptionResult, setSubscriptionResult] = useState(null);

  const [ downloadIacReport, { loading:Loading, error:ReportError }] = useLazyQuery(DownloadIacReportsDocument, { 
    onCompleted: (ReportData) => {
      setStateMachineId(ReportData?.downloadIacReports?.id)
    } 
  });

  const milestones = useListIacMilestonesQuery({
    variables: {
      workloadid: workloadid,
      awsWafrAccount: wafrArn
    },
    context: {
      apiName: "well_architected"
    },
    notifyOnNetworkStatusChange: true,
  });
  let milestonenumber = 0;

  function Download(){
    milestones.data?.listIacMilestones?.MilestoneSummaries.map((value: any, index) => {
      if(index < 1){
        milestonenumber = value.MilestoneNumber;
      }
      return (
        <></>
      );
    })
    setActiveSpinner(true);
    downloadIacReport({
      variables: { 
        workloadname: workloadname,
        workloadid: workloadid,
        lens: workloadlens,
        milestonenumber: milestonenumber,
        awsWafrAccount: wafrArn,
      },
      context: {
        apiName: "well_architected"
      },
    });
  }

  const onDownloadWATClick = (url:string, filename:string) => {
    const watelement = document.createElement("a");
    watelement.href = url;
    watelement.download = filename;
    document.body.appendChild(watelement);
    watelement.click();
    document.body.removeChild(watelement);
  }

  if(subscriptionResult !== null){
    var obj = JSON.parse(subscriptionResult);
    onDownloadWATClick(obj.waturl, obj.watfilename);
    setSubscriptionResult(null);
    setActiveSpinner(false);
  }

  return (
    <>
      <IconButton disabled={Loading} onClick={() => Download()} type="submit" color="secondary">
        {activeSpinner ? <CircularProgress /> : <CloudDownloadOutlinedIcon />}
        {stateMachineId !== null ? <OnResult statemachineid={stateMachineId} 
        setSubscriptionResult={setSubscriptionResult} setStateMachineId={setStateMachineId}/> : null}
      </IconButton>
      {ReportError ? <Alert severity="error">Report download failed - {ReportError.message}</Alert>: null}
    </>
  );
}
