import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  InputAdornment,
  InputLabel,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  Pagination,
  Box,
  IconButton,
  TableCell,
  Collapse,
  Link,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteAccount from "./DeleteAccount";
import RollbackAccount from "./rollback-account/RollbackAccount";
import TestAccount from "./TestAccount";
import StyledSelect from "../common/StyledSelect";
import StyledTableCell from "../common/StyledTableCell";
import Loader from "../common/Loader";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import UpgradeAccount from "./UpgradeAccount";
import AccountProgress from "./AccountProgress";
import { client } from "../../lib/clients/apolloClient";
import { AutomateProgressStatusDocument } from "../../generated";
import ControlStatusesDownloadBtn from "./ControlStatusesDownloadBtn";
import OrganisationDeployDialog from "./OrganisationDeployDialog";
import OrganisationRoleDialog from "./OrganisationRoleDialog";
import OrganisationRollbackDialog from "./OrganisationRollbackDialog";
import { json } from "stream/consumers";

interface LinkOrganisationAccountsProps {
  setTabSwitch: any;
  tabSwitch: any;
  data: any;
  loading: boolean;
  refetch: any;
}

interface Account {
  arn: string;
  defaultregion: string;
  externalid: string;
}

type Item = Account | null;

export default function LinkOrganisationAccounts({
  setTabSwitch,
  tabSwitch,
  data,
  loading,
  refetch,
}: LinkOrganisationAccountsProps) {
  const [accountRefIndex, setAccountRefIndex] = useState("");
  const [item, setItem] = useState<Item>(null);

  const handleAwsAccount = (event: SelectChangeEvent) => {
    let item: Item = data?.getUserAccounts?.items[Number(event?.target?.value)];
    setAccountRefIndex(event?.target?.value as string);
    setItem(item);
  };

  return (
    <>
      <Typography
        variant="body1"
        align="left"
        gutterBottom
        sx={{ paddingTop: "20px" }}
      >
        Link AUTOMATE+ to your AWS organisation AWS accounts with Continuous
        compliance to improve the breadth of remediation capability.
      </Typography>
      <Typography
        variant="body1"
        align="left"
        gutterBottom
        sx={{ paddingTop: "5px" }}
      >
        Deployment to multiple accounts as part of the organisation typically
        takes up to 1 hour, and depends primarily on the number of accounts
        inside your AWS Organisational Unit (OU).
      </Typography>
      <Typography
        variant="body1"
        align="left"
        gutterBottom
        sx={{ paddingTop: "20px" }}
      >
        Please select the AWS Audit account (preferred) or AWS Delegated
        administrator account you wish to use to extend your continuous
        compliance in your AWS organization.
      </Typography>
      <Grid container>
        <Grid item md={4}>
          <FormControl fullWidth>
            <InputLabel id="accountRef">AWS Account</InputLabel>
            <Select
              id="accountRef"
              required
              value={accountRefIndex}
              label="Select AWS Account"
              onChange={handleAwsAccount}
            >
              {data?.getUserAccounts?.items.map(
                (account: any, index: number) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {account?.arn.split(":")[4]}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {item && (
        <Box>
          <Typography
            variant="body1"
            align="left"
            gutterBottom
            sx={{ paddingTop: "20px" }}
          >
            <b>Prerequisites:</b>
          </Typography>
          <Box sx={{ backgroundColor: "#D9D9D9" }}>
            <Typography sx={{ paddingTop: "10px" }}>
              Please make sure the AWS Account that Automate+ is currently
              deployed to is registered as a delegated administrator for the
              Security Hub service and You have linked relavant AWS accounts to
              your Delegated Security Hub Service with AWS Config enabled in
              individual account's regions.
            </Typography>
            <Typography sx={{ paddingTop: "10px" }}>
              Please make sure the AWS Account that Automate+ is currently
              deployed to is registered as a delegated administrator.
            </Typography>
            <Link
              target="_blank"
              sx={{ fontSize: "10px" }}
              href="https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacksets-orgs-delegated-admin.html"
            >
              Documentation link: How to register as a delegated administrator
            </Link>
            <Typography sx={{ paddingTop: "10px" }}>
              Add or update an existing AWS policy as part of the AWS
              Organisation’s service in the AWS management account.
            </Typography>
            <Link target="_blank" sx={{ fontSize: "10px" }} href="">
              Documentation link: 6pillars Knowledge Base - Setting up an AWS
              organisation
            </Link>
          </Box>
          <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
            <Grid item xs={8} sx={{ paddingTop: "0px" }}>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Step 1</b> : Launch a new CloudFormation stack in the
                nominated AWS account that will create two new roles and update
                the existing 6pillars role in the nominated delegated admin
                account ({item.arn.split(":")[4]}).
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "0px" }}>
              <OrganisationRoleDialog
                arn={item.arn}
                defaultregion={item.defaultregion}
              ></OrganisationRoleDialog>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
            <Grid item xs={8} sx={{ paddingTop: "0px" }}>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Step 2</b> : Launch a new CloudFormation stack that
                configures AWS (ASR) Automated Security Response member stacks
                in the additional AWS accounts as part of your AWS Organisation
                Unit (OU)
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ paddingTop: "0px" }}>
              <OrganisationDeployDialog
                arn={item.arn}
                defaultregion={item.defaultregion}
              ></OrganisationDeployDialog>
            </Grid>
          </Grid>
          <Box sx={{ backgroundColor: "#D9D9D9" }}>
            <Typography
              variant="body1"
              align="left"
              gutterBottom
              sx={{
                paddingTop: "5px",
                marginTop: 10,
              }}
            >
              If you wish to monitor the progress of the CloudFormation
              StackSets during the process, navigate to{" "}
              <b>‘CloudFormation StackSets’</b>, select <b>‘Self Manage’</b> and
              click <b>‘Review’</b> inside the delegated administrator AWS
              account.
            </Typography>
            <Typography
              variant="body1"
              align="left"
              gutterBottom
              sx={{ paddingTop: "20px" }}
            >
              Or click{" "}
              <Link
                href={`https:/${item.defaultregion}.console.aws.amazon.com/cloudformation/home?region=${item.defaultregion}#/stacksets`}
              >
                here
              </Link>{" "}
              to open a new window & navigate to ‘Self-managed StackSets’ in the
              AWS console.
            </Typography>
          </Box>
          <Typography
            variant="body1"
            align="left"
            gutterBottom
            sx={{ paddingTop: "20px" }}
          >
            <b>
              AUTOMATE+ will provide additional visibility and remediation
              options linked to your organization once the StackSets are showing
              as ‘completed’ in your AWS account.
            </b>
          </Typography>
          <OrganisationRollbackDialog
            arn={item.arn}
            defaultregion={item.defaultregion}
            externalId={item.externalid}
          ></OrganisationRollbackDialog>
        </Box>
      )}
    </>
  );
}
