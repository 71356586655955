import { FC } from "react";
import { SvgIcon } from "@mui/material";
const QuestionSvg: FC = () => (
  <SvgIcon fontSize="inherit">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 9.00011C9.00029 7.47454 10.1455 6.19212 11.6613 6.01987C13.1771 5.84762 14.5808 6.84039 14.9234 8.327C15.266 9.8136 14.4383 11.3206 13 11.8291C12.4004 12.0411 11.9997 12.6081 12 13.2441V13.5001"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.5C11.7929 16.5 11.625 16.6679 11.625 16.875C11.625 17.0821 11.7929 17.25 12 17.25C12.2071 17.25 12.375 17.0821 12.375 16.875C12.375 16.6679 12.2071 16.5 12 16.5V16.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H21.75C22.5784 0.75 23.25 1.42157 23.25 2.25V21.75C23.25 22.5784 22.5784 23.25 21.75 23.25H2.25C1.42157 23.25 0.75 22.5784 0.75 21.75V2.25Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default QuestionSvg;
