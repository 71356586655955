import React, { FC } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Box,
    Typography,
} from '@mui/material';

interface ThankYouDialogProps {
  open: boolean;
  onClose: () => void;
}

const ThankYouDialog: FC<ThankYouDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
       <DialogTitle>
        <Typography variant="h5" color="primary" fontWeight="bold">
            Thank you
        </Typography>
        </DialogTitle>
      <DialogContent>
        <Box textAlign="center">
          <DialogContentText>
            <Typography
                variant="h6"
                color="black"
            >
            The 6pillars team are working on it and will be in touch<br/>
            regarding your custom remediation requirement.
            </Typography>
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
            variant="contained" 
            onClick={onClose} 
            color="secondary"
            sx={{width: 100}}
        >
        OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThankYouDialog;
