import { FC, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useCognito from "../hooks/useCognito";
import { CognitoRefreshToken } from "amazon-cognito-identity-js";
import useAccount from "../hooks/useAccount";
import { authProtectedRoutes } from "./routes";

interface AuthmiddlewareProps {
  component: FC<any>;
  layout: FC<any>;
  isAuthProtected: Boolean;
  multiaccount: boolean;
  path: string;
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  multiaccount,
  path,
  ...props
}: AuthmiddlewareProps) => {
  const { userPool } = useCognito();
  const [tokenRefreshing, setTokenRefreshing] = useState(false);
  const [noUser, setNoUser] = useState(false);
  const { accounts, loading } = useAccount();
  const navigate = useNavigate();

  const refreshUserToken = async () => {
    setTokenRefreshing(true);
    const refreshTokeStr = localStorage.getItem("refreshToken") || "";
    const token = await new Promise((resolve, reject) => {
      try {
        userPool
          .getCurrentUser()
          ?.refreshSession(
            new CognitoRefreshToken({ RefreshToken: refreshTokeStr }),
            (err, session) => {
              if (err) reject(false);
              const accessToken = session.getAccessToken().getJwtToken();
              localStorage.setItem("accessToken", accessToken);
              const idToken = session.getIdToken();
              localStorage.setItem("idToken", idToken.getJwtToken());
              const refreshToken = session.getRefreshToken();
              localStorage.setItem("refreshToken", refreshToken.getToken());
              const user = idToken.decodePayload();
              localStorage.setItem("user", JSON.stringify(user));
              resolve(idToken);
            }
          );
      } catch (error) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("idToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        reject(false);
      }
      if (!token) {
        window.location.href = "/login";
      }
    });
    setTokenRefreshing(false);
  };

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    if (userStr !== null) {
      const user = JSON.parse(userStr);
      if (user.exp < Date.now() / 1000) {
        refreshUserToken();
      }
      if (
        !user.hasOwnProperty("custom:organisation") &&
        path !== "/new-organization"
      ) {
        navigate("/new-organization");
      }

      const route = authProtectedRoutes.find((r) => r.path === path);
      if (
        user.hasOwnProperty("custom:organisation") &&
        accounts &&
        accounts.length === 0 &&
        !loading &&
        route?.multiAccount
      ) {
        navigate("/accounts");
      }
    } else {
      setNoUser(true);
    }
  }, [path, loading]);

  if (!noUser) {
    return (
      <Layout multiaccount={multiaccount}>
        {!tokenRefreshing && <Component {...props} />}
      </Layout>
    );
  } else {
    return <Navigate to={"/signup"}></Navigate>;
  }
};

export default Authmiddleware;
