import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { TextField, Box } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCognitoAddOrgMutation } from "../../generated";
import Heading from "../common/Heading";
import { useNavigate } from "react-router-dom";
import useCognito from "../../hooks/useCognito";
import {
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import LoadingOverlay from "../common/LoadingOverlay";

const organisationNameRegExp = /^[a-zA-Z0-9_-\s+]{1,32}$/;

const validationSchema = yup.object({
  organisationName: yup
    .string()
    .matches(organisationNameRegExp, "Organization Name is not valid")
    .required("Organization Name is required"),
});

export default function NewOrganization() {
  const navigate = useNavigate();
  const { userPool, cognitoUser } = useCognito();
  const [cognitoAddOrgMutation, { data, loading, error }] =
    useCognitoAddOrgMutation();

  const formik = useFormik({
    initialValues: {
      organisationName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      cognitoAddOrgMutation({
        variables: {
          organisation: values.organisationName, // value for 'organisation'
        },
      })
        .then(refreshUserToken)
        .then(() => {
          if (!loading && data?.cognitoAddOrg?.status !== 0)
            navigate("/accounts");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const refreshUserToken = async () => {
    const refreshTokeStr = localStorage.getItem("refreshToken") || "";
    const token = await new Promise((resolve, reject) => {
      userPool
        .getCurrentUser()
        ?.refreshSession(
          new CognitoRefreshToken({ RefreshToken: refreshTokeStr }),
          (err, session) => {
            if (err) reject(false);
            const accessToken = session.getAccessToken().getJwtToken();
            localStorage.setItem("accessToken", accessToken);
            const idToken = session.getIdToken();
            localStorage.setItem("idToken", idToken.getJwtToken());
            const refreshToken = session.getRefreshToken();
            localStorage.setItem("refreshToken", refreshToken.getToken());
            const user = idToken.decodePayload();
            localStorage.setItem("user", JSON.stringify(user));
            resolve(idToken);
          }
        );
    });
  };

  return (
    <Container component="main">
      <Container maxWidth="lg">
        <Heading heading={"Create New Account"} />
      </Container>
      <Box sx={{ width: "100%", pt: 4 }}>
        <LoadingOverlay show={loading}></LoadingOverlay>
        <form onSubmit={formik.handleSubmit}>
          <Container sx={{ width: 1 }} component={Paper}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={12}>
                <Typography variant="h4" color="primary.main" gutterBottom>
                  Welcome {cognitoUser?.given_name}.
                </Typography>
              </Grid>
              <Grid item md={12}>
                {error ? (
                  <Alert severity="error">Organisation Creation Failed</Alert>
                ) : null}
                {data?.cognitoAddOrg?.status === 0 ? (
                  <Alert severity="error">{data.cognitoAddOrg.message}</Alert>
                ) : null}
                {data?.cognitoAddOrg?.status === 1 ? (
                  <Alert severity="success">{data.cognitoAddOrg.message}</Alert>
                ) : null}
              </Grid>
              <Grid item md={12}>
                <Typography variant="body2" color="text.primary" paragraph>
                  Please enter your company name and click Next
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" color="text.primary" paragraph>
                  Company
                </Typography>
                <TextField
                  required
                  fullWidth
                  id="organisationName"
                  placeholder="Organisation Name"
                  label="Organisation Name"
                  value={formik.values.organisationName}
                  error={
                    formik.touched.organisationName &&
                    Boolean(formik.errors.organisationName)
                  }
                  helperText={
                    formik.touched.organisationName &&
                    formik.errors.organisationName
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={8}></Grid>
              <Grid item md={12}>
                <Stack direction="row">
                  <Button
                    id="automate-setup-btn"
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      width: 157,
                      height: 40,
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    }}
                  >
                    Next
                  </Button>
                </Stack>
              </Grid>
              <Grid item md={12}></Grid>
            </Grid>
          </Container>
        </form>
      </Box>
    </Container>
  );
}
