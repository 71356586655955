import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useDeleteEventMutation } from "../../generated/index";
import { Button, Modal, Typography, Box, Stack, Grid } from "@mui/material";

interface DeleteEventProps {
  eventId: string;
  setTableUpdate: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DeleteEvent({
  eventId,
  setTableUpdate,
}: DeleteEventProps) {
  const [deleteEventMutation, { error }] = useDeleteEventMutation({
    variables: {
      finding_ref: eventId,
    },
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [disable, setDisable] = useState(false);

  const deleteEventButton = () => {
    setDisable(true);
    deleteEventMutation().then(() => {
      if (error) {
        console.error(error);
      }
      setTableUpdate(true);
      handleClose();
    });
  };

  return (
    <Stack direction="row">
      <IconButton onClick={handleOpen} color="secondary">
        <DeleteOutlinedIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs md={10}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Delete Event
              </Typography>
            </Grid>
            <Grid item xs md={2}>
              <Button onClick={() => handleClose()}>
                <CloseIcon />
              </Button>
            </Grid>
            <Grid item xs md={8} sx={{ mt: 2 }}></Grid>
            <Grid item xs md={4} sx={{ mt: 2 }}>
              <Button
                disabled={disable}
                onClick={() => deleteEventButton()}
                type="submit"
                variant="contained"
                startIcon={<DeleteOutlinedIcon />}
                sx={{
                  bgcolor: "secondary.main",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "secondary.contrastText",
                  },
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Stack>
  );
}
