const s3PromptDescriptions = [
    "enable block all public access to AWS S3 buckets on the AWS account level", //S3.1
    "block all public read access for an AWS S3 bucket", //S3.2
    "block all public write access for an AWS S3 bucket", //S3.3
    "enable server-side encryption for an AWS S3 bucket", //S3.4
    "enable secure socket layer for an AWS S3 bucket", //S3.5
    "restrict AWS S3 permissions being granted to other AWS accounts in S3 bucket policies", //S3.6
    "block all public access to an AWS S3 bucket on the AWS bucket level", //S3.8
    "enable AWS S3 bucket server access logging", //S3.9
    "enable AWS s3 bucket lifecycle policies for AWS S3 buckets with lifecycle policies configured", //S3.10
    "enable event notifications for AWS S3 buckets", //S3.11
    "locate all AWS S3 buckets that are configured using ACLs", //S3.12*
    "locate all AWS S3 buckets that have lifecycle policies configured", //S3.13*
]

const ec2PromptDescriptions = [
    "configure AWS EBS snapshots to be private and unrestorable by anyone", //EC2.1
    "disable inbound and outbound traffic for the default AWS EC2 VPC security group", //EC2.2
    "encrypt attached AWS EBS volumes at rest", //EC2.3
    "remove all stopped AWS EC2 instances after a specific time period", //EC2.4
    "enable VPC flow logging in all AWS EC2 VPCs", //EC2.6
    "enable default encryption for an AWS EBS instance", //EC2.7
    "enable IMDSv2 for all AWS EC2 instances", //EC2.8
    "remove all public IP addresses for AWS EC2 instances", //EC2.9
    "configure AWS EC2 to use VPC endpoints that are created for the AWS EC2 service", //EC2.10*
    "disable AWS EC2 subnets automatically assigning public IP addresses", //EC2.15
    "remove unused AWS EC2 network access control lists", //EC2.16
    "remove secondary elastic network interfaces for AWS EC2", //EC2.17*
    "configure AWS EC2 security groups to only allow unrestricted incoming traffic for authorized ports", //EC2.18
    "configure AWS EC2 security groups to block unrestricted access to ports with high risk", //EC2.19*
    "enable both AWS VPN tunnels for an AWS Site-to-Site VPN connection", //EC2.20
    "disable ingress from 0.0.0.0/0 to port 22 or port 3389 for AWS EC2 network ACLs", //EC2.21
    "remove unusued AWS EC2 security groups", //EC2.22
    "disable EC2 Transit Gateways from automatically accepting VPC attachment requests", //EC2.23
    "disable all active paravirtual AWS EC2 instance types", //EC2.24
    "use boto3 to block AWS EC2 launch templates from assigning public IP addresses to network interfaces", //EC2.25?
]

// const rdsPromptDescriptions = [
//     ""
// ]

const genericPromptDescriptions = [
    "upload a file to an AWS S3 bucket",
    "list all existing buckets for an AWS S3 bucket",
    "download a file from an AWS S3 bucket",
    "download a file from an AWS S3 bucket and write to a file",
    "generate a presigned URL for an AWS S3 bucket",
    "retrieve the policy for an AWS S3 bucket",
    "set the policy for an AWS S3 bucket",
    "delete the policy for an AWS S3 bucket",
    "get the access control list for an AWS S3 bucket",
    "retrieve the CORS configuration for an AWS S3 bucket",
    "set the CORS configuration for an AWS S3 bucket",
    "find all AWS EC2 regions",
    "find the latest Amazon Linux 2 AMI with AWS EC2",
    "find all unused AWS EC2 security groups",
    "extract all Security Group rules for AWS EC2",
    "write a dictionary as a json file to an AWS S3 bucket",
    "read a value from an AWS parameter store",
    "write a metric value into AWS CloudWatch",
    "invoke an AWS Lambda function",
    "create an AWS EC2 key pair",
    "create a new AWS EC2 instance",
    "get the public IP for a running AWS EC2 instance",
    "list all running AWS EC2 instances",
    "stop an AWS EC2 instance",
    "terminate an AWS EC2 instance",
    "create a secret in AWS secrets manager",
    "retrieve a secret value in AWS secrets manager",
    "modify an existing secret in AWS secrets manager",
    "update the version of a secret in AWS secrets manager",
    "delete a secret in AWS secrets manager",
    "generate a random password in AWS secrets manager",
    "create a user in AWS IAM",
    "list all users in AWS IAM",
    "update a user in AWS IAM",
    "create an AWS IAM policy",
    "list all AWS IAM policies",
    "attach a policy to an AWS IAM user",
    "create a group in AWS IAM",
    "add a user to an AWS IAM group",
    "attach an AWS IAM policy to a group",
    "create an AWS IAM role",
    "attach an AWS IAM policy to a role",
    "delete an AWS IAM user"
]

const data = [
    ...s3PromptDescriptions,
    ...ec2PromptDescriptions,
]

export default data;