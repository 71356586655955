import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Heading from "../common/Heading";
import AccountRollback from "./AccountRollback";

export default function Utilities() {
  return (
    <Container component="main">
      <Container maxWidth="lg">
        <Heading heading={"Utilities"} />
      </Container>
      <Box sx={{ width: "100%" }}>
        <AccountRollback />
      </Box>
    </Container>
  );
}
