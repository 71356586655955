import React, { FC } from "react";
import Signup from "./signup";
import MarketplaceSignup from "./MarketplaceSignup";
import Login from "./login";
import {
  Paper,
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  CssBaseline,
  Grid,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "./../../assets/automate-logo-new.svg";
import partnerNetwork from "./../../assets/competency-aws-partner.svg";
import partnerIcons from "./../../assets/partner-icons.svg";
import ForgotPasword from "./forgotpassword";
import ConfirmPassword from "./confirmpassword";
import ConfirmEmail from "./confirmemail";
import Mfa from "./mfa";
import Footer from "./../layout/footer";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const HowItWorksItem = ({ paddingBottom, children }: any) => (
  <ListItem
    sx={{
      p: { xs: 1, sm: 0, md: 0 },
      px: { xs: 0, sm: 0, md: "16px" },
      pl: { xs: 0, sm: 0, md: 0 },
    }}
  >
    <Typography
      variant="h6"
      color="primary"
      sx={{
        fontSize: "1rem",
      }}
    >
      {children}
    </Typography>
  </ListItem>
);

const Landing: FC<{ type: string }> = ({ type }: any) => {
  const selectedTabIndex = type === "signup" ? 0 : 1;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: "#EEEEEE",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        maxWidth: "100%",
        justifyContent: "space-between",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: "primary.main",
          flex: "display",
          flexDirection: "row",
        }}
      >
        <AppBar position="static">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <img src={logo} alt="6pillars's logo" />
            </Box>
            <Button
              href="//www.6pillars.ai/benefits"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", textTransform: "capitalize" }}
            >
              Benefits
            </Button>
            <Button
              href="//www.6pillars.ai/pricing"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", textTransform: "capitalize" }}
            >
              Pricing
            </Button>
            <Button
              href="//help.app.well-architected.ai"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", textTransform: "capitalize" }}
            >
              Knowledge-Base
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#EEEEEE",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          padding: { xs: 0, sm: 0, md: "80px" },
          pr: { xs: 0, sm: 0, md: "150px" },
        }}
      >
        <Grid container spacing={5} columns={{ xs: 6, sm: 6, md: 12 }}>
          <Grid item xs={6}>
            <Paper
              sx={{
                boxShadow: "none",
                backgroundColor: "#EEEEEE",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: { xs: 3, sm: 3, md: 1 },
                  pb: { xs: 0, sm: 0, md: 1 },
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  variant="h4"
                  color={"primary"}
                  sx={{
                    fontSize: "2.5rem",
                  }}
                >
                  Cloud automation you can finally trust.
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h4"
                  color={"primary"}
                  sx={{
                    p: { xs: 3, sm: 3, md: 1 },
                    py: { xs: 3, sm: 3, md: 5 },
                    fontSize: "1.3rem",
                  }}
                >
                  6pillars’ AUTOMATE+ delivers 100% safe compliance visibility,
                  continuous compliance options in line with AWS best-practices
                  and even fully configurable, autonomous continuous compliance
                  (optional).
                </Typography>
              </Box>
              <Box
                sx={{
                  justifyContent: { xs: "center", sm: "center", md: "left" },
                  display: "flex",
                  p: { xs: 3, sm: 3, md: 0 },
                  pb: { xs: 0, sm: 0, md: 0 },
                  width: { xs: "100%", sm: "100%", md: "80%" },
                }}
              >
                <img
                  src={partnerNetwork}
                  alt="6pillars's logo"
                  style={{ width: "inherit" }}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ m: 1, p: 1 }}>
              {(type === "signup" || type === "login") && (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={selectedTabIndex}
                      textColor="secondary"
                      indicatorColor="secondary"
                      onChange={(e, value) => {
                        const newRoute = value === 0 ? "/signup" : "/login";
                        navigate(newRoute);
                      }}
                    >
                      <Tab
                        sx={{ fontWeight: "500", fontSize: "16px" }}
                        label="SIGN-UP"
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{ fontWeight: "500", fontSize: "16px" }}
                        label="EXISTING USER LOGIN"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={selectedTabIndex} index={0}>
                    <Signup></Signup>
                  </TabPanel>
                  <TabPanel value={selectedTabIndex} index={1}>
                    <Login></Login>
                  </TabPanel>
                </>
              )}
              {type === "marketplace-signup" && (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs textColor="secondary" indicatorColor="secondary">
                      <Tab
                        sx={{ fontWeight: "500", fontSize: "16px" }}
                        label="REGISTER"
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={2} index={2}>
                    <MarketplaceSignup></MarketplaceSignup>
                  </TabPanel>
                </>
              )}
              {type === "forgot-password" && (
                <TabPanel value={1} index={1}>
                  <ForgotPasword></ForgotPasword>
                </TabPanel>
              )}
              {type === "confirm-password" && (
                <TabPanel value={1} index={1}>
                  <ConfirmPassword></ConfirmPassword>
                </TabPanel>
              )}
              {type === "confirm-email" && (
                <TabPanel value={1} index={1}>
                  <ConfirmEmail></ConfirmEmail>
                </TabPanel>
              )}
              {type === "mfa" && (
                <TabPanel value={1} index={1}>
                  <Mfa></Mfa>
                </TabPanel>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 0,
                px: { xs: 3, sm: 3, md: 0 },
              }}
            >
              <Typography
                variant="h4"
                color={"primary"}
                sx={{
                  fontSize: { xs: "2.125rem", sm: "2.125rem", md: "2.125rem" },
                  pb: 3,
                }}
              >
                How it works
              </Typography>
              <Box>
                <List sx={{ listStyleType: "disc", pl: 0 }}>
                  <HowItWorksItem>
                    NB: You will need to be logged into the AWS account that you
                    are wishing to deploy to during the deployment
                  </HowItWorksItem>
                  <HowItWorksItem>
                    1. Create your 6pillars account by completing the sign-up
                    process
                  </HowItWorksItem>
                  <HowItWorksItem>
                    2. Connect your AWS account(s) to 6pillars via a fully
                    automated deployment of 6pillars AUTOMATE+
                  </HowItWorksItem>
                  <HowItWorksItem>
                    3. Deployment takes approximately 1 minute to kick-off and
                    20 minutes to complete
                  </HowItWorksItem>
                  <HowItWorksItem>
                    4. The deployment automates the enablement and configuration
                    of AWS Security Hub, AWS Config & AWS Security Hub Automated
                    Security Response
                  </HowItWorksItem>
                  <HowItWorksItem>
                    5. This will involve the deployment of a number of
                    CloudFormation stacks that are required to create the AWS
                    Security Hub Automated Security Response roles & runbooks
                  </HowItWorksItem>
                  <HowItWorksItem>
                    6. Permissions associated with the roles are hardened to
                    least-privileged principals and are only related to
                    6pillars’s integration with AWS Security Hub, AWS
                    Well-Architected Tool, Events and AWS Elastic Disaster
                    Recovery
                  </HowItWorksItem>
                  <HowItWorksItem>
                    7. Once deployed, the additional AWS services will result in
                    an incremental increase in your AWS consumption (typically
                    1-2%)
                  </HowItWorksItem>
                  <HowItWorksItem>
                    8. If you would like to remove the installation after
                    deployment, navigate to{" "}
                    {<b>{"Settings > Manage Accounts"}</b>} and select
                    ‘Rollback’ beside your account
                  </HowItWorksItem>
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer></Footer>
    </Box>
  );
};

export default Landing;
