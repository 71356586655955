import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSubmitFindingsEmailMutation } from "../../generated";
import useAccount from "../../hooks/useAccount";
import LoadingButton from "@mui/lab/LoadingButton";
import useSnackBars from "../../hooks/useSnackbar";

const EmailDetailedFindings = ({
  filter,
  sx
}: {
  filter: any,
  sx: any
}) => {
  const { arn, getFilteredConnectedAccounts } = useAccount();
  const { setAlerts } = useSnackBars();
  const [open, setOpen] = useState(false);
  const [filterSelected, setFilterSelected] = useState(false);
  const userEmail = JSON.parse(localStorage.getItem('user') || '{}')?.email || null;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [submitFindingsEmailMutation, { loading }] = useSubmitFindingsEmailMutation({
    variables: {
      arn: arn,// value for 'arn'
      connectedAccounts: getFilteredConnectedAccounts(),// value for 'connectedAccounts'
      standard: filter?.complianceStandard,// value for 'standard'
      compliancestatus: filter?.compliant,// value for 'compliancestatus'
      intrusive: filter?.destructive,// value for 'intrusive'
    },
    context: {
      apiName: "well_architected",
    },
  });

  const submitOnClick = () => {
    submitFindingsEmailMutation().then(({ data }: any) => {
      handleClose();
      if(data?.submitFindingsEmail?.status === "COMPLETE"){
        setAlerts([
          {
            severity: "success",
            msg: `A copy of detailed findings report has been emailed to ${userEmail}`,
          },
        ]);  
      } else {
        setAlerts([
          {
            severity: "info",
            msg: `A copy of detailed findings report will be emailed shortly to ${userEmail}`,
          },
        ]);
      }
    });
  };

  useEffect(() => {
    if(filter?.complianceStandard !== ''){
      setFilterSelected(true);
    } else {
      setFilterSelected(false);
    }
  }, [filter]);

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={sx}
      >
        Detailed Findings
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Generate a Detailed Findings Report</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {filterSelected ? (
              `Would you like the detailed findings to be emailed to ${userEmail}`
            ):(
              `Would you like the detailed findings for all controls to be emailed to ${userEmail}?`
            )}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" sx={{pt:2, pb:2}}>
            {filterSelected || filter?.compliant || filter?.destructive ? (
              `Filtering Options`
            ):(
              ``
            )}
          </DialogContentText>
          <DialogContentText>
            {filter?.complianceStandard ? (
              <>
                <b>Compliance Standard:</b> {filter?.complianceStandard}
              </>
            ) : (
              ``
            )}
          </DialogContentText>
          <DialogContentText>
            {filter?.compliant? (
              <>
              <b>Compliance:</b> {filter?.compliant}
              </>
            ):(
              ``
            )}
          </DialogContentText>
          <DialogContentText>
            {filter?.destructive? (
              <>
              <b>Disruptive:</b> {filter?.destructive}
              </>
            ):(
              ``
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            disabled={loading}
            loading={loading}
            onClick={() => 
              submitOnClick()
            }
            loadingIndicator={
              <CircularProgress color={"secondary"} size={22} />
            }
            autoFocus
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailDetailedFindings;
