import { useCreateIacWorkloadMutation } from "../generated";

const useCreateIacWorkload = () => {
  const [createIacWorkloadMutation] = useCreateIacWorkloadMutation();
    
  const createIacWorkload = async (
    workloadname: string,
    awsCreditAccountId: string,
    awsTechnicalReviewAccount: string,
    wafr_arn: string,
    region: string,
  ) => {
    const workloadcreation = await createIacWorkloadMutation({
      variables: {
        workloadname: workloadname,
        description: `Mapping Security Hub with Well-Architected Tool (AWS Credit Account : ${awsCreditAccountId})`,
        environment: "PRODUCTION",
        awsregions: region,
        reviewowner: "admin",
        lenses: "wellarchitected",
        notes: ``,
        awsCreditAccount:awsCreditAccountId,
        awsTechnicalReviewAccount: awsTechnicalReviewAccount,
        awsWafrAccount:wafr_arn,
      },
      context: {
        apiName: "well_architected",
      },
    });
    return { workloadcreation }
  };
  return { createIacWorkload };
};

export { useCreateIacWorkload };