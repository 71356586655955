import React, { useState, useEffect } from "react";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Search = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string;
  setGlobalFilter: any;
}) => {
  return (
    <DebouncedInput
      value={globalFilter || ""}
      onChange={(value: any) => {
        setGlobalFilter(String(value));
      }}
      placeholder="Search"
      inputProps={{ "aria-label": "Search" }}
      sx={{ ml: 1 }}
    />
  );
};

export default Search;

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  inputProps,
  sx,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  inputProps: any;
  sx: any;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);
  return (
    <FormControl sx={{ m: 1, ml: 0, width: "305px" }} variant="outlined">
      <OutlinedInput
        id="outlined-adornment-password"
        type={"text"}
        value={value}
        inputProps={inputProps}
        onChange={(e: any) => {
          e.preventDefault();

          setValue(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon sx={{ color: "secondary.main" }} />
          </InputAdornment>
        }
        placeholder="Search"
        size="small"
      />
    </FormControl>
  );
}
