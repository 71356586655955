import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Stack,
  InputLabel,
  FormControl,
  Grid,
  MenuItem,
  Button,
  styled,
  CircularProgress,
  Alert,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import { LoadingButton } from "@mui/lab";

import Heading from "../common/Heading";

import {
  useManageRemediationSettingsMutation,
  useGetRemediationSettingsQuery,
} from "../../generated/index";
import { OnResultDocument } from "../../generated";

import useAccount from "./../../hooks/useAccount";
import { useApolloClient } from "@apollo/client";

const SaveButton: any = styled(Button)({
  textTransform: "none",
  width: "141px",
  height: "56px",
});

const ManageAutomation = () => {
  const [automationFlowType, setAutomationFlowType] = useState("");
  const [automationApprovalFlow, setAutomationApprovalFlow] = useState("");
  const [stateMachineLoading, setStateMachineLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { arn } = useAccount();
  const client = useApolloClient();

  const handleAutomationFlowTypeChange = (event: SelectChangeEvent) => {
    setAutomationFlowType(event.target.value as string);
  };

  const handleAutomationApprovalFlowChange = (event: SelectChangeEvent) => {
    setAutomationApprovalFlow(event.target.value as string);
  };

  const [
    manageRemediationSettingsMutation,
    { data, loading: manageRemediationLoading, error },
  ] = useManageRemediationSettingsMutation();

  const { data: remediationSettingsData, loading } =
    useGetRemediationSettingsQuery({
      context: {
        apiName: "automation_approval",
      },
    });

  const handleSaveOnClick = () => {
    setStateMachineLoading(true);
    manageRemediationSettingsMutation({
      variables: {
        arn: arn,
        automationApprovalFlow: automationApprovalFlow,
        automationFlowType: automationFlowType,
      },
      context: {
        apiName: "automation_approval",
      },
    });
  };

  useEffect(() => {
    const automationFlowType =
      remediationSettingsData?.getRemediationSettings?.automationFlowType || "";
    const automationApprovalFlow =
      remediationSettingsData?.getRemediationSettings?.automationApprovalFlow ||
      "";
    console.log("Automation Flow Type", automationFlowType);
    console.log("Automation Approval flow", automationApprovalFlow);
    setAutomationFlowType(automationFlowType);
    setAutomationApprovalFlow(automationApprovalFlow);
  }, [remediationSettingsData]);

  useEffect(() => {
    if (!manageRemediationLoading) {
      console.log("Subscribing 1");
      client
        ?.subscribe({
          query: OnResultDocument,
          variables: {
            id: data?.manageRemediationSettings?.id,
          },
        })
        .subscribe(
          ({ data }: any) => {
            console.log("subscribing 2");
            const result = data?.onResult?.result;
            console.log("Result", result);
            setStateMachineLoading(false);
            if (result.includes("success")) {
              setSuccessMessage("Successfully updated settings!");
            } else {
              setErrorMessage("Failed to update settings!");
            }
          },
          (error: any) => {
            console.log(error);
            setStateMachineLoading(false);
          },
          () => {
            console.log("completed subscription");
          }
        );
    }
  }, [data, manageRemediationLoading]);

  return (
    <Container component="main">
      <Heading heading="Continuous Compliance Settings" />
      <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
        {successMessage !== "" && (
          <Alert severity="success" onClose={() => setSuccessMessage("")}>
            {successMessage}
          </Alert>
        )}
        {errorMessage !== "" && (
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        )}
        <Paper sx={{ width: "1216px", padding: "32px" }}>
          <Grid container flexDirection="row">
            <Stack
              spacing={3}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography
                variant="body1"
                align="left"
                gutterBottom
                sx={{ fontWeight: 400 }}
              >
                Continuous compliance provides additional security and
                enforcement 24x7. At a high level it does this by monitoring
                whether AWS security controls are in a passed or failed state
                and if failed then the misconfiguration is corrected with an
                automation runbook that reinstates the correct, compliant
                configuration.
              </Typography>
              <Typography
                variant="body1"
                align="left"
                gutterBottom
                sx={{ fontWeight: 400 }}
              >
                You can use this page to configure Continuous Compliance
                settings and desired behaviour, including approvals if required.
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>All continuous compliance subject to approval</b> - All
                continuous compliance actions/runbooks will pushed to the
                scheduled remediation and approvals.
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Allow continuous compliance for Non-Disruptive controls</b> -
                Performs continuous compliance on only non-disruptive controls,
                excluding possibly disruptive controls. Possibly disruptive
                controls will be pushed to the scheduled remediation &
                continuous compliance approvals page.
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Allow all continuous compliance without approval</b> -
                Performs all continuous compliance actions/runbooks as soon as
                possible with no approval required.
              </Typography>

              <Stack direction="row" spacing={4}>
                <FormControl fullWidth>
                  {loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <>
                      <InputLabel id="automation-flow-type-input-label">
                        Current continuous compliance approval flow
                      </InputLabel>
                      <Select
                        label="Current continuous compliance approval flow"
                        id="automation-flow-type-select"
                        value={automationFlowType}
                        onChange={handleAutomationFlowTypeChange}
                      >
                        <MenuItem value={"EVENT_BRIDGE"} disabled={true}>
                          Legacy approval flow
                        </MenuItem>
                        <MenuItem value={"AUTOMATE_PLUS"}>
                          AUTOMATE+ approval flow
                        </MenuItem>
                      </Select>
                    </>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  {loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <>
                      <InputLabel id="automation-approval-flow-input-label">
                        Continuous compliance level
                      </InputLabel>
                      <Select
                        label="Continuous compliance level"
                        id="automation-approval-flow-select"
                        value={automationApprovalFlow}
                        onChange={handleAutomationApprovalFlowChange}
                      >
                        <MenuItem value={"ALL"}>
                          All continuous compliance subject to approval
                        </MenuItem>
                        <MenuItem value={"INTRUSIVE"}>
                          Allow continuous compliance for Non-Disruptive
                          controls
                        </MenuItem>
                        <MenuItem value={"NONE"}>
                          Allow all continuous compliance without approval
                        </MenuItem>
                      </Select>
                    </>
                  )}
                </FormControl>
              </Stack>
              <LoadingButton
                color="secondary"
                variant="contained"
                onClick={handleSaveOnClick}
                loadingPosition="end"
                loading={stateMachineLoading}
                sx={{
                  textTransform: "none",
                  width: "141px",
                  height: "56px",
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Paper>
      </Container>
    </Container>
  );
};

export default ManageAutomation;
