import { useSubmitIacEmailMutation } from "../generated";

interface SubmitEmailInput {
    wafrArn: string;
    emailSubmitted: any;
    setEmailSubmitted: any;
}

interface EmailSubmittedDictionary {
    [key: string]: boolean;
}

const useSubmitIacEmail = ({
    wafrArn, emailSubmitted, setEmailSubmitted
}: SubmitEmailInput) => {
    const [submitIacEmailMutation] = useSubmitIacEmailMutation();

    const addEmailSubmittedKeyValuePairs = (newPairs: EmailSubmittedDictionary) => {
        setEmailSubmitted((emailSubmitted: any) => ({
          ...emailSubmitted,
          ...newPairs
        }));
    };

    const submitemail_mutation = async (workloadid: string) => {
        if(emailSubmitted[workloadid] === false){
            addEmailSubmittedKeyValuePairs({[workloadid]:true});
            const submitemail = await submitIacEmailMutation({
                variables: {
                  workLoadId: workloadid,
                  stage: "HALF_STAGE",
                  awsWafrAccount: wafrArn,
                },
                context: {
                  apiName: "well_architected",
                },
            });
            if(submitemail?.data?.submitIacEmail?.status !== "COMPLETE"){
                addEmailSubmittedKeyValuePairs({[workloadid]:false});
            }
            return {submitemail}    
        }
        return false;
    };
    return {submitemail_mutation};
};

export { useSubmitIacEmail };
