import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import {
  useGetSecurityHubMembersQuery,
  useGetUrlLazyQuery,
} from "../../generated";
import LoadingButton from "@mui/lab/LoadingButton";
import useSnackBars from "../../hooks/useSnackbar";
import useRegions from "../../hooks/useRegions";
import Loader from "../common/Loader";
import { StandardCheckbox } from "../common/Checkboxes";
import { OpenInNew } from "@mui/icons-material";

interface OrganisationRoleProps {
  arn: string;
  defaultregion: string;
}

const validationSchema = yup.object({
  mgtAccountRef: yup
    .string()
    .required("AWS Management Account ID is required")
    .min(12)
    .max(12),
  adminAccountRef: yup
    .string()
    .required("AWS Delegated Administrator Account ID is required")
    .min(12)
    .max(12),
  organisationUnitId: yup
    .string()
    .required("AWS Organisation Id/AWS Organisation Unit ID is required"),
});

export default function OrganisationRoleDialog({
  arn,
  defaultregion,
}: OrganisationRoleProps) {
  const [open, setOpen] = useState(false);
  const { setAlerts } = useSnackBars();
  const [loading, setLoading] = useState(false);
  const handleClose = (event: any) => {
    setOpen(false);
    setLoading(false);
  };

  const [getUrl] = useGetUrlLazyQuery();
  const {
    data,
    loading: accountsLoading,
    error,
  } = useGetSecurityHubMembersQuery({
    variables: {
      arn: arn,
    },
    context: {
      apiName: "user_deploy_process",
    },
  });

  const createCloudformationStackUrl = async (
    adminAccount: string,
    organisationUnitIds: Array<string>,
    excludeAccounts: Array<string>
  ) => {
    const memberStackUrl = await getUrl({
      variables: {
        template: "roles/SixPillarsMemberAccountRoleStack.template.json",
      },
      defaultOptions: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
    }).then((result) => {
      return result.data?.getTemplateS3URL;
    });
    const organisationStackUrl = await getUrl({
      variables: {
        template: "roles/SixPillarOrganisationDeployStack.template.json",
      },
      defaultOptions: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
    }).then((result) => {
      return result.data?.getTemplateS3URL;
    });

    const url = `https://console.aws.amazon.com/cloudformation/home?region=${defaultregion}#/stacks/create/review?stackName=six-pillars-organisation-deploy&templateURL=${encodeURIComponent(
      organisationStackUrl
    )}&param_AdministratorAccountId=${adminAccount}&param_AwsOrganisationUnitIds=${organisationUnitIds}&param_Regions=${[
      defaultregion,
    ]}&param_AwsAccountsIds=${[
      excludeAccounts,
    ]}&param_ExecutionRoleStackSetTemplateUrl=${encodeURIComponent(
      memberStackUrl
    )}`;
    window.open(url, "_blank")?.focus();
    setLoading(false);
    setOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      mgtAccountRef: "",
      adminAccountRef: "",
      organisationId: "",
      organisationUnitId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const adminAccountId = arn.split(":")[4];
      if (adminAccountId === values.adminAccountRef) {
        let skip_accounts = [adminAccountId];
        if (values.mgtAccountRef.length === 12) {
          skip_accounts.push(values.mgtAccountRef);
        }
        createCloudformationStackUrl(
          adminAccountId,
          [values.organisationUnitId],
          skip_accounts
        );
      } else {
        setAlerts([
          {
            severity: "error",
            msg: "Administrator AWS account does not match with Automate+ deployed AWS account",
          },
        ]);
      }
    },
  });

  useEffect(() => {}, [data, accountsLoading, error]);

  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: 100,
          bgcolor: "secondary.main",
          "&:hover": {
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
        onClick={() => {
          setOpen(true);
          setLoading(false);
        }}
      >
        Step 1
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ pb: 0.5, fontSize: "16px" }}>
          <b>
            Step 1 : Uses a CloudFormation stack to create two new roles and
            update your existing role in the nominated account.{" "}
          </b>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ overflow: "hidden", pt: 0 }}>
            <Typography fontSize={"12px"} sx={{ pb: 1 }}>
              We require a few details in order to create the required
              CloudFormation Stack.
            </Typography>
            <Typography fontSize={"12px"} sx={{ pb: 1 }}>
              A full list of the role details can be found at the following{" "}
              <Link>URL</Link>
            </Typography>
            <Typography fontSize={"12px"} sx={{ pb: 1 }}>
              At a high level the required CloudFormation stack performs:
            </Typography>
            <Typography fontSize={"12px"} sx={{ pb: 1 }}>
              1. The creation of the AWS role,
              “AWSCloudFormationStackSetAdministrationRole” and a service
              managed StackSet, which creates a role named
              AWSCloudFormationStackSetExecutionRole for all Organisational Unit
              (OU) members.{" "}
            </Typography>
            <Typography fontSize={"12px"} sx={{ pt: 1, pb: 3 }}>
              2. Updates to the existing “six-pillars-role” iam role in the
              delegated administrator account.
            </Typography>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="organisationUnitId"
                name="organisationUnitId"
                label="AWS Organisation Unit Id"
                value={formik.values.organisationUnitId}
                sx={{ pb: 2 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.organisationUnitId &&
                  Boolean(formik.errors.organisationUnitId)
                }
                helperText={
                  formik.touched.organisationUnitId &&
                  formik.errors.organisationUnitId
                }
              />
            </FormControl>
            <TextField
              fullWidth
              id="mgtAccountRef"
              name="mgtAccountRef"
              label="AWS Organisation Management Account"
              value={formik.values.mgtAccountRef}
              sx={{ pb: 2, mt: 0 }}
              onChange={formik.handleChange}
              error={
                formik.touched.mgtAccountRef &&
                Boolean(formik.errors.mgtAccountRef)
              }
              helperText={
                formik.touched.mgtAccountRef && formik.errors.mgtAccountRef
              }
            />
            <TextField
              fullWidth
              id="defaultAdminAccountRef"
              name="defaultAdminAccountRef"
              label="AWS Delegated Administrator Account"
              disabled={true}
              value={arn.split(":")[4]}
              sx={{ pb: 2, mt: 0 }}
            />
            <Typography fontSize={"12px"} sx={{ pb: 2 }} color={"red"}>
              Please log into your Delegated Administrator AWS Account to copy
              the account ID
            </Typography>
            <TextField
              fullWidth
              id="adminAccountRef"
              name="adminAccountRef"
              label="Confirm AWS Delegated Administrator Account"
              value={formik.values.adminAccountRef}
              sx={{ pb: 2, mt: 0 }}
              onChange={formik.handleChange}
              error={
                formik.touched.adminAccountRef &&
                Boolean(formik.errors.adminAccountRef)
              }
              helperText={
                formik.touched.adminAccountRef && formik.errors.adminAccountRef
              }
            />
          </DialogContent>
          <Typography color={"red"} fontSize={"16px"} sx={{ pl: 5, pr: 5 }}>
            Before you click "Open", please make sure you are logged into the
            correct AWS account({arn.split(":")[4]}) and please ensure that
            “pop-ups” for the AUTOMATE+ SaaS site/URLs are set to always allow.​
          </Typography>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              disabled={loading}
              loading={loading}
              endIcon={<OpenInNew fontSize="small" />}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
            >
              Open
            </LoadingButton>
          </DialogActions>
        </form>
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </>
  );
}
