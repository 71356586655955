import { FC } from "react";
import Typography from "@mui/material/Typography";
import AwsSvg from "../../assets/aws-icon";
import { Table, TableBody, TableRow, Box, Link } from "@mui/material";
import { Row } from "@tanstack/react-table";
import {
  useGetAwsControlQuery,
  useGetSupportControlQuery,
} from "../../generated";
import ListFindingsProps from "../../models/ListFindingsProps";
import StyledTableCell from "../common/StyledTableCell";
import Control from "../../models/Control";
import Loader from "./../common/Loader";
import ComplianceStatus from "./../common/ComplianceStatus";

const Finding: FC<any> = ({
  row,
  loading,
  findings,
  controlshlink,
  customcontrol = false,
}: {
  findings: [];
  controlshlink: string;
  loading: boolean;
  row: Row<Control>;
  customcontrol: boolean;
}) => {

  return loading ? (
    <TableRow key={`${row.id}-expanded`}>
      <StyledTableCell colSpan={row.getVisibleCells().length}>
        <Loader></Loader>
      </StyledTableCell>
    </TableRow>
  ) : (
    <>
      {findings?.map((finding: any) => {
        return (
          <TableRow key={finding.Id}>
            <StyledTableCell key={`f-1`} colSpan={1}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ComplianceStatus
                  value={finding.Compliance.Status}
                ></ComplianceStatus>
              </Box>
            </StyledTableCell>
            <StyledTableCell key={`f-2`} colSpan={1}>
              <Table>
                <TableBody>
                  <TableRow key={`${finding.Id}-1`}>
                    <StyledTableCell
                      colSpan={1}
                      sx={{
                        borderBottom: 0,
                        padding: 0.25,
                        width: 90,
                        verticalAlign: "top",
                      }}
                    >
                      <Typography fontSize={"14px"}>Title</Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={1}
                      sx={{
                        borderBottom: 0,
                        padding: 0.25,
                        verticalAlign: "top",
                        width: 100,
                      }}
                    >
                      <Typography fontSize={"14px"}>{finding.Title}</Typography>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow key={`${finding.Id}-2`}>
                    <StyledTableCell
                      colSpan={1}
                      sx={{
                        borderBottom: 0,
                        padding: 0.25,
                        verticalAlign: "top",
                      }}
                    >
                      <Typography fontSize={"14px"}>Account ID</Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={1}
                      sx={{
                        borderBottom: 0,
                        padding: 0.25,
                        verticalAlign: "top",
                      }}
                    >
                      <Typography fontSize={"14px"}>
                        {finding.AwsAccountId}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow key={`${finding.Id}-3`}>
                    <StyledTableCell
                      colSpan={1}
                      sx={{
                        borderBottom: 0,
                        padding: 0.25,
                        verticalAlign: "top",
                      }}
                    >
                      <Typography fontSize={"14px"}>Service ID</Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={1}
                      sx={{
                        borderBottom: 0,
                        padding: 0.25,
                        verticalAlign: "top",
                        width: 100,
                      }}
                    >
                      <Typography fontSize={"14px"}>
                        {finding?.Resources ? finding?.Resources[0]?.Id : ""}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow key={`${finding.Id}-4`}>
                    <StyledTableCell
                      colSpan={1}
                      sx={{
                        borderBottom: 0,
                        padding: 0.25,
                        verticalAlign: "top",
                      }}
                    >
                      <Typography fontSize={"14px"}>Finding ID</Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={1}
                      sx={{
                        borderBottom: 0,
                        padding: 0.25,
                        verticalAlign: "top",
                        width: 100,
                      }}
                    >
                      <Typography fontSize={"14px"}>
                        {finding?.Id?.split("/").reverse()[0]}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableCell>
            <StyledTableCell
              key={`f-3`}
              colSpan={1}
              sx={{ verticalAlign: "top" }}
            >
              {!customcontrol && (
                <Link
                  variant="body2"
                  href={finding?.ProductFields?.RecommendationUrl || ""}
                  target={"_blank"}
                  sx={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      display: "flex ",
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 2,
                    }}
                    flexDirection={"column"}
                  >
                    <AwsSvg></AwsSvg>
                    <Typography fontSize={"14px"} sx={{ textAlign: "center" }}>
                      Remediation Documentation
                    </Typography>
                  </Box>
                </Link>
              )}
              {!customcontrol && (
                <Link
                  variant="body2"
                  href={controlshlink || ""}
                  target={"_blank"}
                  sx={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      display: "flex ",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    flexDirection={"column"}
                  >
                    <AwsSvg></AwsSvg>
                    <Typography fontSize={"14px"} sx={{ textAlign: "center" }}>
                      Security Hub Findings
                    </Typography>
                  </Box>
                </Link>
              )}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        );
      })}
      {findings.length === 0 && (
        <>
          <TableRow key={row.original.compliancestatus}>
            <StyledTableCell key={`0-f-1`} colSpan={1}></StyledTableCell>
            <StyledTableCell key={`0-f-2`} colSpan={1}>
              No Findings
            </StyledTableCell>
            <StyledTableCell
              key={`0-f-3`}
              colSpan={1}
              sx={{ verticalAlign: "top" }}
            ></StyledTableCell>
            <StyledTableCell
              key={`0-f-4`}
              colSpan={1}
              sx={{ verticalAlign: "top" }}
            >
              {!customcontrol && (
                <Link
                  variant="body2"
                  href={controlshlink || ""}
                  target={"_blank"}
                  sx={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      display: "flex ",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    flexDirection={"column"}
                  >
                    <AwsSvg></AwsSvg>
                    <Typography fontSize={"14px"} sx={{ textAlign: "center" }}>
                      Security Hub Findings
                    </Typography>
                  </Box>
                </Link>
              )}
            </StyledTableCell>
            <StyledTableCell
              key={`0-f-5`}
              colSpan={2}
              sx={{ verticalAlign: "top" }}
            ></StyledTableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const RenderAwsControlFindings: FC<any> = ({ row, arn }: ListFindingsProps) => {
  const { data, loading, error } = useGetAwsControlQuery({
    variables: {
      arn: arn,
      controlname: row.original.controlname,
    },
  });
  console.log(error);
  return (
    <Finding
      row={row}
      findings={data?.getAWSControl?.findings}
      loading={loading}
      controlshlink={data?.getAWSControl?.controlshlink}
      customcontrol={false}
    ></Finding>
  );
};

const RenderSup1ControlFindings: FC<any> = ({
  row,
  arn,
}: ListFindingsProps) => {
  const { data, loading, error } = useGetSupportControlQuery({
    variables: {
      arn: arn,
    },
  });
  console.log(error);
  return (
    <Finding
      row={row}
      findings={data?.getSupportControl?.findings}
      controlshlink={""}
      loading={loading}
      customcontrol={true}
    ></Finding>
  );
};

const Findings: FC<any> = ({ row, arn }: ListFindingsProps) => {
  if (row.original.controlname.includes("6PL.")) {
    if (row.original.controlname.includes("6PL.SUP.1")) {
      return (
        <RenderSup1ControlFindings
          row={row}
          arn={arn}
        ></RenderSup1ControlFindings>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <RenderAwsControlFindings row={row} arn={arn}></RenderAwsControlFindings>
    );
  }
};

export default Findings;
