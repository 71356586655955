import { FC, ReactNode } from "react";
import Header from "./header";
import Footer from "./footer";
import { Box, CssBaseline } from "@mui/material";
import LoadingOverlay from "../common/LoadingOverlay";
import useAccount from "../../hooks/useAccount";

interface PageProps {
  children?: ReactNode;
  multiaccount: boolean;
}

const Layout: FC<PageProps> = ({ children, multiaccount }) => {
  const { loading } = useAccount();
  return (
    <>
      <LoadingOverlay show={loading}></LoadingOverlay>
      <Header multiaccount={multiaccount}></Header>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "space-between",
        }}
      >
        <CssBaseline />
        {!loading && children}
      </Box>
      <Footer></Footer>
    </>
  );
};

export default Layout;
